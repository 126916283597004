/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProviderResponse } from './providerResponse';
import { PaymentMethodApi } from './paymentMethodApi';
import { AvailablePaymentMethodForProvider } from './availablePaymentMethodForProvider';


export interface InvitationSettingsResponse { 
    paymentMethods?: Array<PaymentMethodApi>;
    providers?: Array<ProviderResponse>;
    serviceActivated: boolean;
    availablePaymentMethods?: Array<AvailablePaymentMethodForProvider>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NettingStatus } from './nettingStatus';


export interface SupplierActivationCluster1 { 
    clusterId: number;
    legacyClusterId: string;
    clusterName: string;
    discountDynamicRate: number;
    tnDays: number;
    minStock: number;
    minimumAmount: number;
    maximumAmount: number;
    prize: number;
    discountFixedRate: number;
    currency: string;
    cutOffDays: number;
    nettingStatus: NettingStatus;
    maxDaysToExpiration?: number;
}
export namespace SupplierActivationCluster1 {
}



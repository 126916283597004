/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LearningProgressStatusView = 'Completed' | 'InProgress' | 'New';

export const LearningProgressStatusView = {
    Completed: 'Completed' as LearningProgressStatusView,
    InProgress: 'InProgress' as LearningProgressStatusView,
    New: 'New' as LearningProgressStatusView
};


/**
 * Authentication API
 * Thoth authentication API
 *
 * The version of the OpenAPI document: 2.4.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdentityUser } from './identityUser';
import { OtpResponse } from './otpResponse';
import { AuthenticationIdentity } from './authenticationIdentity';
import { IdentityOrganization } from './identityOrganization';
import { MfaIncompleteDataResponse } from './mfaIncompleteDataResponse';


/**
 * @type DeviceLogin200Response
 * @export
 */
export type DeviceLogin200Response = AuthenticationIdentity | MfaIncompleteDataResponse | OtpResponse;


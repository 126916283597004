/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RunningOnboardingResponse { 
    customerName?: string;
    customerVat?: string;
    factorName: string;
    factorVat: string;
    onboardedName: string;
    onboardedVat: string;
    missingDocuments: number;
    uploadedDocuments: number;
    totalDocuments: number;
    onboardingId: number;
}


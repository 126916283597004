/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields11 = 'activatedBuyers' | 'supplierCompanyName' | 'supplierCompanyVat';

export const SortableFields11 = {
    ActivatedBuyers: 'activatedBuyers' as SortableFields11,
    SupplierCompanyName: 'supplierCompanyName' as SortableFields11,
    SupplierCompanyVat: 'supplierCompanyVat' as SortableFields11
};


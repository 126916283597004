/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplierBudgetErrorView } from './supplierBudgetErrorView';
import { BuyerBudgetErrorView } from './buyerBudgetErrorView';
import { ErrorReasonTypeView } from './errorReasonTypeView';


/**
 * @type ErrorReasonView
 * @export
 */
export type ErrorReasonView = BuyerBudgetErrorView | SupplierBudgetErrorView;


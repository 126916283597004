/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTargetUpdateInt } from './kpiTargetUpdateInt';
import { KpiView } from './kpiView';


export interface KpiGridIntUpdate { 
    kpi: KpiView;
    target: KpiTargetUpdateInt;
    kind: string;
}
export namespace KpiGridIntUpdate {
}



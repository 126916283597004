/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';


export interface InvoiceToSignResponse { 
    amount: number;
    currency: string;
    documentNumber: string;
    buyerCompanyName: string;
    buyerCompanyVat: VatView;
    buyerDivisionCodeView?: string;
    supplierCompanyName: string;
    supplierCompanyVat: VatView;
    supplierDivisionCodeView?: string;
}


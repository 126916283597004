/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomParametersView } from './customParametersView';
import { CreditCardPaymentRequest } from './creditCardPaymentRequest';
import { SupplierActivationDivision } from './supplierActivationDivision';


export interface SupplierActivationRequest1 { 
    clusterId: number;
    customParameters: CustomParametersView;
    divisions?: Array<SupplierActivationDivision>;
    creditCardPayment?: CreditCardPaymentRequest;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CountFilterOptionBuyerSupplierServiceStatusView } from './countFilterOptionBuyerSupplierServiceStatusView';
import { NamedFilterOptionBuyerSupplierServiceTypeView } from './namedFilterOptionBuyerSupplierServiceTypeView';
import { FinancialInfoFiltersView } from './financialInfoFiltersView';


export interface SupplierFilters1 { 
    serviceTypeFilters?: Array<NamedFilterOptionBuyerSupplierServiceTypeView>;
    serviceStatusFilters?: Array<CountFilterOptionBuyerSupplierServiceStatusView>;
    financialInfoFilters: FinancialInfoFiltersView;
    enableUpload: boolean;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReverseInvoiceStatusView = 'Credited' | 'Filtered' | 'Paid' | 'Recognized' | 'Recognizing' | 'Refused' | 'Rejected' | 'ToRecognize' | 'ToSign' | 'Uploaded';

export const ReverseInvoiceStatusView = {
    Credited: 'Credited' as ReverseInvoiceStatusView,
    Filtered: 'Filtered' as ReverseInvoiceStatusView,
    Paid: 'Paid' as ReverseInvoiceStatusView,
    Recognized: 'Recognized' as ReverseInvoiceStatusView,
    Recognizing: 'Recognizing' as ReverseInvoiceStatusView,
    Refused: 'Refused' as ReverseInvoiceStatusView,
    Rejected: 'Rejected' as ReverseInvoiceStatusView,
    ToRecognize: 'ToRecognize' as ReverseInvoiceStatusView,
    ToSign: 'ToSign' as ReverseInvoiceStatusView,
    Uploaded: 'Uploaded' as ReverseInvoiceStatusView
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfirmingDiscountRequestLineResponse } from './confirmingDiscountRequestLineResponse';
import { DiscountRateApplicationTimeView } from './discountRateApplicationTimeView';
import { VatView } from './vatView';
import { ConfirmingTypeApi } from './confirmingTypeApi';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface ConfirmingDiscountRequestFactorGroupResponse { 
    discountAmount: number;
    discountableAmount: number;
    discountPercentage: number;
    discountRateApplicationTime: DiscountRateApplicationTimeView;
    inhibitDueDateSelection: boolean;
    netAmount: number;
    notesAmount: number;
    prizeAmount: number;
    factorId: number;
    factorName: string;
    factorVat: VatView;
    confirmingType: ConfirmingTypeApi;
    lines?: Array<ConfirmingDiscountRequestLineResponse>;
    integrationTypeView: FactorIntegrationTypeView;
}
export namespace ConfirmingDiscountRequestFactorGroupResponse {
}



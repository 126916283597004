/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BuyerSupplierServiceTypeView = 'Confirming' | 'DynamicDiscounting' | 'Esg' | 'NoService' | 'Reverse';

export const BuyerSupplierServiceTypeView = {
    Confirming: 'Confirming' as BuyerSupplierServiceTypeView,
    DynamicDiscounting: 'DynamicDiscounting' as BuyerSupplierServiceTypeView,
    Esg: 'Esg' as BuyerSupplierServiceTypeView,
    NoService: 'NoService' as BuyerSupplierServiceTypeView,
    Reverse: 'Reverse' as BuyerSupplierServiceTypeView
};


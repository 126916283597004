/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserStatus = 'INSERTED' | 'INVITED' | 'ACTIVE' | 'DISABLED' | 'BOUNCED';

export const UserStatus = {
    Inserted: 'INSERTED' as UserStatus,
    Invited: 'INVITED' as UserStatus,
    Active: 'ACTIVE' as UserStatus,
    Disabled: 'DISABLED' as UserStatus,
    Bounced: 'BOUNCED' as UserStatus
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerResponse } from './buyerResponse';
import { PaginatedResponseBuyerFiltersBuyerResponseSortableFieldsSort } from './paginatedResponseBuyerFiltersBuyerResponseSortableFieldsSort';
import { PaginationResponse } from './paginationResponse';
import { BuyerFilters } from './buyerFilters';


export interface PaginatedResponseBuyerFiltersBuyerResponseSortableFields { 
    filters: BuyerFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseBuyerFiltersBuyerResponseSortableFieldsSort;
    page?: Array<BuyerResponse>;
}


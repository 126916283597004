/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CompanyEsgStatusApi = 'NotInvited' | 'Invited' | 'InCompilation' | 'WaitingForScore' | 'ScoreEvaded' | 'InvitationExpired' | 'ScoreExpired';

export const CompanyEsgStatusApi = {
    NotInvited: 'NotInvited' as CompanyEsgStatusApi,
    Invited: 'Invited' as CompanyEsgStatusApi,
    InCompilation: 'InCompilation' as CompanyEsgStatusApi,
    WaitingForScore: 'WaitingForScore' as CompanyEsgStatusApi,
    ScoreEvaded: 'ScoreEvaded' as CompanyEsgStatusApi,
    InvitationExpired: 'InvitationExpired' as CompanyEsgStatusApi,
    ScoreExpired: 'ScoreExpired' as CompanyEsgStatusApi
};


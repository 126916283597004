/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetStatus } from './budgetStatus';
import { BudgetResponse } from './budgetResponse';


export interface ConfirmBudgetResponse { 
    status: BudgetStatus;
    budget: BudgetResponse;
}
export namespace ConfirmBudgetResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PSD2MovementCategoryView = 'BankDeposit' | 'Employment' | 'Entertainment' | 'Expenses' | 'Health' | 'Home' | 'Income' | 'InsuranceAndRefunds' | 'Mortgage' | 'Other' | 'Rent' | 'Restaurants' | 'School' | 'Shopping' | 'Tax' | 'Travel';

export const PSD2MovementCategoryView = {
    BankDeposit: 'BankDeposit' as PSD2MovementCategoryView,
    Employment: 'Employment' as PSD2MovementCategoryView,
    Entertainment: 'Entertainment' as PSD2MovementCategoryView,
    Expenses: 'Expenses' as PSD2MovementCategoryView,
    Health: 'Health' as PSD2MovementCategoryView,
    Home: 'Home' as PSD2MovementCategoryView,
    Income: 'Income' as PSD2MovementCategoryView,
    InsuranceAndRefunds: 'InsuranceAndRefunds' as PSD2MovementCategoryView,
    Mortgage: 'Mortgage' as PSD2MovementCategoryView,
    Other: 'Other' as PSD2MovementCategoryView,
    Rent: 'Rent' as PSD2MovementCategoryView,
    Restaurants: 'Restaurants' as PSD2MovementCategoryView,
    School: 'School' as PSD2MovementCategoryView,
    Shopping: 'Shopping' as PSD2MovementCategoryView,
    Tax: 'Tax' as PSD2MovementCategoryView,
    Travel: 'Travel' as PSD2MovementCategoryView
};


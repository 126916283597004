/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DashboardBySuppliersResponse } from './dashboardBySuppliersResponse';
import { BuyerDashboardFiltersResponse } from './buyerDashboardFiltersResponse';
import { SupplierDashboardQuery } from './supplierDashboardQuery';


export interface DashboardSuppliersContentResponse { 
    data?: Array<DashboardBySuppliersResponse>;
    filters: BuyerDashboardFiltersResponse;
    request: SupplierDashboardQuery;
}


import { CommonModule } from '@angular/common';
import { Component, EnvironmentInjector, NgZone, inject } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonicModule, Platform } from '@ionic/angular';
import {
    BehaviorSubject,
    Observable,
    combineLatest,
    exhaustMap,
    switchMap,
    tap,
} from 'rxjs';
import { OutdatedVersionComponent } from 'src/components/outdated-version/outdated-version.component';
import { DirectivesModule } from 'src/shared/directives/directives.module';
import { DeepLinkService } from 'src/shared/services/deep-link-service/deep-link.service';
import { RemoteConfigurationService } from 'src/shared/services/remote-configuration-service/remote-configuration.service';

import { OfflineComponent } from '../components/offline/offline.component';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    standalone: true,
    imports: [
        CommonModule,
        DirectivesModule,
        IonicModule,
        OfflineComponent,
        OutdatedVersionComponent,
    ],
})
export class AppComponent {
    public environmentInjector = inject(EnvironmentInjector);

    isOnline: boolean;

    isApp = this.platform.is('capacitor');
    isIos = this.platform.is('ios');
    isAndroid = this.platform.is('android');

    isSupported: boolean;

    supportedVersion$: Observable<boolean>;
    loadVersion$ = new BehaviorSubject<'load'>('load');

    constructor(
        private deepLinkService: DeepLinkService,
        private platform: Platform,
        private remoteConfigurationService: RemoteConfigurationService,
        private zone: NgZone,
    ) {
        if (this.isApp) {
            this.initializeApp();
        }

        Network.getStatus().then((status) => {
            this.isOnline = status.connected;
        });
        Network.addListener('networkStatusChange', (status) => {
            this.zone.run(() => {
                this.isOnline = status.connected;
            });
        });
    }

    private defineVersionBehaviour() {
        return this.loadVersion$.pipe(
            switchMap(() => {
                return combineLatest([
                    this.remoteConfigurationService.getMinimumVersionFirebase(
                        this.isIos ? 'ios' : 'android',
                    ),
                    this.remoteConfigurationService.getInfo(),
                ]).pipe(
                    exhaustMap(([minimumVersion, appInfo]) => {
                        return this.remoteConfigurationService.versionAccepted(
                            appInfo.version,
                            minimumVersion,
                        );
                    }),
                    tap(() => {
                        SplashScreen.hide();
                    }),
                );
            }),
        );
    }

    initializeApp() {
        StatusBar.setStyle({ style: Style.Light });
        this.supportedVersion$ = this.defineVersionBehaviour();

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                this.deepLinkService.getRoute(event.url);
            });
        });
    }
}

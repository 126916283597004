/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InternalFailure } from './internalFailure';
import { InconsistentData } from './inconsistentData';
import { BadParameters } from './badParameters';


/**
 * @type TechnicalError
 * @export
 */
export type TechnicalError = BadParameters | InconsistentData | InternalFailure;


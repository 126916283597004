/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DayOfWeek } from './dayOfWeek';
import { InvoiceVisibility } from './invoiceVisibility';
import { NettingStatus } from './nettingStatus';
import { InvoiceDiscountability } from './invoiceDiscountability';


export interface ClusterParameters { 
    currency: string;
    name: string;
    discountDynamicRate: number;
    discountFixedRate: number;
    discountableDaysOfWeek?: Set<DayOfWeek>;
    cutOffDays: number;
    tnDays: number;
    minimumAmount: number;
    maximumAmount: number;
    discountableInvoices: InvoiceDiscountability;
    visibleInvoices: InvoiceVisibility;
    minStock: number;
    nettingStatus: NettingStatus;
    discountRequestCutOff: string;
    prize: number;
    maxDaysToExpiration?: number;
}
export namespace ClusterParameters {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfirmRequestLineResponse } from './confirmRequestLineResponse';
import { ConfirmBudgetResponse } from './confirmBudgetResponse';


export interface ConfirmRequestBudgetGroupResponse { 
    buyerId: number;
    buyerName: string;
    divisionId: number;
    divisionCode: string;
    vatCountry: string;
    vatCode: string;
    invoicesAmount: number;
    invoicesPrizeAmount: number;
    confirmedNetAmount: number;
    notesAmount: number;
    currency: string;
    budget?: ConfirmBudgetResponse;
    lines?: Array<ConfirmRequestLineResponse>;
}


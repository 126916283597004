/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AutocompleteResponseApiTypeUnit } from '../model/autocompleteResponseApiTypeUnit';
// @ts-ignore
import { AvailableBuyersResponse } from '../model/availableBuyersResponse';
// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { CCBuyerOpenPlafondsResponse } from '../model/cCBuyerOpenPlafondsResponse';
// @ts-ignore
import { CCPlafondRequest } from '../model/cCPlafondRequest';
// @ts-ignore
import { CCProviderBuyerDetailResponse } from '../model/cCProviderBuyerDetailResponse';
// @ts-ignore
import { CCProviderBuyerSortableFields } from '../model/cCProviderBuyerSortableFields';
// @ts-ignore
import { CCProviderBuyerSupplierActivationRequest } from '../model/cCProviderBuyerSupplierActivationRequest';
// @ts-ignore
import { CCProviderBuyerSupplierSortableFields } from '../model/cCProviderBuyerSupplierSortableFields';
// @ts-ignore
import { CCSupplierStatus } from '../model/cCSupplierStatus';
// @ts-ignore
import { ClusterRequest } from '../model/clusterRequest';
// @ts-ignore
import { ClusterResponse } from '../model/clusterResponse';
// @ts-ignore
import { CompanyInviteRequest } from '../model/companyInviteRequest';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { DiscountToolRequest } from '../model/discountToolRequest';
// @ts-ignore
import { DiscountToolResponse } from '../model/discountToolResponse';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFields } from '../model/paginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFields';
// @ts-ignore
import { PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFields } from '../model/paginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFields';
// @ts-ignore
import { ParametersInput } from '../model/parametersInput';
// @ts-ignore
import { ParametersResponse } from '../model/parametersResponse';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { SupplierActivationInfoResponse } from '../model/supplierActivationInfoResponse';
// @ts-ignore
import { SupplierActivationRequest1 } from '../model/supplierActivationRequest1';
// @ts-ignore
import { SupplierBuyerDiscountParametersResponse } from '../model/supplierBuyerDiscountParametersResponse';
// @ts-ignore
import { SupplierCompanyActivationInfoResponse } from '../model/supplierCompanyActivationInfoResponse';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DynamicDiscountingService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param buyerId 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid.');
        }
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling deleteDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/parameters/clusters/${this.configuration.encodeParam({name: "clusterId", value: clusterId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling deleteDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/relation`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2BuyersBuyeridCreditCardOpenPlafonds(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CCBuyerOpenPlafondsResponse>;
    public getDynamicDiscountingApiV2BuyersBuyeridCreditCardOpenPlafonds(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CCBuyerOpenPlafondsResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridCreditCardOpenPlafonds(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CCBuyerOpenPlafondsResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridCreditCardOpenPlafonds(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getDynamicDiscountingApiV2BuyersBuyeridCreditCardOpenPlafonds.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/credit-card-open-plafonds`;
        return this.httpClient.request<CCBuyerOpenPlafondsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ParametersResponse>;
    public getDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ParametersResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ParametersResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getDynamicDiscountingApiV2BuyersBuyeridParameters.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/parameters`;
        return this.httpClient.request<ParametersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<ClusterResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ClusterResponse>>>;
    public getDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ClusterResponse>>>;
    public getDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getDynamicDiscountingApiV2BuyersBuyeridParametersClusters.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/parameters/clusters`;
        return this.httpClient.request<Array<ClusterResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersCompaniesSuppliercompanyidActivationInfo(buyerId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierCompanyActivationInfoResponse>;
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersCompaniesSuppliercompanyidActivationInfo(buyerId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierCompanyActivationInfoResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersCompaniesSuppliercompanyidActivationInfo(buyerId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierCompanyActivationInfoResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersCompaniesSuppliercompanyidActivationInfo(buyerId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getDynamicDiscountingApiV2BuyersBuyeridSuppliersCompaniesSuppliercompanyidActivationInfo.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getDynamicDiscountingApiV2BuyersBuyeridSuppliersCompaniesSuppliercompanyidActivationInfo.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-companies/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation-info`;
        return this.httpClient.request<SupplierCompanyActivationInfoResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridInfo(buyerId: number, supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierActivationInfoResponse>;
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridInfo(buyerId: number, supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierActivationInfoResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridInfo(buyerId: number, supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierActivationInfoResponse>>;
    public getDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridInfo(buyerId: number, supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridInfo.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridInfo.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/info`;
        return this.httpClient.request<SupplierActivationInfoResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param paginationStart 
     * @param paginationMax 
     * @param buyerCompanyId 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, paginationStart: number, paginationMax: number, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFields>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, paginationStart: number, paginationMax: number, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFields>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, paginationStart: number, paginationMax: number, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFields>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, paginationStart: number, paginationMax: number, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersAutocomplete(providerAccountId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiTypeUnit>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersAutocomplete(providerAccountId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiTypeUnit>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersAutocomplete(providerAccountId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiTypeUnit>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersAutocomplete(providerAccountId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers-autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiTypeUnit>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyid(providerAccountId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CCProviderBuyerDetailResponse>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyid(providerAccountId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CCProviderBuyerDetailResponse>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyid(providerAccountId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CCProviderBuyerDetailResponse>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyid(providerAccountId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<CCProviderBuyerDetailResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param paginationStart 
     * @param paginationMax 
     * @param supplierCompanyId 
     * @param status 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers(providerAccountId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFields>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers(providerAccountId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFields>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers(providerAccountId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFields>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers(providerAccountId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers`;
        return this.httpClient.request<PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersAutocomplete(providerAccountId: number, buyerCompanyId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiTypeUnit>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersAutocomplete(providerAccountId: number, buyerCompanyId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiTypeUnit>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersAutocomplete(providerAccountId: number, buyerCompanyId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiTypeUnit>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersAutocomplete(providerAccountId: number, buyerCompanyId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersAutocomplete.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiTypeUnit>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param format 
     * @param supplierCompanyId 
     * @param status 
     * @param sortField 
     * @param sortOrder 
     * @param paginationStart 
     * @param paginationMax 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersReport(providerAccountId: number, buyerCompanyId: number, format: ReportFormat, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersReport(providerAccountId: number, buyerCompanyId: number, format: ReportFormat, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersReport(providerAccountId: number, buyerCompanyId: number, format: ReportFormat, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersReport(providerAccountId: number, buyerCompanyId: number, format: ReportFormat, supplierCompanyId?: number, status?: CCSupplierStatus, sortField?: CCProviderBuyerSupplierSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersReport.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param format 
     * @param buyerCompanyId 
     * @param sortField 
     * @param sortOrder 
     * @param paginationStart 
     * @param paginationMax 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersReport(providerAccountId: number, format: ReportFormat, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersReport(providerAccountId: number, format: ReportFormat, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersReport(providerAccountId: number, format: ReportFormat, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersReport(providerAccountId: number, format: ReportFormat, buyerCompanyId?: number, sortField?: CCProviderBuyerSortableFields, sortOrder?: SortOrder, paginationStart?: number, paginationMax?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getDynamicDiscountingApiV2ProvidersProvideraccountidBuyersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersAvailableBuyers(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AvailableBuyersResponse>;
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersAvailableBuyers(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AvailableBuyersResponse>>;
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersAvailableBuyers(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AvailableBuyersResponse>>;
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersAvailableBuyers(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getDynamicDiscountingApiV2SuppliersSupplieridBuyersAvailableBuyers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/available-buyers`;
        return this.httpClient.request<AvailableBuyersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param buyerId 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridParameters(supplierId: number, buyerId: number, endDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierBuyerDiscountParametersResponse>;
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridParameters(supplierId: number, buyerId: number, endDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierBuyerDiscountParametersResponse>>;
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridParameters(supplierId: number, buyerId: number, endDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierBuyerDiscountParametersResponse>>;
    public getDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridParameters(supplierId: number, buyerId: number, endDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridParameters.');
        }
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridParameters.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridParameters.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/parameters`;
        return this.httpClient.request<SupplierBuyerDiscountParametersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param parametersInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, parametersInput: ParametersInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, parametersInput: ParametersInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, parametersInput: ParametersInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postDynamicDiscountingApiV2BuyersBuyeridParameters(buyerId: number, parametersInput: ParametersInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postDynamicDiscountingApiV2BuyersBuyeridParameters.');
        }
        if (parametersInput === null || parametersInput === undefined) {
            throw new Error('Required parameter parametersInput was null or undefined when calling postDynamicDiscountingApiV2BuyersBuyeridParameters.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/parameters`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: parametersInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param clusterRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, clusterRequest: ClusterRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, clusterRequest: ClusterRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, clusterRequest: ClusterRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postDynamicDiscountingApiV2BuyersBuyeridParametersClusters(buyerId: number, clusterRequest: ClusterRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postDynamicDiscountingApiV2BuyersBuyeridParametersClusters.');
        }
        if (clusterRequest === null || clusterRequest === undefined) {
            throw new Error('Required parameter clusterRequest was null or undefined when calling postDynamicDiscountingApiV2BuyersBuyeridParametersClusters.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/parameters/clusters`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clusterRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param companyInviteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, companyInviteRequest: CompanyInviteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, companyInviteRequest: CompanyInviteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, companyInviteRequest: CompanyInviteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyers(providerAccountId: number, companyInviteRequest: CompanyInviteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyers.');
        }
        if (companyInviteRequest === null || companyInviteRequest === undefined) {
            throw new Error('Required parameter companyInviteRequest was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: companyInviteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidActivations(providerAccountId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidActivations(providerAccountId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidActivations(providerAccountId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidActivations(providerAccountId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidActivations.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidActivations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activations`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidDeactivations.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidDeactivations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/deactivations`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param cCProviderBuyerSupplierActivationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, cCProviderBuyerSupplierActivationRequest: CCProviderBuyerSupplierActivationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, cCProviderBuyerSupplierActivationRequest: CCProviderBuyerSupplierActivationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, cCProviderBuyerSupplierActivationRequest: CCProviderBuyerSupplierActivationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, cCProviderBuyerSupplierActivationRequest: CCProviderBuyerSupplierActivationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations.');
        }
        if (cCProviderBuyerSupplierActivationRequest === null || cCProviderBuyerSupplierActivationRequest === undefined) {
            throw new Error('Required parameter cCProviderBuyerSupplierActivationRequest was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidActivations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activations`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: cCProviderBuyerSupplierActivationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidDeactivations(providerAccountId: number, buyerCompanyId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidDeactivations.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidDeactivations.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling postDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidSuppliersSuppliercompanyidDeactivations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/deactivations`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param buyerId 
     * @param discountToolRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridDiscountTool(supplierId: number, buyerId: number, discountToolRequest: DiscountToolRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DiscountToolResponse>;
    public postDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridDiscountTool(supplierId: number, buyerId: number, discountToolRequest: DiscountToolRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DiscountToolResponse>>;
    public postDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridDiscountTool(supplierId: number, buyerId: number, discountToolRequest: DiscountToolRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DiscountToolResponse>>;
    public postDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridDiscountTool(supplierId: number, buyerId: number, discountToolRequest: DiscountToolRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridDiscountTool.');
        }
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridDiscountTool.');
        }
        if (discountToolRequest === null || discountToolRequest === undefined) {
            throw new Error('Required parameter discountToolRequest was null or undefined when calling postDynamicDiscountingApiV2SuppliersSupplieridBuyersBuyeridDiscountTool.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-tool`;
        return this.httpClient.request<DiscountToolResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: discountToolRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param clusterId 
     * @param clusterRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, clusterRequest: ClusterRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, clusterRequest: ClusterRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, clusterRequest: ClusterRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid(buyerId: number, clusterId: number, clusterRequest: ClusterRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling putDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid.');
        }
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling putDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid.');
        }
        if (clusterRequest === null || clusterRequest === undefined) {
            throw new Error('Required parameter clusterRequest was null or undefined when calling putDynamicDiscountingApiV2BuyersBuyeridParametersClustersClusterid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/parameters/clusters/${this.configuration.encodeParam({name: "clusterId", value: clusterId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: clusterRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param supplierActivationRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, supplierActivationRequest1: SupplierActivationRequest1, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, supplierActivationRequest1: SupplierActivationRequest1, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, supplierActivationRequest1: SupplierActivationRequest1, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation(buyerId: number, supplierId: number, supplierActivationRequest1: SupplierActivationRequest1, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling putDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling putDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation.');
        }
        if (supplierActivationRequest1 === null || supplierActivationRequest1 === undefined) {
            throw new Error('Required parameter supplierActivationRequest1 was null or undefined when calling putDynamicDiscountingApiV2BuyersBuyeridSuppliersSupplieridRelation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/relation`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierActivationRequest1,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param providerAccountId 
     * @param buyerCompanyId 
     * @param currency 
     * @param cCPlafondRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency(providerAccountId: number, buyerCompanyId: number, currency: string, cCPlafondRequest: CCPlafondRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency(providerAccountId: number, buyerCompanyId: number, currency: string, cCPlafondRequest: CCPlafondRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency(providerAccountId: number, buyerCompanyId: number, currency: string, cCPlafondRequest: CCPlafondRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency(providerAccountId: number, buyerCompanyId: number, currency: string, cCPlafondRequest: CCPlafondRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (providerAccountId === null || providerAccountId === undefined) {
            throw new Error('Required parameter providerAccountId was null or undefined when calling putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency.');
        }
        if (cCPlafondRequest === null || cCPlafondRequest === undefined) {
            throw new Error('Required parameter cCPlafondRequest was null or undefined when calling putDynamicDiscountingApiV2ProvidersProvideraccountidBuyersBuyercompanyidPlafondsCurrency.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/dynamic-discounting/api/v2/providers/${this.configuration.encodeParam({name: "providerAccountId", value: providerAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/plafonds/${this.configuration.encodeParam({name: "currency", value: currency, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: cCPlafondRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

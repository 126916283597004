/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RatingMoreClassView } from './ratingMoreClassView';
import { DpoView } from './dpoView';
import { CompanySizeView } from './companySizeView';


export interface FinancialInfoFilters { 
    dpoFilters?: Array<DpoView>;
    companySizeFilters?: Array<CompanySizeView>;
    companyRatingFilters?: Array<RatingMoreClassView>;
    sectorFilters?: Array<string>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConfirmingSupplierFilteredReasonView = 'Blocked' | 'Budget' | 'CigCup' | 'MaximumAmount' | 'MaximumCreditDays' | 'MaximumDaysToExpiration' | 'MinimumAmount' | 'MinimumDaysToExpiration' | 'MissingSettings' | 'Netting' | 'NoDiscountableDays';

export const ConfirmingSupplierFilteredReasonView = {
    Blocked: 'Blocked' as ConfirmingSupplierFilteredReasonView,
    Budget: 'Budget' as ConfirmingSupplierFilteredReasonView,
    CigCup: 'CigCup' as ConfirmingSupplierFilteredReasonView,
    MaximumAmount: 'MaximumAmount' as ConfirmingSupplierFilteredReasonView,
    MaximumCreditDays: 'MaximumCreditDays' as ConfirmingSupplierFilteredReasonView,
    MaximumDaysToExpiration: 'MaximumDaysToExpiration' as ConfirmingSupplierFilteredReasonView,
    MinimumAmount: 'MinimumAmount' as ConfirmingSupplierFilteredReasonView,
    MinimumDaysToExpiration: 'MinimumDaysToExpiration' as ConfirmingSupplierFilteredReasonView,
    MissingSettings: 'MissingSettings' as ConfirmingSupplierFilteredReasonView,
    Netting: 'Netting' as ConfirmingSupplierFilteredReasonView,
    NoDiscountableDays: 'NoDiscountableDays' as ConfirmingSupplierFilteredReasonView
};


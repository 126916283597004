/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InvoiceStatusApi = 'Canceled' | 'Confirmed' | 'Confirming' | 'DiscountApproval' | 'Discounted' | 'DocumentToSign' | 'Filtered' | 'Inserted' | 'Recalled' | 'Paid' | 'WaitingForDocument';

export const InvoiceStatusApi = {
    Canceled: 'Canceled' as InvoiceStatusApi,
    Confirmed: 'Confirmed' as InvoiceStatusApi,
    Confirming: 'Confirming' as InvoiceStatusApi,
    DiscountApproval: 'DiscountApproval' as InvoiceStatusApi,
    Discounted: 'Discounted' as InvoiceStatusApi,
    DocumentToSign: 'DocumentToSign' as InvoiceStatusApi,
    Filtered: 'Filtered' as InvoiceStatusApi,
    Inserted: 'Inserted' as InvoiceStatusApi,
    Recalled: 'Recalled' as InvoiceStatusApi,
    Paid: 'Paid' as InvoiceStatusApi,
    WaitingForDocument: 'WaitingForDocument' as InvoiceStatusApi
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTarget0TemplateBoolean } from './kpiTarget0TemplateBoolean';
import { KpiTargetTemplateBoolean } from './kpiTargetTemplateBoolean';
import { KpiView } from './kpiView';


export interface KpiGridTemplateBoolean { 
    kpi: KpiView;
    target0: KpiTarget0TemplateBoolean;
    targets?: Array<KpiTargetTemplateBoolean>;
    kind: string;
}
export namespace KpiGridTemplateBoolean {
}



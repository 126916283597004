/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyResponse1 } from './companyResponse1';
import { PaginationResponse } from './paginationResponse';
import { LoanCompaniesListFilters } from './loanCompaniesListFilters';
import { PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsViewSort } from './paginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsViewSort';


export interface PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsView { 
    filters: LoanCompaniesListFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsViewSort;
    page?: Array<CompanyResponse1>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TargetInvitedResponse } from './targetInvitedResponse';
import { TargetScoreEvadedResponse } from './targetScoreEvadedResponse';
import { TargetWaitingForScoreResponse } from './targetWaitingForScoreResponse';
import { TargetNotInvitedResponse } from './targetNotInvitedResponse';
import { TargetInCompilationResponse } from './targetInCompilationResponse';
import { EsgManagerApi } from './esgManagerApi';
import { TargetInvitationExpiredResponse } from './targetInvitationExpiredResponse';
import { CompanyEsgStatusApi } from './companyEsgStatusApi';
import { TargetScoreExpiredResponse } from './targetScoreExpiredResponse';


/**
 * @type TargetStatusResponse
 * @export
 */
export type TargetStatusResponse = TargetInCompilationResponse | TargetInvitationExpiredResponse | TargetInvitedResponse | TargetNotInvitedResponse | TargetScoreEvadedResponse | TargetScoreExpiredResponse | TargetWaitingForScoreResponse;


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnboardingServiceView } from './onboardingServiceView';
import { AutoDiscountStatusView } from './autoDiscountStatusView';


export interface AutoDiscountSettings1 { 
    factorId: number;
    factorName: string;
    buyerCompanyId: number;
    buyerCompanyName: string;
    service?: OnboardingServiceView;
    autoDiscountStatus: AutoDiscountStatusView;
}
export namespace AutoDiscountSettings1 {
}



import { Injectable } from '@angular/core';
import { SignatureService } from '@fd/clients/findynamic/client';
import { Platform } from '@ionic/angular';

import { NativeApiService } from '../native-api-service/nativeApi.service';

@Injectable()
export class SignatureApiService {
    private isApp = this.platform.is('capacitor');

    constructor(
        private platform: Platform,
        private nativeApiService: NativeApiService,
        private findynamicSignatureService: SignatureService,
    ) {}

    getSignatureApiV1AccountsAccountidSignableDocuments(accountId: number) {
        if (this.isApp) {
            return this.nativeApiService.getSignatureApiV1AccountsAccountidSignableDocuments(
                accountId,
            );
        } else {
            return this.findynamicSignatureService.getSignatureApiV1AccountsAccountidSignableDocuments(
                accountId,
            );
        }
    }

    postSignatureApiV1AccountsAccountidSignableDocumentsSignatureid(
        accountId: number,
        signatureId: number,
    ) {
        if (this.isApp) {
            return this.nativeApiService.postSignatureApiV1AccountsAccountidSignableDocumentsSignatureid(
                accountId,
                signatureId,
            );
        } else {
            return this.findynamicSignatureService.postSignatureApiV1AccountsAccountidSignableDocumentsSignatureid(
                accountId,
                signatureId,
            );
        }
    }

    getSignatureApiV1AccountsAccountidSignableDocumentsCount(
        accountId: number,
    ) {
        if (this.isApp) {
            return this.nativeApiService.getSignatureApiV1AccountsAccountidSignableDocumentsCount(
                accountId,
            );
        } else {
            return this.findynamicSignatureService.getSignatureApiV1AccountsAccountidSignableDocumentsCount(
                accountId,
            );
        }
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EsgSectionApi = 'Environmental' | 'Governance' | 'OverAll' | 'Social';

export const EsgSectionApi = {
    Environmental: 'Environmental' as EsgSectionApi,
    Governance: 'Governance' as EsgSectionApi,
    OverAll: 'OverAll' as EsgSectionApi,
    Social: 'Social' as EsgSectionApi
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IbanView1 } from './ibanView1';
import { IbanBuyerActivationView } from './ibanBuyerActivationView';


export interface FactorActivationsView { 
    factorId: number;
    factorName: string;
    activatedIbans?: Array<IbanView1>;
    buyers?: Array<IbanBuyerActivationView>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CCProviderBuyerItem } from './cCProviderBuyerItem';
import { PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFieldsSort } from './paginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFieldsSort';
import { PaginationResponse } from './paginationResponse';


export interface PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFields { 
    filters: object;
    pagination: PaginationResponse;
    sort?: PaginatedResponseCCProviderBuyerFiltersCCProviderBuyerItemCCProviderBuyerSortableFieldsSort;
    page?: Array<CCProviderBuyerItem>;
}


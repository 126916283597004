/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields = 'amount' | 'buyerName' | 'date' | 'documentNumber' | 'dueDate' | 'factorName' | 'issueDate' | 'newAmount' | 'status' | 'supplierName';

export const SortableFields = {
    Amount: 'amount' as SortableFields,
    BuyerName: 'buyerName' as SortableFields,
    Date: 'date' as SortableFields,
    DocumentNumber: 'documentNumber' as SortableFields,
    DueDate: 'dueDate' as SortableFields,
    FactorName: 'factorName' as SortableFields,
    IssueDate: 'issueDate' as SortableFields,
    NewAmount: 'newAmount' as SortableFields,
    Status: 'status' as SortableFields,
    SupplierName: 'supplierName' as SortableFields
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TargetVatQuery } from './targetVatQuery';
import { PaymentMethodApi } from './paymentMethodApi';
import { EsgManagerApi } from './esgManagerApi';


export interface CompanyAccountInvitationRequest { 
    manager: EsgManagerApi;
    paymentMethod: PaymentMethodApi;
    providerId: number;
    targetVat: TargetVatQuery;
}
export namespace CompanyAccountInvitationRequest {
}



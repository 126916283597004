/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PSD2MovementCategoryView } from './pSD2MovementCategoryView';
import { PSD2MovementListItemAccount } from './pSD2MovementListItemAccount';
import { PSD2MovementOperationKindView } from './pSD2MovementOperationKindView';
import { PSD2MovementDirectionView } from './pSD2MovementDirectionView';


export interface PSD2MovementListItem { 
    id: number;
    bankAccount: PSD2MovementListItemAccount;
    operationKind: PSD2MovementOperationKindView;
    debtor?: string;
    beneficiary?: string;
    bookingDate: string;
    valueDate?: string;
    amount: number;
    currency: string;
    direction: PSD2MovementDirectionView;
    description: string;
    category: PSD2MovementCategoryView;
}
export namespace PSD2MovementListItem {
}



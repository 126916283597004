import { Injectable } from '@angular/core';
import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config';
import { App } from '@capacitor/app';
import { of } from 'rxjs';
import { DeviceOs } from 'src/components/outdated-version/outdated-version.component';

@Injectable()
export class RemoteConfigurationService {
    async getMinimumVersionFirebase(deviceOs: DeviceOs) {
        try {
            await FirebaseRemoteConfig.fetchConfig({
                minimumFetchIntervalInSeconds: 3600,
            });

            return FirebaseRemoteConfig.activate()
                .then(async () => {
                    const { value } = await FirebaseRemoteConfig.getString({
                        key: `${deviceOs}_version`,
                    });

                    return value as string;
                })
                .catch(() => {
                    return '0';
                });
        } catch (_) {
            return '0';
        }
    }
    getInfo() {
        return App.getInfo();
    }

    versionAccepted(appVersion: string, minimumVersionSupported: string) {
        return of(
            appVersion.localeCompare(minimumVersionSupported, undefined, {
                numeric: true,
            }) >= 0,
        );
    }
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

/** Prevent IE11 from caching AJAX requests */

@Injectable()
export class NoCacheInterceptor implements HttpInterceptor {
  intercept(
      req: HttpRequest<unknown>,
      next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
      const nextReq = req.clone({
          headers: req.headers
              .set("Cache-Control", "no-cache")
              .set("Pragma", "no-cache")
              .set("Expires", "Sat, 01 Jan 2000 00:00:00 GMT")
              .set("If-Modified-Since", "Mon, 26 Jul 1997 05:00:00 GMT"),
      });
      return next.handle(nextReq);
  }
}

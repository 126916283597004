/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';
import { BuyerCreditCardStatusView } from './buyerCreditCardStatusView';


export interface CCProviderBuyerItem { 
    buyerCompanyId: number;
    buyerCompanyName: string;
    buyerCompanyVat: VatView;
    status: BuyerCreditCardStatusView;
}
export namespace CCProviderBuyerItem {
}



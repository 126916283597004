/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CountFilterOptionCompanySizeView } from './countFilterOptionCompanySizeView';
import { CountFilterOptionString } from './countFilterOptionString';
import { CountFilterOptionDpoView } from './countFilterOptionDpoView';
import { CountFilterOptionRatingMoreClassView } from './countFilterOptionRatingMoreClassView';


export interface FinancialInfoFiltersView { 
    dpoFilters?: Array<CountFilterOptionDpoView>;
    companySizeFilters?: Array<CountFilterOptionCompanySizeView>;
    companyRatingFilters?: Array<CountFilterOptionRatingMoreClassView>;
    sectorFilters?: Array<CountFilterOptionString>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseFactorInvoiceFiltersResponse } from './reverseFactorInvoiceFiltersResponse';
import { ReverseInvoiceResponse1 } from './reverseInvoiceResponse1';


export interface InvoiceContentResponseReverseInvoiceResponseReverseFactorInvoiceFiltersResponse { 
    data?: Array<ReverseInvoiceResponse1>;
    count: number;
    startResult: number;
    maxResult: number;
    lastId?: number;
    filters: ReverseFactorInvoiceFiltersResponse;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerDivision2 } from './buyerDivision2';
import { ReverseServiceStatus } from './reverseServiceStatus';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface SupplierFactorConfigurationResponse1 { 
    divisions?: Array<BuyerDivision2>;
    factorId: number;
    factorName: string;
    factorVatCountry: string;
    factorVatCode: string;
    status: ReverseServiceStatus;
    integrationType: FactorIntegrationTypeView;
}
export namespace SupplierFactorConfigurationResponse1 {
}



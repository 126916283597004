/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NamedFilter } from './namedFilter';
import { CountFilter } from './countFilter';


export interface ReverseInvoiceFiltersResponse { 
    totalAmount: number;
    startDueDate: string;
    endDueDate: string;
    statusFilters?: Array<CountFilter>;
    currencyFilters?: Array<NamedFilter>;
    invoiceTypeFilters?: Array<NamedFilter>;
    divisionFilters?: Array<NamedFilter>;
    enableUpload: boolean;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActiveDiscountStatusApi = 'Approved' | 'Credited' | 'DocumentToSign' | 'PaidByBuyer' | 'Processing' | 'Rejected' | 'Unpaid';

export const ActiveDiscountStatusApi = {
    Approved: 'Approved' as ActiveDiscountStatusApi,
    Credited: 'Credited' as ActiveDiscountStatusApi,
    DocumentToSign: 'DocumentToSign' as ActiveDiscountStatusApi,
    PaidByBuyer: 'PaidByBuyer' as ActiveDiscountStatusApi,
    Processing: 'Processing' as ActiveDiscountStatusApi,
    Rejected: 'Rejected' as ActiveDiscountStatusApi,
    Unpaid: 'Unpaid' as ActiveDiscountStatusApi
};


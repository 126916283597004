/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InfocertDiscountSignatureStatusView = 'ABORTED' | 'COMPLETED' | 'DORMANT' | 'PENDING_COMPLETION' | 'RUNNING';

export const InfocertDiscountSignatureStatusView = {
    Aborted: 'ABORTED' as InfocertDiscountSignatureStatusView,
    Completed: 'COMPLETED' as InfocertDiscountSignatureStatusView,
    Dormant: 'DORMANT' as InfocertDiscountSignatureStatusView,
    PendingCompletion: 'PENDING_COMPLETION' as InfocertDiscountSignatureStatusView,
    Running: 'RUNNING' as InfocertDiscountSignatureStatusView
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TargetMismatch } from './targetMismatch';
import { Generic2 } from './generic2';


/**
 * @type LoanUpdateError
 * @export
 */
export type LoanUpdateError = Generic2 | TargetMismatch;


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NamedFilter } from './namedFilter';
import { CountFilter } from './countFilter';


export interface SupplierInvoiceFiltersResponse { 
    amount: number;
    buyerCompanyFilter?: Array<NamedFilter>;
    endDueDate: string;
    endIssueDate: string;
    startDueDate: string;
    startIssueDate: string;
    statusFilter?: Array<CountFilter>;
    confirmingTypeFilter?: Array<NamedFilter>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SupplierDashboardQuery { 
    currency?: string;
    endDate?: string;
    startDate?: string;
    supplierId?: number;
    divisionId?: number;
    factorId?: number;
    max?: number;
    search?: string;
}


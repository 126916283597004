/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EarlySalaryEmployeeResponse } from './earlySalaryEmployeeResponse';
import { EarlySalaryRequestStatusView } from './earlySalaryRequestStatusView';


export interface EarlySalaryHRRequestResponse { 
    id: number;
    issueDate: string;
    paymentDate: string;
    amount: number;
    currency: string;
    status: EarlySalaryRequestStatusView;
    employee: EarlySalaryEmployeeResponse;
}
export namespace EarlySalaryHRRequestResponse {
}



import { Injectable } from '@angular/core';
import {
    ConfirmingInvoicesSelectionsRequest,
    ConfirmingService,
    ConfirmingTypeApi,
    DiscountStatusApi,
    DocumentsResponse,
    InvoiceStatusApi,
    ReportFormat,
    SupplierAutoDiscountsRequest,
} from '@fd/clients/findynamic/client';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';

import { NativeApiService } from '../native-api-service/nativeApi.service';

@Injectable()
export class ConfirmingApiService {
    private isApp = this.platform.is('capacitor');

    constructor(
        private platform: Platform,
        private nativeApiService: NativeApiService,
        private findynamicConfirmingService: ConfirmingService,
    ) {}

    getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(
        supplierId: number,
        requestId: number,
    ): Observable<DocumentsResponse> {
        if (this.isApp)
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(
                supplierId,
                requestId,
            );
        else
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(
                supplierId,
                requestId,
            );
    }

    getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(
        supplierId: number,
        requestId: number,
        documentId: number,
        observe?: 'body',
        reportProgress?: boolean,
        options?: {
            httpHeaderAccept?: 'application/octet-stream' | 'application/json';
        },
    ): Observable<Blob | string> {
        if (this.isApp) {
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(
                supplierId,
                requestId,
                documentId,
            );
        } else {
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(
                supplierId,
                requestId,
                documentId,
                observe,
                reportProgress,
                options,
            );
        }
    }

    getConfirmingApiV1SuppliersSupplieridInvoices(
        supplierId: number,
        currency: string,
        endDueDate: string,
        endIssueDate: string,
        max: number,
        start: number,
        startDueDate: string,
        startIssueDate: string,
        buyerCompanyIds?: Array<number>,
        factorId?: number,
        invoiceIds?: Array<number>,
        searchQuery?: string,
        sort?: string,
        status?: InvoiceStatusApi,
        confirmingType?: ConfirmingTypeApi,
    ) {
        if (this.isApp) {
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridInvoices(
                supplierId,
                currency,
                endDueDate,
                endIssueDate,
                max,
                start,
                startDueDate,
                startIssueDate,
                buyerCompanyIds,
                factorId,
                invoiceIds,
                searchQuery,
                sort,
                status,
                confirmingType,
            );
        } else {
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridInvoices(
                supplierId,
                currency,
                endDueDate,
                endIssueDate,
                max,
                start,
                startDueDate,
                startIssueDate,
                buyerCompanyIds,
                factorId,
                invoiceIds,
                searchQuery,
                sort,
                status,
                confirmingType,
            );
        }
    }

    getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(
        supplierId: number,
        currency: string,
        endDueDate: string,
        endIssueDate: string,
        startDueDate: string,
        startIssueDate: string,
        status: InvoiceStatusApi,
        buyerCompanyIds?: Array<number>,
        factorId?: number,
        invoiceIds?: Array<number>,
        confirmingType?: ConfirmingTypeApi,
    ) {
        if (this.isApp) {
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(
                supplierId,
                currency,
                endDueDate,
                endIssueDate,
                startDueDate,
                startIssueDate,
                status,
                buyerCompanyIds,
                factorId,
                invoiceIds,
                confirmingType,
            );
        } else {
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(
                supplierId,
                currency,
                endDueDate,
                endIssueDate,
                startDueDate,
                startIssueDate,
                status,
                buyerCompanyIds,
                factorId,
                invoiceIds,
                confirmingType,
            );
        }
    }

    postConfirmingApiV1SuppliersSupplieridInvoicesSummary(
        supplierId: number,
        exclude: boolean,
        endDueDate?: string,
        invoiceIds?: Array<number>,
        startDueDate?: string,
        statuses?: Array<InvoiceStatusApi>,
    ) {
        if (this.isApp) {
            return this.nativeApiService.postConfirmingApiV1SuppliersSupplieridInvoicesSummary(
                supplierId,
                exclude,
                endDueDate,
                invoiceIds,
                startDueDate,
                statuses,
            );
        } else {
            return this.findynamicConfirmingService.postConfirmingApiV1SuppliersSupplieridInvoicesSummary(
                supplierId,
                { exclude, endDueDate, invoiceIds, startDueDate, statuses },
            );
        }
    }

    postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(
        supplierId: number,
        confirmingInvoicesSelectionsRequest: ConfirmingInvoicesSelectionsRequest,
    ) {
        if (this.isApp) {
            return this.nativeApiService.postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(
                supplierId,
                confirmingInvoicesSelectionsRequest,
            );
        } else {
            return this.findynamicConfirmingService.postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(
                supplierId,
                confirmingInvoicesSelectionsRequest,
            );
        }
    }

    postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(
        supplierId: number,
        autoDiscountSettings: SupplierAutoDiscountsRequest,
    ) {
        if (this.isApp) {
            return this.nativeApiService.postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(
                supplierId,
                autoDiscountSettings,
            );
        } else {
            return this.findynamicConfirmingService.postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(
                supplierId,
                autoDiscountSettings,
            );
        }
    }

    getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
        supplierId: number,
    ) {
        if (this.isApp) {
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
                supplierId,
            );
        } else {
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
                supplierId,
            );
        }
    }

    postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
        supplierId: number,
        supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest,
    ) {
        if (this.isApp) {
            return this.nativeApiService.postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
                supplierId,
                supplierAutoDiscountsRequest,
            );
        } else {
            return this.findynamicConfirmingService.postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
                supplierId,
                supplierAutoDiscountsRequest,
            );
        }
    }

    getConfirmingApiV1SuppliersSupplieridDiscounts(
        supplierId: number,
        max?: number,
        start?: number,
        startDate?: string,
        endDate?: string,
        buyerCompanyIds?: Array<number>,
        status?: DiscountStatusApi,
        searchQuery?: string,
        sort?: string,
        discountIds?: Array<number>,
        confirmingType?: ConfirmingTypeApi,
    ) {
        if (this.isApp) {
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridDiscounts(
                supplierId,
                max,
                start,
                startDate,
                endDate,
                buyerCompanyIds,
                status,
                searchQuery,
                sort,
                discountIds,
                confirmingType,
            );
        } else {
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridDiscounts(
                supplierId,
                max,
                start,
                startDate,
                endDate,
                buyerCompanyIds,
                status,
                searchQuery,
                sort,
                discountIds,
                confirmingType,
            );
        }
    }

    getConfirmingApiV1SuppliersSupplieridInvoicesReport(
        supplierId: number,
        currency: string,
        endDueDate: string,
        endIssueDate: string,
        max: number,
        start: number,
        startDueDate: string,
        startIssueDate: string,
        format: ReportFormat,
        buyerCompanyIds?: Array<number>,
        factorId?: number,
        invoiceIds?: Array<number>,
        searchQuery?: string,
        sort?: string,
        status?: InvoiceStatusApi,
        confirmingType?: ConfirmingTypeApi,
    ): Observable<unknown> {
        if (this.isApp) {
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridInvoicesReport(
                supplierId,
                currency,
                endDueDate,
                endIssueDate,
                max,
                start,
                startDueDate,
                startIssueDate,
                format,
                buyerCompanyIds,
                factorId,
                invoiceIds,
                searchQuery,
                sort,
                status,
                confirmingType,
            );
        } else {
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridInvoicesReport(
                supplierId,
                currency,
                endDueDate,
                endIssueDate,
                max,
                start,
                startDueDate,
                startIssueDate,
                format,
                buyerCompanyIds,
                factorId,
                invoiceIds,
                searchQuery,
                sort,
                status,
                confirmingType,
            );
        }
    }

    getConfirmingApiV1SuppliersSupplieridDiscountsReport(
        supplierId: number,
        format: ReportFormat,
        max?: number,
        start?: number,
        startDate?: string,
        endDate?: string,
        buyerCompanyIds?: Array<number>,
        status?: DiscountStatusApi,
        searchQuery?: string,
        sort?: string,
        discountIds?: Array<number>,
        confirmingType?: ConfirmingTypeApi,
    ): Observable<unknown> {
        if (this.isApp) {
            return this.nativeApiService.getConfirmingApiV1SuppliersSupplieridDiscountsReport(
                supplierId,
                format,
                max,
                start,
                startDate,
                endDate,
                buyerCompanyIds,
                status,
                searchQuery,
                sort,
                discountIds,
                confirmingType,
            );
        } else {
            return this.findynamicConfirmingService.getConfirmingApiV1SuppliersSupplieridDiscountsReport(
                supplierId,
                format,
                max,
                start,
                startDate,
                endDate,
                buyerCompanyIds,
                status,
                searchQuery,
                sort,
                discountIds,
                confirmingType,
            );
        }
    }
}

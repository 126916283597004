/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CassettoFiscaleStatusView = 'Connected' | 'NotConnected' | 'Invalid' | 'Unreachable';

export const CassettoFiscaleStatusView = {
    Connected: 'Connected' as CassettoFiscaleStatusView,
    NotConnected: 'NotConnected' as CassettoFiscaleStatusView,
    Invalid: 'Invalid' as CassettoFiscaleStatusView,
    Unreachable: 'Unreachable' as CassettoFiscaleStatusView
};


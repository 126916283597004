/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PSD2OnboardingStatusView = 'Available' | 'Completed' | 'Error' | 'Pending';

export const PSD2OnboardingStatusView = {
    Available: 'Available' as PSD2OnboardingStatusView,
    Completed: 'Completed' as PSD2OnboardingStatusView,
    Error: 'Error' as PSD2OnboardingStatusView,
    Pending: 'Pending' as PSD2OnboardingStatusView
};


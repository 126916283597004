/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeferralInvoiceLineResponse } from './deferralInvoiceLineResponse';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface DeferralInvoiceFactorGroupResponse { 
    averageDeferralDays: number;
    factorId: number;
    factorName: string;
    invoiceAmount: number;
    netAmount: number;
    lines?: Array<DeferralInvoiceLineResponse>;
    integrationType: FactorIntegrationTypeView;
}
export namespace DeferralInvoiceFactorGroupResponse {
}



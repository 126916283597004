/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseInvoiceTypeView } from './reverseInvoiceTypeView';
import { ReverseInvoiceFilteredReasonView } from './reverseInvoiceFilteredReasonView';
import { VatView } from './vatView';
import { ReverseInvoiceStatusView } from './reverseInvoiceStatusView';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface ReverseInvoiceResponse { 
    id: number;
    documentNumber: string;
    buyerId: number;
    supplierCompanyId: number;
    supplierName: string;
    factorId: number;
    factorIntegrationType: FactorIntegrationTypeView;
    factorName: string;
    factorVat: VatView;
    division: string;
    divisionName: string;
    issueDate: string;
    dueDate: string;
    recognizeDate?: string;
    amount: number;
    status: ReverseInvoiceStatusView;
    currency: string;
    invoiceType: ReverseInvoiceTypeView;
    deferralDays: number;
    deferralBlocked: boolean;
    filteredReason?: ReverseInvoiceFilteredReasonView;
}
export namespace ReverseInvoiceResponse {
}



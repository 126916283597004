/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ConfirmingRelationStatusView = 'Disabled' | 'Enabled' | 'Inserted' | 'Invited';

export const ConfirmingRelationStatusView = {
    Disabled: 'Disabled' as ConfirmingRelationStatusView,
    Enabled: 'Enabled' as ConfirmingRelationStatusView,
    Inserted: 'Inserted' as ConfirmingRelationStatusView,
    Invited: 'Invited' as ConfirmingRelationStatusView
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplierInvoiceStatus } from './supplierInvoiceStatus';
import { VatView } from './vatView';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';
import { FilteredReasonView1 } from './filteredReasonView1';


export interface ActiveInvoiceResponse { 
    id: number;
    amount: number;
    buyerId: number;
    buyerName: string;
    buyerCode: string;
    division: string;
    factorIntegrationType?: FactorIntegrationTypeView;
    factorName?: string;
    factorVat?: VatView;
    documentNumber: string;
    keyCode: string;
    dueDate: string;
    issueDate: string;
    notesAmount?: number;
    prizeAmount?: number;
    status: SupplierInvoiceStatus;
    filteredReason?: FilteredReasonView1;
    cigCode?: string;
    cupCode?: string;
}
export namespace ActiveInvoiceResponse {
}



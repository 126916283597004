import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import {
    enableProdMode,
    importProvidersFrom,
    inject,
    Injectable,
    isDevMode,
    LOCALE_ID,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {
    getBrowserCultureLang,
    provideTransloco,
    Translation,
    translocoConfig,
    TranslocoLoader,
    TranslocoModule,
} from '@ngneat/transloco';
import {
    provideTranslocoLocale,
    TranslocoLocaleModule,
} from '@ngneat/transloco-locale';
import { SCROLL_VALUE_BOTTOM, SCROLL_VALUE_TOP } from 'packages/variables';
import { DEFAULT_PAGE_SIZE } from 'src/shared/variables/variables';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { CoreProviders } from './core-providers';
import { environment } from './environments/environment';
import { AuthService } from './shared/services/auth-service/auth.service';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private http = inject(HttpClient);

    getTranslation(lang: string) {
        return fetch(`/assets/i18n/${lang}.json`).then<Translation>((res) =>
            res.json(),
        );
    }
}

registerLocaleData(localeEs);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

if (environment.production) {
    enableProdMode();
}

const deviceLanguage = () => {
    const lang = getBrowserCultureLang();

    if (lang.substring(0, 2) === 'es' || lang.substring(0, 2) === 'eu') {
        return 'es-ES';
    } else if (lang === 'en-US') {
        return 'en-US';
    } else {
        return 'en-GB';
    }
};

export const translocoConf = translocoConfig({
    availableLangs: ['es', 'en'],
    defaultLang: deviceLanguage().slice(0, 2),
    fallbackLang: 'en',
    prodMode: !isDevMode(),
    reRenderOnLangChange: true,
});

bootstrapApplication(AppComponent, {
    providers: [
        AuthService,
        ...CoreProviders,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: deviceLanguage() },
        importProvidersFrom([
            IonicModule.forRoot({}),
            TranslocoLocaleModule,
            TranslocoModule,
        ]),
        { provide: DEFAULT_PAGE_SIZE, useValue: 10 },
        {
            provide: SCROLL_VALUE_TOP,
            useValue: 10,
        },
        {
            provide: SCROLL_VALUE_BOTTOM,
            useValue: 800,
        },
        provideRouter(routes),
        provideHttpClient(),
        provideTransloco({
            config: translocoConf,
            loader: TranslocoHttpLoader,
        }),
        provideTranslocoLocale(),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
});

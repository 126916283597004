/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFieldsSort { 
    field: PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFieldsSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFieldsSort {
    export type FieldEnum = 'Company' | 'Status';
    export const FieldEnum = {
        Company: 'Company' as FieldEnum,
        Status: 'Status' as FieldEnum
    };
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields7 = 'amount' | 'buyerName' | 'documentNumber' | 'dueDate' | 'factorName' | 'issueDate';

export const SortableFields7 = {
    Amount: 'amount' as SortableFields7,
    BuyerName: 'buyerName' as SortableFields7,
    DocumentNumber: 'documentNumber' as SortableFields7,
    DueDate: 'dueDate' as SortableFields7,
    FactorName: 'factorName' as SortableFields7,
    IssueDate: 'issueDate' as SortableFields7
};


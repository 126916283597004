/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiGridIntWrite } from './kpiGridIntWrite';
import { KpiTargetWriteInt } from './kpiTargetWriteInt';
import { KpiTarget0WriteInt } from './kpiTarget0WriteInt';
import { KpiGridDoubleWrite } from './kpiGridDoubleWrite';
import { KpiView } from './kpiView';
import { KpiGridBooleanWrite } from './kpiGridBooleanWrite';


/**
 * @type KpiGridWrite
 * @export
 */
export type KpiGridWrite = KpiGridBooleanWrite | KpiGridDoubleWrite | KpiGridIntWrite;


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { EarlySalaryBudgetRequest } from '../model/earlySalaryBudgetRequest';
// @ts-ignore
import { EarlySalaryBudgetResponse } from '../model/earlySalaryBudgetResponse';
// @ts-ignore
import { EarlySalaryBudgetUpdateRequest } from '../model/earlySalaryBudgetUpdateRequest';
// @ts-ignore
import { EarlySalaryBudgetsResponse } from '../model/earlySalaryBudgetsResponse';
// @ts-ignore
import { EarlySalaryCategoriesResponse } from '../model/earlySalaryCategoriesResponse';
// @ts-ignore
import { EarlySalaryCategoryRequest } from '../model/earlySalaryCategoryRequest';
// @ts-ignore
import { EarlySalaryCategoryResponse } from '../model/earlySalaryCategoryResponse';
// @ts-ignore
import { EarlySalaryCategoryUpdateRequest } from '../model/earlySalaryCategoryUpdateRequest';
// @ts-ignore
import { EarlySalaryEmployeeActivationQuery } from '../model/earlySalaryEmployeeActivationQuery';
// @ts-ignore
import { EarlySalaryEmployeeSortableFieldsView } from '../model/earlySalaryEmployeeSortableFieldsView';
// @ts-ignore
import { EarlySalaryEmployeeStatusView } from '../model/earlySalaryEmployeeStatusView';
// @ts-ignore
import { EarlySalaryEmployeesIdsResponse } from '../model/earlySalaryEmployeesIdsResponse';
// @ts-ignore
import { EarlySalaryEmployeesSummaryResponse } from '../model/earlySalaryEmployeesSummaryResponse';
// @ts-ignore
import { EarlySalaryHRRequestsSummaryResponse } from '../model/earlySalaryHRRequestsSummaryResponse';
// @ts-ignore
import { EarlySalaryProfileResponse } from '../model/earlySalaryProfileResponse';
// @ts-ignore
import { EarlySalaryRequest } from '../model/earlySalaryRequest';
// @ts-ignore
import { EarlySalaryRequestStatusView } from '../model/earlySalaryRequestStatusView';
// @ts-ignore
import { EarlySalaryRequestsActivationQuery } from '../model/earlySalaryRequestsActivationQuery';
// @ts-ignore
import { EarlySalaryRequestsSortableFieldsView } from '../model/earlySalaryRequestsSortableFieldsView';
// @ts-ignore
import { EarlySalaryRequestsSummaryResponse } from '../model/earlySalaryRequestsSummaryResponse';
// @ts-ignore
import { EarlySalaryServiceActivationView } from '../model/earlySalaryServiceActivationView';
// @ts-ignore
import { EarlySalarySettingsView } from '../model/earlySalarySettingsView';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsView } from '../model/paginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsView';
// @ts-ignore
import { PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsView } from '../model/paginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsView';
// @ts-ignore
import { PaginatedResponseEarlySalaryRequestFiltersResponseEarlySalaryRequestResponseEarlySalaryRequestsSortableFieldsView } from '../model/paginatedResponseEarlySalaryRequestFiltersResponseEarlySalaryRequestResponseEarlySalaryRequestsSortableFieldsView';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class EarlySalaryService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param accountId 
     * @param categoryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling deleteEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid.');
        }
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling deleteEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/categories/${this.configuration.encodeParam({name: "categoryId", value: categoryId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryEmployeeApiV1AccountsAccountidProfile(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryProfileResponse>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidProfile(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryProfileResponse>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidProfile(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryProfileResponse>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidProfile(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidProfile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/employee/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/profile`;
        return this.httpClient.request<EarlySalaryProfileResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param paginationStart 
     * @param paginationMax 
     * @param currency 
     * @param endIssueDate 
     * @param startIssueDate 
     * @param sort 
     * @param sortOrder 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseEarlySalaryRequestFiltersResponseEarlySalaryRequestResponseEarlySalaryRequestsSortableFieldsView>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseEarlySalaryRequestFiltersResponseEarlySalaryRequestResponseEarlySalaryRequestsSortableFieldsView>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseEarlySalaryRequestFiltersResponseEarlySalaryRequestResponseEarlySalaryRequestsSortableFieldsView>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }
        if (endIssueDate === null || endIssueDate === undefined) {
            throw new Error('Required parameter endIssueDate was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }
        if (startIssueDate === null || startIssueDate === undefined) {
            throw new Error('Required parameter startIssueDate was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endIssueDate !== undefined && endIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endIssueDate, 'endIssueDate');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (startIssueDate !== undefined && startIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIssueDate, 'startIssueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/employee/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests`;
        return this.httpClient.request<PaginatedResponseEarlySalaryRequestFiltersResponseEarlySalaryRequestResponseEarlySalaryRequestsSortableFieldsView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param currency 
     * @param endIssueDate 
     * @param startIssueDate 
     * @param format 
     * @param sort 
     * @param sortOrder 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, format: ReportFormat, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, format: ReportFormat, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, format: ReportFormat, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, format: ReportFormat, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport.');
        }
        if (endIssueDate === null || endIssueDate === undefined) {
            throw new Error('Required parameter endIssueDate was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport.');
        }
        if (startIssueDate === null || startIssueDate === undefined) {
            throw new Error('Required parameter startIssueDate was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequestsReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endIssueDate !== undefined && endIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endIssueDate, 'endIssueDate');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (startIssueDate !== undefined && startIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIssueDate, 'startIssueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/employee/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsSummary(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryRequestsSummaryResponse>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsSummary(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryRequestsSummaryResponse>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsSummary(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryRequestsSummaryResponse>>;
    public getEarlySalaryEmployeeApiV1AccountsAccountidRequestsSummary(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryEmployeeApiV1AccountsAccountidRequestsSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/employee/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests-summary`;
        return this.httpClient.request<EarlySalaryRequestsSummaryResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryBudgetsResponse>;
    public getEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryBudgetsResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryBudgetsResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidBudgets.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/budgets`;
        return this.httpClient.request<EarlySalaryBudgetsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryCategoriesResponse>;
    public getEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryCategoriesResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryCategoriesResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidCategories.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/categories`;
        return this.httpClient.request<EarlySalaryCategoriesResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param sortField 
     * @param sortOrder 
     * @param status 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidEmployeeIds(accountId: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryEmployeesIdsResponse>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeeIds(accountId: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryEmployeesIdsResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeeIds(accountId: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryEmployeesIdsResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeeIds(accountId: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidEmployeeIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employee-ids`;
        return this.httpClient.request<EarlySalaryEmployeesIdsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param status 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, paginationStart: number, paginationMax: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsView>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, paginationStart: number, paginationMax: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsView>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, paginationStart: number, paginationMax: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsView>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, paginationStart: number, paginationMax: number, sortField?: EarlySalaryEmployeeSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryEmployeeStatusView, search?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidEmployees.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidEmployees.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidEmployees.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employees`;
        return this.httpClient.request<PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesEmployeeidProfile(accountId: number, employeeId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryProfileResponse>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesEmployeeidProfile(accountId: number, employeeId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryProfileResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesEmployeeidProfile(accountId: number, employeeId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryProfileResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesEmployeeidProfile(accountId: number, employeeId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidEmployeesEmployeeidProfile.');
        }
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidEmployeesEmployeeidProfile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employees/${this.configuration.encodeParam({name: "employeeId", value: employeeId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/profile`;
        return this.httpClient.request<EarlySalaryProfileResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesSummary(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryEmployeesSummaryResponse>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesSummary(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryEmployeesSummaryResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesSummary(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryEmployeesSummaryResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidEmployeesSummary(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidEmployeesSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employees-summary`;
        return this.httpClient.request<EarlySalaryEmployeesSummaryResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param currency 
     * @param endIssueDate 
     * @param startIssueDate 
     * @param search 
     * @param sort 
     * @param sortOrder 
     * @param status 
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidRequestIds(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getEarlySalaryHrApiV1AccountsAccountidRequestIds(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getEarlySalaryHrApiV1AccountsAccountidRequestIds(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getEarlySalaryHrApiV1AccountsAccountidRequestIds(accountId: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequestIds.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequestIds.');
        }
        if (endIssueDate === null || endIssueDate === undefined) {
            throw new Error('Required parameter endIssueDate was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequestIds.');
        }
        if (startIssueDate === null || startIssueDate === undefined) {
            throw new Error('Required parameter startIssueDate was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequestIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endIssueDate !== undefined && endIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endIssueDate, 'endIssueDate');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (startIssueDate !== undefined && startIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIssueDate, 'startIssueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (employeeId !== undefined && employeeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>employeeId, 'employeeId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/request-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param paginationStart 
     * @param paginationMax 
     * @param currency 
     * @param endIssueDate 
     * @param startIssueDate 
     * @param search 
     * @param sort 
     * @param sortOrder 
     * @param status 
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsView>;
    public getEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsView>>;
    public getEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsView>>;
    public getEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, paginationStart: number, paginationMax: number, currency: string, endIssueDate: string, startIssueDate: string, search?: string, sort?: EarlySalaryRequestsSortableFieldsView, sortOrder?: SortOrder, status?: EarlySalaryRequestStatusView, employeeId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequests.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequests.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequests.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequests.');
        }
        if (endIssueDate === null || endIssueDate === undefined) {
            throw new Error('Required parameter endIssueDate was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequests.');
        }
        if (startIssueDate === null || startIssueDate === undefined) {
            throw new Error('Required parameter startIssueDate was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequests.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endIssueDate !== undefined && endIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endIssueDate, 'endIssueDate');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (startIssueDate !== undefined && startIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIssueDate, 'startIssueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (employeeId !== undefined && employeeId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>employeeId, 'employeeId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests`;
        return this.httpClient.request<PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param requestIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidRequestsSummary(accountId: number, requestIds: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryHRRequestsSummaryResponse>;
    public getEarlySalaryHrApiV1AccountsAccountidRequestsSummary(accountId: number, requestIds: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryHRRequestsSummaryResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidRequestsSummary(accountId: number, requestIds: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryHRRequestsSummaryResponse>>;
    public getEarlySalaryHrApiV1AccountsAccountidRequestsSummary(accountId: number, requestIds: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequestsSummary.');
        }
        if (requestIds === null || requestIds === undefined) {
            throw new Error('Required parameter requestIds was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidRequestsSummary.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (requestIds) {
            requestIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'requestIds');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests-summary`;
        return this.httpClient.request<EarlySalaryHRRequestsSummaryResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryServiceActivationView>;
    public getEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryServiceActivationView>>;
    public getEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryServiceActivationView>>;
    public getEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidServiceActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/service-activation`;
        return this.httpClient.request<EarlySalaryServiceActivationView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalarySettingsView>;
    public getEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalarySettingsView>>;
    public getEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalarySettingsView>>;
    public getEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getEarlySalaryHrApiV1AccountsAccountidSettings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/settings`;
        return this.httpClient.request<EarlySalarySettingsView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param earlySalaryRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequest: EarlySalaryRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequest: EarlySalaryRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequest: EarlySalaryRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEarlySalaryEmployeeApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequest: EarlySalaryRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling postEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }
        if (earlySalaryRequest === null || earlySalaryRequest === undefined) {
            throw new Error('Required parameter earlySalaryRequest was null or undefined when calling postEarlySalaryEmployeeApiV1AccountsAccountidRequests.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-ONE-TIME-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth3) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth3');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-OTP', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/employee/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param earlySalaryBudgetRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, earlySalaryBudgetRequest: EarlySalaryBudgetRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, earlySalaryBudgetRequest: EarlySalaryBudgetRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, earlySalaryBudgetRequest: EarlySalaryBudgetRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEarlySalaryHrApiV1AccountsAccountidBudgets(accountId: number, earlySalaryBudgetRequest: EarlySalaryBudgetRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling postEarlySalaryHrApiV1AccountsAccountidBudgets.');
        }
        if (earlySalaryBudgetRequest === null || earlySalaryBudgetRequest === undefined) {
            throw new Error('Required parameter earlySalaryBudgetRequest was null or undefined when calling postEarlySalaryHrApiV1AccountsAccountidBudgets.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/budgets`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryBudgetRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param earlySalaryCategoryRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, earlySalaryCategoryRequest: EarlySalaryCategoryRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, earlySalaryCategoryRequest: EarlySalaryCategoryRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, earlySalaryCategoryRequest: EarlySalaryCategoryRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEarlySalaryHrApiV1AccountsAccountidCategories(accountId: number, earlySalaryCategoryRequest: EarlySalaryCategoryRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling postEarlySalaryHrApiV1AccountsAccountidCategories.');
        }
        if (earlySalaryCategoryRequest === null || earlySalaryCategoryRequest === undefined) {
            throw new Error('Required parameter earlySalaryCategoryRequest was null or undefined when calling postEarlySalaryHrApiV1AccountsAccountidCategories.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/categories`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryCategoryRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param budgetId 
     * @param earlySalaryBudgetUpdateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putEarlySalaryHrApiV1AccountsAccountidBudgetsBudgetid(accountId: number, budgetId: number, earlySalaryBudgetUpdateRequest: EarlySalaryBudgetUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryBudgetResponse>;
    public putEarlySalaryHrApiV1AccountsAccountidBudgetsBudgetid(accountId: number, budgetId: number, earlySalaryBudgetUpdateRequest: EarlySalaryBudgetUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryBudgetResponse>>;
    public putEarlySalaryHrApiV1AccountsAccountidBudgetsBudgetid(accountId: number, budgetId: number, earlySalaryBudgetUpdateRequest: EarlySalaryBudgetUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryBudgetResponse>>;
    public putEarlySalaryHrApiV1AccountsAccountidBudgetsBudgetid(accountId: number, budgetId: number, earlySalaryBudgetUpdateRequest: EarlySalaryBudgetUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidBudgetsBudgetid.');
        }
        if (budgetId === null || budgetId === undefined) {
            throw new Error('Required parameter budgetId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidBudgetsBudgetid.');
        }
        if (earlySalaryBudgetUpdateRequest === null || earlySalaryBudgetUpdateRequest === undefined) {
            throw new Error('Required parameter earlySalaryBudgetUpdateRequest was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidBudgetsBudgetid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/budgets/${this.configuration.encodeParam({name: "budgetId", value: budgetId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<EarlySalaryBudgetResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryBudgetUpdateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param categoryId 
     * @param earlySalaryCategoryUpdateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, earlySalaryCategoryUpdateRequest: EarlySalaryCategoryUpdateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryCategoryResponse>;
    public putEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, earlySalaryCategoryUpdateRequest: EarlySalaryCategoryUpdateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryCategoryResponse>>;
    public putEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, earlySalaryCategoryUpdateRequest: EarlySalaryCategoryUpdateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryCategoryResponse>>;
    public putEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid(accountId: number, categoryId: number, earlySalaryCategoryUpdateRequest: EarlySalaryCategoryUpdateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid.');
        }
        if (categoryId === null || categoryId === undefined) {
            throw new Error('Required parameter categoryId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid.');
        }
        if (earlySalaryCategoryUpdateRequest === null || earlySalaryCategoryUpdateRequest === undefined) {
            throw new Error('Required parameter earlySalaryCategoryUpdateRequest was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidCategoriesCategoryid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/categories/${this.configuration.encodeParam({name: "categoryId", value: categoryId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<EarlySalaryCategoryResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryCategoryUpdateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param earlySalaryEmployeeActivationQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, earlySalaryEmployeeActivationQuery: EarlySalaryEmployeeActivationQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, earlySalaryEmployeeActivationQuery: EarlySalaryEmployeeActivationQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, earlySalaryEmployeeActivationQuery: EarlySalaryEmployeeActivationQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putEarlySalaryHrApiV1AccountsAccountidEmployees(accountId: number, earlySalaryEmployeeActivationQuery: EarlySalaryEmployeeActivationQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidEmployees.');
        }
        if (earlySalaryEmployeeActivationQuery === null || earlySalaryEmployeeActivationQuery === undefined) {
            throw new Error('Required parameter earlySalaryEmployeeActivationQuery was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidEmployees.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/employees`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryEmployeeActivationQuery,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param earlySalaryRequestsActivationQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequestsActivationQuery: EarlySalaryRequestsActivationQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequestsActivationQuery: EarlySalaryRequestsActivationQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequestsActivationQuery: EarlySalaryRequestsActivationQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putEarlySalaryHrApiV1AccountsAccountidRequests(accountId: number, earlySalaryRequestsActivationQuery: EarlySalaryRequestsActivationQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidRequests.');
        }
        if (earlySalaryRequestsActivationQuery === null || earlySalaryRequestsActivationQuery === undefined) {
            throw new Error('Required parameter earlySalaryRequestsActivationQuery was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidRequests.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryRequestsActivationQuery,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param earlySalaryServiceActivationView 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, earlySalaryServiceActivationView: EarlySalaryServiceActivationView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalaryServiceActivationView>;
    public putEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, earlySalaryServiceActivationView: EarlySalaryServiceActivationView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalaryServiceActivationView>>;
    public putEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, earlySalaryServiceActivationView: EarlySalaryServiceActivationView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalaryServiceActivationView>>;
    public putEarlySalaryHrApiV1AccountsAccountidServiceActivation(accountId: number, earlySalaryServiceActivationView: EarlySalaryServiceActivationView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidServiceActivation.');
        }
        if (earlySalaryServiceActivationView === null || earlySalaryServiceActivationView === undefined) {
            throw new Error('Required parameter earlySalaryServiceActivationView was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidServiceActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/service-activation`;
        return this.httpClient.request<EarlySalaryServiceActivationView>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalaryServiceActivationView,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param earlySalarySettingsView 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, earlySalarySettingsView: EarlySalarySettingsView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EarlySalarySettingsView>;
    public putEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, earlySalarySettingsView: EarlySalarySettingsView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EarlySalarySettingsView>>;
    public putEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, earlySalarySettingsView: EarlySalarySettingsView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EarlySalarySettingsView>>;
    public putEarlySalaryHrApiV1AccountsAccountidSettings(accountId: number, earlySalarySettingsView: EarlySalarySettingsView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidSettings.');
        }
        if (earlySalarySettingsView === null || earlySalarySettingsView === undefined) {
            throw new Error('Required parameter earlySalarySettingsView was null or undefined when calling putEarlySalaryHrApiV1AccountsAccountidSettings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/early-salary/hr/api/v1/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/settings`;
        return this.httpClient.request<EarlySalarySettingsView>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: earlySalarySettingsView,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActiveFinancingStatusUpdate = 'Approved' | 'Rejected' | 'Credited' | 'PaidByBuyer';

export const ActiveFinancingStatusUpdate = {
    Approved: 'Approved' as ActiveFinancingStatusUpdate,
    Rejected: 'Rejected' as ActiveFinancingStatusUpdate,
    Credited: 'Credited' as ActiveFinancingStatusUpdate,
    PaidByBuyer: 'PaidByBuyer' as ActiveFinancingStatusUpdate
};


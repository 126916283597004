/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields10 = 'company' | 'factorBuyerCode';

export const SortableFields10 = {
    Company: 'company' as SortableFields10,
    FactorBuyerCode: 'factorBuyerCode' as SortableFields10
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CompanySizeView = 'Micro' | 'Small' | 'Medium' | 'Large';

export const CompanySizeView = {
    Micro: 'Micro' as CompanySizeView,
    Small: 'Small' as CompanySizeView,
    Medium: 'Medium' as CompanySizeView,
    Large: 'Large' as CompanySizeView
};


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@capacitor/core';
import { DefaultService as ThothMobileClientService } from '@fd/clients/thoth-mobile/client';
import {
    AuthenticationIdentity,
    DeviceLogin200Response,
    DefaultService as ThothClientService,
} from '@fd/clients/thoth/client';
import { Platform } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IonicStorageService } from 'src/shared/storages/ionic-storage.service';

import { environment } from '../../../environments/environment';
import { IdentityUser, OtpResponse } from '../../models/AuthenticationToken';
import { UpdatePhoneResponse } from '../../storages/models/settings-model';
import { NativeApiService } from '../native-api-service/nativeApi.service';
import { TimeService } from '../time-service/time.service';

export type RedirectType = 'LOGIN' | 'LOGOUT' | 'BUYER' | 'SUPPLIER' | 'FACTOR';

export const PUBLIC_BASE_LOGIN_PATH = 'public';

export interface DataCompany {
    cf: { code: string; country: string; value: string };
    country: string;
    createTime: string;
    currency: string;
    id: number;
    name: string;
    rating: number;
    type: RedirectType;
    updateTime: string;
    vat: { code: string; country: string; value: string };
    version: number;
    visibilityCode: string;
}

@Injectable({
    providedIn: 'root',
})
export class ThothApiService {
    isApp = this.platform.is('capacitor');
    private readonly BASE_URL_NATIVE = environment.apiBasePath;

    constructor(
        private httpClient: HttpClient,
        private nativeApiService: NativeApiService,
        private platform: Platform,
        private storageService: IonicStorageService,
        private thothService: ThothClientService,
        private thothMobileService: ThothMobileClientService,
    ) {
        this.isApp = this.platform.is('capacitor');
    }

    getInfo(user: IdentityUser, companyId: number, newPhone: string) {
        if (this.isApp) {
            return this.nativeApiService.getInfo(user, companyId, newPhone);
        } else {
            return this.httpClient
                .get<HttpResponse>(
                    `${this.BASE_URL_NATIVE}/portal/api/option/${companyId}/user/${user.id}`,
                )
                .pipe(
                    map((response) => ({
                        companyId: companyId,
                        newPhone: newPhone,
                        user: user,
                        userSettings: response,
                    })),
                );
        }
    }

    login(
        username: string,
        password: string,
        persistentSession: string,
    ): Observable<DeviceLogin200Response> {
        if (this.isApp) {
            return this.nativeApiService.doLogin(
                username,
                password,
                persistentSession,
            );
        } else {
            this.storageService.clear();

            return this.thothService.doLoginRoute(username, password);
        }
    }

    logout(): Observable<boolean> {
        if (this.isApp) {
            return this.nativeApiService.doLogout();
        } else {
            return this.httpClient
                .get<DataCompany>('/thoth/api/v1/identity/logout')
                .pipe(
                    tap(() => {
                        this.storageService.clear();
                    }),
                    map(() => {
                        return true;
                    }),
                    catchError(() => {
                        return of(false);
                    }),
                );
        }
    }

    newOtp(oneTimeToken: string, username: string): Observable<OtpResponse> {
        if (this.isApp) {
            return this.nativeApiService.doNewOtp(oneTimeToken, username);
        } else {
            return this.thothService.resendOtp({
                oneTimeToken: oneTimeToken,
                username: username,
            });
        }
    }

    otp(
        code: string,
        oneTimeToken: string,
        username: string,
    ): Observable<AuthenticationIdentity> {
        if (this.isApp) {
            return this.nativeApiService.doOtpLogin(
                code,
                oneTimeToken,
                username,
            );
        } else {
            return this.thothService.checkOtpRoute({
                code: code,
                oneTimeToken: oneTimeToken,
                username: username,
            });
        }
    }

    phone(
        phone: string,
        oneTimeToken: string,
        email: string,
    ): Observable<OtpResponse> {
        if (this.isApp) {
            return this.nativeApiService.doPhone(phone, oneTimeToken, email);
        } else {
            const body = { email, oneTimeToken, phone };

            return this.httpClient.post<OtpResponse>(
                '/thoth/api/v1/identity/mfa/activation',
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            );
        }
    }

    postThothApiV1IdentityAuthorizeOtp(): Observable<OtpResponse> {
        if (this.isApp) {
            return this.nativeApiService.postThothApiV1IdentityAuthorizeOtp();
        } else {
            return this.thothMobileService.postThothApiV1IdentityAuthorizeOtp();
        }
    }

    updateInfo(
        data: UpdatePhoneResponse,
    ): Observable<HttpResponse | IdentityUser> {
        if (this.isApp) {
            return this.nativeApiService.updateInfo(data);
        } else {
            return this.httpClient
                .put<HttpResponse>(
                    `${this.BASE_URL_NATIVE}/portal/api/option/${data.companyId}/user/${data.user.id}/self`,
                    {
                        ...data.userSettings,
                        phone: data.newPhone,
                        updateTime: TimeService.timestamp(),
                    },
                )
                .pipe(
                    map((res) =>
                        res?.data
                            ? { ...data.user, phone: res.data.phone }
                            : res,
                    ),
                );
        }
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InvoiceSummaryQuery } from './invoiceSummaryQuery';
import { BuyerBudgetCheck } from './buyerBudgetCheck';


export interface BuyerInvoiceSummaryResponse { 
    request: InvoiceSummaryQuery;
    budgetCheck: BuyerBudgetCheck;
    count: number;
    confirmingInvoiceIds?: Array<number>;
    deferralInvoiceIds?: Array<number>;
    changeableStatusInvoiceIds?: Array<number>;
    invoicesAmount: number;
    invoicesPrizeAmount: number;
    invoicesPrizeAmountPercent: number;
    notesAmount: number;
    newAmount: number;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Signer } from './signer';
import { Parameters } from './parameters';
import { VatView } from './vatView';
import { Budgets } from './budgets';


export interface Factor { 
    factorId: number;
    factorName: string;
    vat: VatView;
    budget: Budgets;
    parameters: Parameters;
    signers?: Array<Signer>;
}


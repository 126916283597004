/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OptionWrapperRefined } from './optionWrapperRefined';


/**
 * We need the OptionWrapper on maxDaysToExpiration to distinguish between   - overriding a cluster value to discard the maxDaysToExpiration -> Some(OptionWrapper(None))  - letting the cluster value pass through -> None   since Option[Option[Int]] gets flattened by Tapir as a simple nullable value 
 */
export interface CustomParametersView { 
    discountDynamicRate?: number;
    tnDays?: number;
    minStock?: number;
    minimumAmount?: number;
    maximumAmount?: number;
    prize?: number;
    discountFixedRate?: number;
    maxDaysToExpiration?: OptionWrapperRefined;
}


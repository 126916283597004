/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceType } from './serviceType';
import { Service } from './service';
import { ServiceStatus1 } from './serviceStatus1';


export interface ServiceListResponse { 
    serviceType?: ServiceType;
    service: Service;
    serviceStatus: ServiceStatus1;
}
export namespace ServiceListResponse {
}



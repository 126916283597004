/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { KpiView } from '../model/kpiView';
// @ts-ignore
import { LoanCreationError } from '../model/loanCreationError';
// @ts-ignore
import { LoanCreationRequest } from '../model/loanCreationRequest';
// @ts-ignore
import { LoanUpdateError } from '../model/loanUpdateError';
// @ts-ignore
import { LoanUpdateRequest } from '../model/loanUpdateRequest';
// @ts-ignore
import { LoansResponse } from '../model/loansResponse';
// @ts-ignore
import { MarginGridTemplateError } from '../model/marginGridTemplateError';
// @ts-ignore
import { MarginGridTemplateResponse } from '../model/marginGridTemplateResponse';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsView } from '../model/paginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsView';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { SortableLoansFieldsView } from '../model/sortableLoansFieldsView';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class LoanService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoanApiV1FactorsFactoridLoansCompanies(factorId: number, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsView>;
    public getLoanApiV1FactorsFactoridLoansCompanies(factorId: number, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsView>>;
    public getLoanApiV1FactorsFactoridLoansCompanies(factorId: number, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsView>>;
    public getLoanApiV1FactorsFactoridLoansCompanies(factorId: number, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompanies.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'companyId');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/loan/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans/companies`;
        return this.httpClient.request<PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<LoansResponse>;
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<LoansResponse>>;
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<LoansResponse>>;
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/loan/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans`;
        return this.httpClient.request<LoansResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param kpi1 
     * @param kpi2 
     * @param firstBalanceSheetDate 
     * @param temporaryIssueDate 
     * @param loanPeriodInMonths 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate(factorId: number, companyId: number, kpi1: KpiView, kpi2: KpiView, firstBalanceSheetDate: string, temporaryIssueDate: string, loanPeriodInMonths: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<MarginGridTemplateResponse>;
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate(factorId: number, companyId: number, kpi1: KpiView, kpi2: KpiView, firstBalanceSheetDate: string, temporaryIssueDate: string, loanPeriodInMonths: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<MarginGridTemplateResponse>>;
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate(factorId: number, companyId: number, kpi1: KpiView, kpi2: KpiView, firstBalanceSheetDate: string, temporaryIssueDate: string, loanPeriodInMonths: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<MarginGridTemplateResponse>>;
    public getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate(factorId: number, companyId: number, kpi1: KpiView, kpi2: KpiView, firstBalanceSheetDate: string, temporaryIssueDate: string, loanPeriodInMonths: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate.');
        }
        if (kpi1 === null || kpi1 === undefined) {
            throw new Error('Required parameter kpi1 was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate.');
        }
        if (kpi2 === null || kpi2 === undefined) {
            throw new Error('Required parameter kpi2 was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate.');
        }
        if (firstBalanceSheetDate === null || firstBalanceSheetDate === undefined) {
            throw new Error('Required parameter firstBalanceSheetDate was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate.');
        }
        if (temporaryIssueDate === null || temporaryIssueDate === undefined) {
            throw new Error('Required parameter temporaryIssueDate was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate.');
        }
        if (loanPeriodInMonths === null || loanPeriodInMonths === undefined) {
            throw new Error('Required parameter loanPeriodInMonths was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesCompanyidMarginGridTemplate.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (kpi1 !== undefined && kpi1 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>kpi1, 'kpi1');
        }
        if (kpi2 !== undefined && kpi2 !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>kpi2, 'kpi2');
        }
        if (firstBalanceSheetDate !== undefined && firstBalanceSheetDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>firstBalanceSheetDate, 'firstBalanceSheetDate');
        }
        if (temporaryIssueDate !== undefined && temporaryIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>temporaryIssueDate, 'temporaryIssueDate');
        }
        if (loanPeriodInMonths !== undefined && loanPeriodInMonths !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>loanPeriodInMonths, 'loanPeriodInMonths');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/loan/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/margin-grid-template`;
        return this.httpClient.request<MarginGridTemplateResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param companyId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoanApiV1FactorsFactoridLoansCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getLoanApiV1FactorsFactoridLoansCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getLoanApiV1FactorsFactoridLoansCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getLoanApiV1FactorsFactoridLoansCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, paginationStart?: number, paginationMax?: number, sortField?: SortableLoansFieldsView, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesReports.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getLoanApiV1FactorsFactoridLoansCompaniesReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'companyId');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/loan/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans/companies/reports`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param loanCreationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanCreationRequest: LoanCreationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanCreationRequest: LoanCreationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanCreationRequest: LoanCreationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanCreationRequest: LoanCreationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling postLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans.');
        }
        if (loanCreationRequest === null || loanCreationRequest === undefined) {
            throw new Error('Required parameter loanCreationRequest was null or undefined when calling postLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/loan/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: loanCreationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param loanUpdateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanUpdateRequest?: Array<LoanUpdateRequest>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanUpdateRequest?: Array<LoanUpdateRequest>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanUpdateRequest?: Array<LoanUpdateRequest>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans(factorId: number, companyId: number, loanUpdateRequest?: Array<LoanUpdateRequest>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling putLoanApiV1FactorsFactoridLoansCompaniesCompanyidLoans.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/loan/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/loans`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: loanUpdateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

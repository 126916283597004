/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DiscountRequestLineErrorResponse = 'DiscountInvoiceNotEligible' | 'DiscountInvoiceNotFound' | 'DiscountRequestNotValid' | 'DiscountRequestSimulation' | 'DiscountNoBudget';

export const DiscountRequestLineErrorResponse = {
    DiscountInvoiceNotEligible: 'DiscountInvoiceNotEligible' as DiscountRequestLineErrorResponse,
    DiscountInvoiceNotFound: 'DiscountInvoiceNotFound' as DiscountRequestLineErrorResponse,
    DiscountRequestNotValid: 'DiscountRequestNotValid' as DiscountRequestLineErrorResponse,
    DiscountRequestSimulation: 'DiscountRequestSimulation' as DiscountRequestLineErrorResponse,
    DiscountNoBudget: 'DiscountNoBudget' as DiscountRequestLineErrorResponse
};


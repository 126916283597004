/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SupplierInvoiceStatus = 'Credited' | 'DiscountApproval' | 'Discounted' | 'DocumentToSign' | 'Filtered' | 'Inserted' | 'PaidByBuyer' | 'Unpaid';

export const SupplierInvoiceStatus = {
    Credited: 'Credited' as SupplierInvoiceStatus,
    DiscountApproval: 'DiscountApproval' as SupplierInvoiceStatus,
    Discounted: 'Discounted' as SupplierInvoiceStatus,
    DocumentToSign: 'DocumentToSign' as SupplierInvoiceStatus,
    Filtered: 'Filtered' as SupplierInvoiceStatus,
    Inserted: 'Inserted' as SupplierInvoiceStatus,
    PaidByBuyer: 'PaidByBuyer' as SupplierInvoiceStatus,
    Unpaid: 'Unpaid' as SupplierInvoiceStatus
};


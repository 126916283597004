/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFieldsSort } from './paginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFieldsSort';
import { PaginationResponse } from './paginationResponse';
import { ActiveBuyerResponse } from './activeBuyerResponse';


export interface PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFields { 
    filters: object;
    pagination: PaginationResponse;
    sort?: PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFieldsSort;
    page?: Array<ActiveBuyerResponse>;
}


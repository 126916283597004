import { IdentityOrganization, IdentityUser } from '@fd/clients/thoth/client';

import { BuyerModel } from './buyer-model';

export interface IonicStorageModel {
    currentCompany: BuyerModel;
    loginUserName: string;
    oneTimeTokenLocalStorageKey: string;
    organization: IdentityOrganization;
    otpLocalStorageKey: string;
    user: IdentityUser;
    XSRFTokenLocalStorageKey: string;
}

export type IonicStorageModelKeys = keyof IonicStorageModel;

export const IonicStorageModelKeys = {
    currentCompany: 'currentCompany' as IonicStorageModelKeys,
    loginUserName: 'loginUserName' as IonicStorageModelKeys,
    oneTimeTokenLocalStorageKey:
        'oneTimeTokenLocalStorageKey' as IonicStorageModelKeys,
    organization: 'organization' as IonicStorageModelKeys,
    otpLocalStorageKey: 'otpLocalStorageKey' as IonicStorageModelKeys,
    user: 'user' as IonicStorageModelKeys,
    XSRFTokenLocalStorageKey:
        'XSRFTokenLocalStorageKey' as IonicStorageModelKeys,
};

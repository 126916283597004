/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { CompanyAccountInvitationRequest } from '../model/companyAccountInvitationRequest';
// @ts-ignore
import { CompanyAutocompleteResponse } from '../model/companyAutocompleteResponse';
// @ts-ignore
import { CompanyEsgStatusApi } from '../model/companyEsgStatusApi';
// @ts-ignore
import { CompanyInvitationRequest } from '../model/companyInvitationRequest';
// @ts-ignore
import { CompanyInviteRequest } from '../model/companyInviteRequest';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { EsgCustomerShareScoresRequest } from '../model/esgCustomerShareScoresRequest';
// @ts-ignore
import { EsgQuestionnaireResponse } from '../model/esgQuestionnaireResponse';
// @ts-ignore
import { EsgRequestApi } from '../model/esgRequestApi';
// @ts-ignore
import { EsgRequesterQuestionnaireResponse } from '../model/esgRequesterQuestionnaireResponse';
// @ts-ignore
import { EsgRequesterRequestsApi } from '../model/esgRequesterRequestsApi';
// @ts-ignore
import { EsgRequestsApi } from '../model/esgRequestsApi';
// @ts-ignore
import { EsgSimulationRequest } from '../model/esgSimulationRequest';
// @ts-ignore
import { EsgSimulationResponse } from '../model/esgSimulationResponse';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { InvitationSettingsResponse } from '../model/invitationSettingsResponse';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApi } from '../model/paginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApi';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { SortableFieldsApi } from '../model/sortableFieldsApi';
// @ts-ignore
import { TargetStatusResponse } from '../model/targetStatusResponse';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class EsgService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param customerId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1CustomersCustomeridDocumentsDocumentid(customerId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getEsgApiV1CustomersCustomeridDocumentsDocumentid(customerId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getEsgApiV1CustomersCustomeridDocumentsDocumentid(customerId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getEsgApiV1CustomersCustomeridDocumentsDocumentid(customerId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getEsgApiV1CustomersCustomeridDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getEsgApiV1CustomersCustomeridDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1CustomersCustomeridRequests(customerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EsgRequestsApi>;
    public getEsgApiV1CustomersCustomeridRequests(customerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EsgRequestsApi>>;
    public getEsgApiV1CustomersCustomeridRequests(customerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EsgRequestsApi>>;
    public getEsgApiV1CustomersCustomeridRequests(customerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getEsgApiV1CustomersCustomeridRequests.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests`;
        return this.httpClient.request<EsgRequestsApi>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1CustomersCustomeridSettings(customerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvitationSettingsResponse>;
    public getEsgApiV1CustomersCustomeridSettings(customerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvitationSettingsResponse>>;
    public getEsgApiV1CustomersCustomeridSettings(customerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvitationSettingsResponse>>;
    public getEsgApiV1CustomersCustomeridSettings(customerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getEsgApiV1CustomersCustomeridSettings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/settings`;
        return this.httpClient.request<InvitationSettingsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param targetVatCountry 
     * @param targetVatCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1CustomersCustomeridTargetsRequests(customerId: number, targetVatCountry: string, targetVatCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EsgRequesterRequestsApi>;
    public getEsgApiV1CustomersCustomeridTargetsRequests(customerId: number, targetVatCountry: string, targetVatCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EsgRequesterRequestsApi>>;
    public getEsgApiV1CustomersCustomeridTargetsRequests(customerId: number, targetVatCountry: string, targetVatCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EsgRequesterRequestsApi>>;
    public getEsgApiV1CustomersCustomeridTargetsRequests(customerId: number, targetVatCountry: string, targetVatCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsRequests.');
        }
        if (targetVatCountry === null || targetVatCountry === undefined) {
            throw new Error('Required parameter targetVatCountry was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsRequests.');
        }
        if (targetVatCode === null || targetVatCode === undefined) {
            throw new Error('Required parameter targetVatCode was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsRequests.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (targetVatCountry !== undefined && targetVatCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetVatCountry, 'targetVatCountry');
        }
        if (targetVatCode !== undefined && targetVatCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetVatCode, 'targetVatCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/targets/requests`;
        return this.httpClient.request<EsgRequesterRequestsApi>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param documentId 
     * @param targetVatCountry 
     * @param targetVatCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid(customerId: number, documentId: number, targetVatCountry: string, targetVatCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid(customerId: number, documentId: number, targetVatCountry: string, targetVatCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid(customerId: number, documentId: number, targetVatCountry: string, targetVatCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid(customerId: number, documentId: number, targetVatCountry: string, targetVatCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid.');
        }
        if (targetVatCountry === null || targetVatCountry === undefined) {
            throw new Error('Required parameter targetVatCountry was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid.');
        }
        if (targetVatCode === null || targetVatCode === undefined) {
            throw new Error('Required parameter targetVatCode was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsScoresDocumentsDocumentid.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (targetVatCountry !== undefined && targetVatCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetVatCountry, 'targetVatCountry');
        }
        if (targetVatCode !== undefined && targetVatCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetVatCode, 'targetVatCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/targets/scores/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param targetVatCountry 
     * @param targetVatCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1CustomersCustomeridTargetsStatus(customerId: number, targetVatCountry: string, targetVatCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<TargetStatusResponse>;
    public getEsgApiV1CustomersCustomeridTargetsStatus(customerId: number, targetVatCountry: string, targetVatCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TargetStatusResponse>>;
    public getEsgApiV1CustomersCustomeridTargetsStatus(customerId: number, targetVatCountry: string, targetVatCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TargetStatusResponse>>;
    public getEsgApiV1CustomersCustomeridTargetsStatus(customerId: number, targetVatCountry: string, targetVatCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsStatus.');
        }
        if (targetVatCountry === null || targetVatCountry === undefined) {
            throw new Error('Required parameter targetVatCountry was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsStatus.');
        }
        if (targetVatCode === null || targetVatCode === undefined) {
            throw new Error('Required parameter targetVatCode was null or undefined when calling getEsgApiV1CustomersCustomeridTargetsStatus.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (targetVatCountry !== undefined && targetVatCountry !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetVatCountry, 'targetVatCountry');
        }
        if (targetVatCode !== undefined && targetVatCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>targetVatCode, 'targetVatCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/targets/status`;
        return this.httpClient.request<TargetStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param paginationStart 
     * @param paginationMax 
     * @param companyId 
     * @param status 
     * @param sortField 
     * @param sortOrder 
     * @param scoreShared 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompanies(factorId: number, paginationStart: number, paginationMax: number, companyId?: number, status?: CompanyEsgStatusApi, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApi>;
    public getEsgApiV1FactorsFactoridCompanies(factorId: number, paginationStart: number, paginationMax: number, companyId?: number, status?: CompanyEsgStatusApi, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApi>>;
    public getEsgApiV1FactorsFactoridCompanies(factorId: number, paginationStart: number, paginationMax: number, companyId?: number, status?: CompanyEsgStatusApi, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApi>>;
    public getEsgApiV1FactorsFactoridCompanies(factorId: number, paginationStart: number, paginationMax: number, companyId?: number, status?: CompanyEsgStatusApi, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompanies.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getEsgApiV1FactorsFactoridCompanies.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getEsgApiV1FactorsFactoridCompanies.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'companyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (scoreShared !== undefined && scoreShared !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scoreShared, 'scoreShared');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies`;
        return this.httpClient.request<PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApi>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompaniesAutocomplete(factorId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CompanyAutocompleteResponse>;
    public getEsgApiV1FactorsFactoridCompaniesAutocomplete(factorId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CompanyAutocompleteResponse>>;
    public getEsgApiV1FactorsFactoridCompaniesAutocomplete(factorId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CompanyAutocompleteResponse>>;
    public getEsgApiV1FactorsFactoridCompaniesAutocomplete(factorId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/autocomplete`;
        return this.httpClient.request<CompanyAutocompleteResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompaniesCompanyid(factorId: number, companyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EsgRequesterRequestsApi>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyid(factorId: number, companyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EsgRequesterRequestsApi>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyid(factorId: number, companyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EsgRequesterRequestsApi>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyid(factorId: number, companyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyid.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<EsgRequesterRequestsApi>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompaniesCompanyidDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidDocumentsDocumentid.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param questionnaireId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireid(factorId: number, companyId: number, questionnaireId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EsgRequesterQuestionnaireResponse>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireid(factorId: number, companyId: number, questionnaireId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EsgRequesterQuestionnaireResponse>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireid(factorId: number, companyId: number, questionnaireId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EsgRequesterQuestionnaireResponse>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireid(factorId: number, companyId: number, questionnaireId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireid.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireid.');
        }
        if (questionnaireId === null || questionnaireId === undefined) {
            throw new Error('Required parameter questionnaireId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/questionnaires/${this.configuration.encodeParam({name: "questionnaireId", value: questionnaireId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<EsgRequesterQuestionnaireResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param questionnaireId 
     * @param simulationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid(factorId: number, companyId: number, questionnaireId: number, simulationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EsgSimulationResponse>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid(factorId: number, companyId: number, questionnaireId: number, simulationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EsgSimulationResponse>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid(factorId: number, companyId: number, questionnaireId: number, simulationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EsgSimulationResponse>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid(factorId: number, companyId: number, questionnaireId: number, simulationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid.');
        }
        if (questionnaireId === null || questionnaireId === undefined) {
            throw new Error('Required parameter questionnaireId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid.');
        }
        if (simulationId === null || simulationId === undefined) {
            throw new Error('Required parameter simulationId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulationsSimulationid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/questionnaires/${this.configuration.encodeParam({name: "questionnaireId", value: questionnaireId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/simulations/${this.configuration.encodeParam({name: "simulationId", value: simulationId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<EsgSimulationResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompaniesCompanyidSimulationDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidSimulationDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidSimulationDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getEsgApiV1FactorsFactoridCompaniesCompanyidSimulationDocumentsDocumentid(factorId: number, companyId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidSimulationDocumentsDocumentid.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidSimulationDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesCompanyidSimulationDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/simulation-documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param companyId 
     * @param status 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param scoreShared 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, status?: CompanyEsgStatusApi, paginationStart?: number, paginationMax?: number, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getEsgApiV1FactorsFactoridCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, status?: CompanyEsgStatusApi, paginationStart?: number, paginationMax?: number, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getEsgApiV1FactorsFactoridCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, status?: CompanyEsgStatusApi, paginationStart?: number, paginationMax?: number, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getEsgApiV1FactorsFactoridCompaniesReports(factorId: number, format: ReportFormat, companyId?: number, status?: CompanyEsgStatusApi, paginationStart?: number, paginationMax?: number, sortField?: SortableFieldsApi, sortOrder?: SortOrder, scoreShared?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesReports.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getEsgApiV1FactorsFactoridCompaniesReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>companyId, 'companyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (scoreShared !== undefined && scoreShared !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>scoreShared, 'scoreShared');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/reports`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEsgApiV1FactorsFactoridInvitationsSettings(factorId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvitationSettingsResponse>;
    public getEsgApiV1FactorsFactoridInvitationsSettings(factorId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvitationSettingsResponse>>;
    public getEsgApiV1FactorsFactoridInvitationsSettings(factorId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvitationSettingsResponse>>;
    public getEsgApiV1FactorsFactoridInvitationsSettings(factorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getEsgApiV1FactorsFactoridInvitationsSettings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invitations/settings`;
        return this.httpClient.request<InvitationSettingsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEsgApiV1CustomersCustomeridRequestsRequestidQuestionnaire(customerId: number, requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EsgQuestionnaireResponse>;
    public postEsgApiV1CustomersCustomeridRequestsRequestidQuestionnaire(customerId: number, requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EsgQuestionnaireResponse>>;
    public postEsgApiV1CustomersCustomeridRequestsRequestidQuestionnaire(customerId: number, requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EsgQuestionnaireResponse>>;
    public postEsgApiV1CustomersCustomeridRequestsRequestidQuestionnaire(customerId: number, requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling postEsgApiV1CustomersCustomeridRequestsRequestidQuestionnaire.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling postEsgApiV1CustomersCustomeridRequestsRequestidQuestionnaire.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/questionnaire`;
        return this.httpClient.request<EsgQuestionnaireResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param esgCustomerShareScoresRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEsgApiV1CustomersCustomeridShareScores(customerId: number, esgCustomerShareScoresRequest: EsgCustomerShareScoresRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEsgApiV1CustomersCustomeridShareScores(customerId: number, esgCustomerShareScoresRequest: EsgCustomerShareScoresRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEsgApiV1CustomersCustomeridShareScores(customerId: number, esgCustomerShareScoresRequest: EsgCustomerShareScoresRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEsgApiV1CustomersCustomeridShareScores(customerId: number, esgCustomerShareScoresRequest: EsgCustomerShareScoresRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling postEsgApiV1CustomersCustomeridShareScores.');
        }
        if (esgCustomerShareScoresRequest === null || esgCustomerShareScoresRequest === undefined) {
            throw new Error('Required parameter esgCustomerShareScoresRequest was null or undefined when calling postEsgApiV1CustomersCustomeridShareScores.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/share-scores`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: esgCustomerShareScoresRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param customerId 
     * @param targetId 
     * @param companyAccountInvitationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEsgApiV1CustomersCustomeridTargetsTargetidInvitations(customerId: number, targetId: number, companyAccountInvitationRequest: CompanyAccountInvitationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEsgApiV1CustomersCustomeridTargetsTargetidInvitations(customerId: number, targetId: number, companyAccountInvitationRequest: CompanyAccountInvitationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEsgApiV1CustomersCustomeridTargetsTargetidInvitations(customerId: number, targetId: number, companyAccountInvitationRequest: CompanyAccountInvitationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEsgApiV1CustomersCustomeridTargetsTargetidInvitations(customerId: number, targetId: number, companyAccountInvitationRequest: CompanyAccountInvitationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling postEsgApiV1CustomersCustomeridTargetsTargetidInvitations.');
        }
        if (targetId === null || targetId === undefined) {
            throw new Error('Required parameter targetId was null or undefined when calling postEsgApiV1CustomersCustomeridTargetsTargetidInvitations.');
        }
        if (companyAccountInvitationRequest === null || companyAccountInvitationRequest === undefined) {
            throw new Error('Required parameter companyAccountInvitationRequest was null or undefined when calling postEsgApiV1CustomersCustomeridTargetsTargetidInvitations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/customers/${this.configuration.encodeParam({name: "customerId", value: customerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/targets/${this.configuration.encodeParam({name: "targetId", value: targetId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invitations`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: companyAccountInvitationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyInviteRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEsgApiV1FactorsFactoridCompanies(factorId: number, companyInviteRequest: CompanyInviteRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEsgApiV1FactorsFactoridCompanies(factorId: number, companyInviteRequest: CompanyInviteRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEsgApiV1FactorsFactoridCompanies(factorId: number, companyInviteRequest: CompanyInviteRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEsgApiV1FactorsFactoridCompanies(factorId: number, companyInviteRequest: CompanyInviteRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postEsgApiV1FactorsFactoridCompanies.');
        }
        if (companyInviteRequest === null || companyInviteRequest === undefined) {
            throw new Error('Required parameter companyInviteRequest was null or undefined when calling postEsgApiV1FactorsFactoridCompanies.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: companyInviteRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param companyInvitationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEsgApiV1FactorsFactoridCompaniesCompanyidInvitations(factorId: number, companyId: number, companyInvitationRequest: CompanyInvitationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidInvitations(factorId: number, companyId: number, companyInvitationRequest: CompanyInvitationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidInvitations(factorId: number, companyId: number, companyInvitationRequest: CompanyInvitationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidInvitations(factorId: number, companyId: number, companyInvitationRequest: CompanyInvitationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidInvitations.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidInvitations.');
        }
        if (companyInvitationRequest === null || companyInvitationRequest === undefined) {
            throw new Error('Required parameter companyInvitationRequest was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidInvitations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invitations`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: companyInvitationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param questionnaireId 
     * @param esgSimulationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations(factorId: number, companyId: number, questionnaireId: number, esgSimulationRequest: EsgSimulationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations(factorId: number, companyId: number, questionnaireId: number, esgSimulationRequest: EsgSimulationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations(factorId: number, companyId: number, questionnaireId: number, esgSimulationRequest: EsgSimulationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations(factorId: number, companyId: number, questionnaireId: number, esgSimulationRequest: EsgSimulationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations.');
        }
        if (questionnaireId === null || questionnaireId === undefined) {
            throw new Error('Required parameter questionnaireId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations.');
        }
        if (esgSimulationRequest === null || esgSimulationRequest === undefined) {
            throw new Error('Required parameter esgSimulationRequest was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidQuestionnairesQuestionnaireidSimulations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/questionnaires/${this.configuration.encodeParam({name: "questionnaireId", value: questionnaireId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/simulations`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: esgSimulationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param companyId 
     * @param scoreId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postEsgApiV1FactorsFactoridCompaniesCompanyidScoresScoreidShare(factorId: number, companyId: number, scoreId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EsgRequestApi>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidScoresScoreidShare(factorId: number, companyId: number, scoreId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EsgRequestApi>>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidScoresScoreidShare(factorId: number, companyId: number, scoreId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EsgRequestApi>>;
    public postEsgApiV1FactorsFactoridCompaniesCompanyidScoresScoreidShare(factorId: number, companyId: number, scoreId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidScoresScoreidShare.');
        }
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidScoresScoreidShare.');
        }
        if (scoreId === null || scoreId === undefined) {
            throw new Error('Required parameter scoreId was null or undefined when calling postEsgApiV1FactorsFactoridCompaniesCompanyidScoresScoreidShare.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/esg/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/scores/${this.configuration.encodeParam({name: "scoreId", value: scoreId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/share`;
        return this.httpClient.request<EsgRequestApi>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

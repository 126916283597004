/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiGridUpdate } from './kpiGridUpdate';
import { LoanUpdateRegistry } from './loanUpdateRegistry';


export interface LoanUpdateRequest { 
    loanRegistry: LoanUpdateRegistry;
    kpi1?: KpiGridUpdate;
    kpi2?: KpiGridUpdate;
}


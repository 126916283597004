/**
 * Identity API
 * Identity API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Saml2Info } from './saml2Info';
import { GroupResponse } from './groupResponse';


export interface IdentityUser { 
    id: number;
    uuid: string;
    login: boolean;
    email: string;
    firstName: string;
    fiscalCode?: string;
    lastName: string;
    legalEntity: boolean;
    timeZone: string;
    locale: string;
    groups?: Array<GroupResponse>;
    phone?: string;
    status: string;
    saml2Info?: Saml2Info;
}


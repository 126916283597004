/**
 * Identity API
 * Identity API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuthenticationIdentity } from './authenticationIdentity';


export interface AuthenticationToken { 
    status: string;
    token?: string;
    identity?: AuthenticationIdentity;
    phone?: string;
    blockedUntil?: string;
    redirect?: string;
    multiFactorAuthenticationChannel?: string;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReverseFactorInvoiceStatusView = 'Approved' | 'Credited' | 'PaidByBuyer' | 'Refused' | 'Rejected' | 'ToApprove' | 'ToRecognize' | 'ToSign' | 'Uploaded';

export const ReverseFactorInvoiceStatusView = {
    Approved: 'Approved' as ReverseFactorInvoiceStatusView,
    Credited: 'Credited' as ReverseFactorInvoiceStatusView,
    PaidByBuyer: 'PaidByBuyer' as ReverseFactorInvoiceStatusView,
    Refused: 'Refused' as ReverseFactorInvoiceStatusView,
    Rejected: 'Rejected' as ReverseFactorInvoiceStatusView,
    ToApprove: 'ToApprove' as ReverseFactorInvoiceStatusView,
    ToRecognize: 'ToRecognize' as ReverseFactorInvoiceStatusView,
    ToSign: 'ToSign' as ReverseFactorInvoiceStatusView,
    Uploaded: 'Uploaded' as ReverseFactorInvoiceStatusView
};


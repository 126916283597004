/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EarlySalaryRequestStatusView = 'Approved' | 'Credited' | 'Rejected' | 'Requested';

export const EarlySalaryRequestStatusView = {
    Approved: 'Approved' as EarlySalaryRequestStatusView,
    Credited: 'Credited' as EarlySalaryRequestStatusView,
    Rejected: 'Rejected' as EarlySalaryRequestStatusView,
    Requested: 'Requested' as EarlySalaryRequestStatusView
};


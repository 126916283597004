/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTarget0TemplateInt } from './kpiTarget0TemplateInt';
import { KpiGridTemplateBoolean } from './kpiGridTemplateBoolean';
import { KpiTargetTemplateInt } from './kpiTargetTemplateInt';
import { KpiGridTemplateDouble } from './kpiGridTemplateDouble';
import { KpiView } from './kpiView';
import { KpiGridTemplateInt } from './kpiGridTemplateInt';


/**
 * @type KpiGridTemplate
 * @export
 */
export type KpiGridTemplate = KpiGridTemplateBoolean | KpiGridTemplateDouble | KpiGridTemplateInt;


import { Injectable } from '@angular/core';
import {
    CapacitorCookies,
    CapacitorHttp,
    HttpParams,
    HttpResponse,
} from '@capacitor/core';
import {
    ChangeIbanRequest,
    ConfirmingDiscountRequestSelectionResponse,
    ConfirmingInvoicesSelectionsRequest,
    ConfirmingInvoicesSummaryResponse,
    ConfirmingTypeApi,
    DiscountRequestsCheckoutResponse,
    DiscountStatusApi,
    DocumentsResponse,
    InvoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse,
    InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse,
    InvoiceStatusApi,
    ReportFormat,
    SignResponse1,
    SignableDocumentsBadgeResponse,
    SignableDocumentsResponse,
    SupplierAutoDiscountsRequest,
    SupplierInvoicesSummaryQuery,
} from '@fd/clients/findynamic/client';
import { DeviceLogin200Response, OtpResponse } from '@fd/clients/thoth/client';
import { NavController, Platform } from '@ionic/angular';
import { Observable, catchError, exhaustMap, from, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IonicStorageService } from 'src/shared/storages/ionic-storage.service';
import { IonicStorageModelKeys } from 'src/shared/storages/models/ionic-storage-model';

import { IdentityUser } from '../../models/AuthenticationToken';
import { UpdatePhoneResponse } from '../../storages/models/settings-model';
import { AuthService } from '../auth-service/auth.service';
import { TimeService } from '../time-service/time.service';

@Injectable()
export class NativeApiService {
    private readonly BASE_URL_NATIVE = environment.apiBasePath;

    private readonly xsrftoken = 'XSRF-TOKEN';
    private readonly fdauth = '_fd_auth';

    constructor(
        private authService: AuthService,
        private navController: NavController,
        private platform: Platform,
        private storageService: IonicStorageService,
    ) {}

    doLogin(
        username: string,
        password: string,
        persistentSession: string,
    ): Observable<DeviceLogin200Response> {
        const body = `username=${encodeURIComponent(
            username,
        )}&password=${encodeURIComponent(password)}&persistentSession=${
            persistentSession ? 'true' : 'false'
        }`;
        return from(
            CapacitorHttp.post({
                data: body,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                url: `${this.BASE_URL_NATIVE}/thoth/api/v1/identity/login`,
                webFetchExtra: {
                    credentials: 'include',
                },
            }),
        ).pipe(
            tap((res) => {
                if (res.headers[this.setCookiesForPlatform(res)]) {
                    this.storageService.set(
                        IonicStorageModelKeys.XSRFTokenLocalStorageKey,
                        this.parseToken(res),
                    );
                    this.setCookies(this.parseToken(res));
                }
            }),
            map((res) => {
                if (res.status === 200) {
                    return res?.data ?? res;
                } else {
                    throw new Error(res.data);
                }
            }),
        );
    }

    doLogout() {
        return from(
            CapacitorHttp.get({
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `${this.BASE_URL_NATIVE}/thoth/api/v1/identity/logout`,
                webFetchExtra: {
                    credentials: 'include',
                },
            }),
        ).pipe(
            tap(() => {
                this.clearCookies();
                this.storageService.clear();
            }),
            map(() => true),
            catchError(() => of(false)),
        );
    }

    doNewOtp(oneTimeToken: string, username: string) {
        const body = {
            oneTimeToken: oneTimeToken,
            username: username,
        };
        return from(
            CapacitorHttp.post({
                data: body,
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `${this.BASE_URL_NATIVE}/thoth/api/v1/identity/new-otp`,
                webFetchExtra: {
                    credentials: 'include',
                },
            }),
        ).pipe(
            map((res) => {
                if (res.status === 200) {
                    return res?.data ?? res;
                } else {
                    throw new Error(res.data);
                }
            }),
        );
    }

    doOtpLogin(code: string, oneTimeToken: string, username: string) {
        const body = {
            code: code,
            oneTimeToken: oneTimeToken,
            username: username,
        };
        return from(
            CapacitorHttp.post({
                data: body,
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `${this.BASE_URL_NATIVE}/thoth/api/v1/identity/otp`,
                webFetchExtra: {
                    credentials: 'include',
                },
            }),
        ).pipe(
            tap((res) => {
                if (res.headers[this.setCookiesForPlatform(res)]) {
                    this.storageService.set(
                        IonicStorageModelKeys.XSRFTokenLocalStorageKey,
                        this.parseToken(res),
                    );
                    this.setCookies(this.parseToken(res));
                }
            }),
            map((res) => {
                if (res.status === 200) {
                    return res?.data ?? res;
                } else {
                    throw new Error(res.data);
                }
            }),
        );
    }

    doPhone(phone: string, oneTimeToken: string, email: string) {
        const body = {
            email: email,
            oneTimeToken: oneTimeToken,
            phone: phone,
        };
        return from(
            CapacitorHttp.post({
                data: body,
                headers: {
                    'Content-Type': 'application/json',
                },
                url: `${this.BASE_URL_NATIVE}/thoth/api/v1/identity/mfa/activation'`,
                webFetchExtra: {
                    credentials: 'include',
                },
            }),
        ).pipe(
            map((res) => {
                if (res.status === 200) {
                    return res?.data ?? res;
                } else {
                    throw new Error(res.data);
                }
            }),
        );
    }

    getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(
        supplierId: number,
        requestId: number,
    ): Observable<DocumentsResponse> {
        const params: HttpParams = {};

        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        params,
                        url: `${
                            this.BASE_URL_NATIVE
                        }/confirming/api/v1/suppliers/${encodeURIComponent(
                            String(supplierId),
                        )}/discount-requests/${encodeURIComponent(
                            String(requestId),
                        )}/documents`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(
        supplierId: number,
        requestId: number,
        documentId: number,
    ) {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/octet-stream',
                            'x-xsrf-token': xsrftoken,
                        },
                        responseType: 'blob',
                        url: `${
                            this.BASE_URL_NATIVE
                        }/confirming/api/v1/suppliers/${encodeURIComponent(
                            String(supplierId),
                        )}/discount-requests/${encodeURIComponent(
                            String(requestId),
                        )}/documents/${encodeURIComponent(String(documentId))}`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => {
                if (response.status === 401) {
                    this.authService.logout();
                    this.doLogout();
                    this.navController.navigateRoot('public');
                    throw new Error(`${response.data}`);
                } else {
                    return 'data:image/png;base64,' + response.data;
                }
            }),
        );
    }

    getConfirmingApiV1SuppliersSupplieridDiscounts(
        supplierId: number,
        max?: number,
        start?: number,
        startDate?: string,
        endDate?: string,
        buyerCompanyIds?: Array<number>,
        status?: DiscountStatusApi,
        searchQuery?: string,
        sort?: string,
        discountIds?: Array<number>,
        confirmingType?: ConfirmingTypeApi,
    ): Observable<InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse> {
        const params: HttpParams = {};
        params['supplierId'] = `${supplierId}`;

        if (!!endDate && endDate !== undefined) {
            params['endDate'] = endDate;
        }

        if (!!max && max !== undefined) {
            params['max'] = `${max}`;
        }

        if (!!start && start !== undefined) {
            params['start'] = `${start}`;
        }

        if (!!startDate && startDate !== undefined) {
            params['startDate'] = startDate;
        }

        if (
            !!buyerCompanyIds &&
            buyerCompanyIds !== undefined &&
            buyerCompanyIds.length > 0
        ) {
            const buyerCompanyArray: string[] = [];
            buyerCompanyIds.map((id) => {
                buyerCompanyArray.push(`${id}`);
            });
            params['buyerCompanyIds'] = buyerCompanyArray;
        }

        if (!!discountIds && discountIds !== undefined) {
            params['discountIds'] = `${discountIds}`;
        }
        if (!!searchQuery && searchQuery !== undefined) {
            params['searchQuery'] = `${searchQuery}`;
        }
        if (!!sort && sort !== undefined) {
            params['sort'] = sort;
        }
        if (!!status && status !== undefined) {
            params['status'] = status;
        }
        if (!!confirmingType && confirmingType !== undefined) {
            params['confirmingType'] = confirmingType;
        }

        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        params: params,
                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/discounts`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getConfirmingApiV1SuppliersSupplieridDiscountsReport(
        supplierId: number,
        format: ReportFormat,
        max?: number,
        start?: number,
        startDate?: string,
        endDate?: string,
        buyerCompanyIds?: Array<number>,
        status?: DiscountStatusApi,
        searchQuery?: string,
        sort?: string,
        discountIds?: Array<number>,
        confirmingType?: ConfirmingTypeApi,
    ) {
        const params: HttpParams = {};
        params['format'] = format;
        if (!!max && max !== undefined) {
            params['max'] = `${max}`;
        }
        if (!!start && start !== undefined) {
            params['start'] = `${start}`;
        }
        if (!!startDate && startDate !== undefined) {
            params['startDate'] = `${startDate}`;
        }
        if (!!endDate && endDate !== undefined) {
            params['endDate'] = `${endDate}`;
        }
        if (
            !!buyerCompanyIds &&
            buyerCompanyIds !== undefined &&
            buyerCompanyIds.length > 0
        ) {
            const buyerCompanyArray: string[] = [];
            buyerCompanyIds.map((id) => {
                buyerCompanyArray.push(`${id}`);
            });
            params['buyerCompanyIds'] = buyerCompanyArray;
        }
        if (!!status && status !== undefined) {
            params['status'] = status;
        }

        if (!!searchQuery && searchQuery !== undefined) {
            params['searchQuery'] = searchQuery;
        }
        if (!!sort && sort !== undefined) {
            params['sort'] = sort;
        }
        if (!!discountIds && discountIds !== undefined) {
            params['discountIds'] = `${discountIds}`;
        }
        if (!!confirmingType && confirmingType !== undefined) {
            params['confirmingType'] = confirmingType;
        }
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        params: params,
                        url: `${
                            this.BASE_URL_NATIVE
                        }/confirming/api/v1/suppliers/${encodeURIComponent(
                            String(supplierId),
                        )}/discounts-report`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getConfirmingApiV1SuppliersSupplieridInvoices(
        supplierId: number,
        currency: string,
        endDueDate: string,
        endIssueDate: string,
        max: number,
        start: number,
        startDueDate: string,
        startIssueDate: string,
        buyerCompanyIds?: Array<number>,
        factorId?: number,
        invoiceIds?: Array<number>,
        searchQuery?: string,
        sort?: string,
        status?: InvoiceStatusApi,
        confirmingType?: ConfirmingTypeApi,
    ): Observable<InvoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse> {
        const params: HttpParams = {};
        params['currency'] = currency;
        params['endDueDate'] = endDueDate;
        params['endIssueDate'] = endIssueDate;
        params['max'] = `${max}`;
        params['start'] = `${start}`;
        params['startDueDate'] = startDueDate;
        params['startIssueDate'] = startIssueDate;

        if (
            !!buyerCompanyIds &&
            buyerCompanyIds !== undefined &&
            buyerCompanyIds.length > 0
        ) {
            const buyerCompanyArray: string[] = [];
            buyerCompanyIds.map((id) => {
                buyerCompanyArray.push(`${id}`);
            });
            params['buyerCompanyIds'] = buyerCompanyArray;
        }
        if (!!factorId && factorId !== undefined) {
            params['factorId'] = `${factorId}`;
        }
        if (!!invoiceIds && invoiceIds !== undefined) {
            params['invoiceIds'] = `${invoiceIds}`;
        }
        if (!!searchQuery && searchQuery !== undefined) {
            params['searchQuery'] = searchQuery;
        }
        if (!!sort && sort !== undefined) {
            params['sort'] = sort;
        }
        if (!!status && status !== undefined) {
            params['status'] = status;
        }
        if (!!confirmingType && confirmingType !== undefined) {
            params['confirmingType'] = confirmingType;
        }

        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        params: params,
                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/invoices`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(
        supplierId: number,
        currency: string,
        endDueDate: string,
        endIssueDate: string,
        startDueDate: string,
        startIssueDate: string,
        status: InvoiceStatusApi,
        buyerCompanyIds?: Array<number>,
        factorId?: number,
        invoiceIds?: Array<number>,
        confirmingType?: ConfirmingTypeApi,
    ): Observable<Array<number>> {
        const params: HttpParams = {};
        params['supplierId'] = `${supplierId}`;
        params['currency'] = currency;
        params['endDueDate'] = endDueDate;
        params['endIssueDate'] = endIssueDate;
        params['startDueDate'] = startDueDate;
        params['startIssueDate'] = startIssueDate;
        params['status'] = status;

        if (
            !!buyerCompanyIds &&
            buyerCompanyIds !== undefined &&
            buyerCompanyIds.length > 0
        ) {
            const buyerCompanyArray: string[] = [];
            buyerCompanyIds.map((id) => {
                buyerCompanyArray.push(`${id}`);
            });
            params['buyerCompanyIds'] = buyerCompanyArray;
        }

        if (!!factorId && factorId !== undefined) {
            params['factorId'] = `${factorId}`;
        }
        if (!!invoiceIds && invoiceIds !== undefined) {
            params['invoiceIds'] = `${invoiceIds}`;
        }

        if (!!confirmingType && confirmingType !== undefined) {
            params['confirmingType'] = confirmingType;
        }
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        params: params,
                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/invoices/invoice-ids`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getConfirmingApiV1SuppliersSupplieridInvoicesReport(
        supplierId: number,
        currency: string,
        endDueDate: string,
        endIssueDate: string,
        max: number,
        start: number,
        startDueDate: string,
        startIssueDate: string,
        format: ReportFormat,
        buyerCompanyIds?: Array<number>,
        factorId?: number,
        invoiceIds?: Array<number>,
        searchQuery?: string,
        sort?: string,
        status?: InvoiceStatusApi,
        confirmingType?: ConfirmingTypeApi,
    ) {
        const params: HttpParams = {};
        params['currency'] = currency;
        params['endDueDate'] = endDueDate;
        params['endIssueDate'] = endIssueDate;
        params['max'] = `${max}`;
        params['start'] = `${start}`;
        params['startDueDate'] = startDueDate;
        params['startIssueDate'] = startIssueDate;
        params['format'] = format;
        if (
            !!buyerCompanyIds &&
            buyerCompanyIds !== undefined &&
            buyerCompanyIds.length > 0
        ) {
            const buyerCompanyArray: string[] = [];
            buyerCompanyIds.map((id) => {
                buyerCompanyArray.push(`${id}`);
            });
            params['buyerCompanyIds'] = buyerCompanyArray;
        }
        if (!!factorId && factorId !== undefined) {
            params['factorId'] = `${factorId}`;
        }
        if (!!invoiceIds && invoiceIds !== undefined) {
            params['invoiceIds'] = `${invoiceIds}`;
        }
        if (!!searchQuery && searchQuery !== undefined) {
            params['searchQuery'] = searchQuery;
        }
        if (!!sort && sort !== undefined) {
            params['sort'] = sort;
        }
        if (!!status && status !== undefined) {
            params['status'] = status;
        }
        if (!!confirmingType && confirmingType !== undefined) {
            params['confirmingType'] = confirmingType;
        }
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        params: params,
                        url: `${
                            this.BASE_URL_NATIVE
                        }/confirming/api/v1/suppliers/${encodeURIComponent(
                            String(supplierId),
                        )}/invoices-report`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
        supplierId: number,
    ) {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/settings/autodiscounts`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getInfo(user: IdentityUser, companyId: number, newPhone: string) {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        url: `${this.BASE_URL_NATIVE}/portal/api/option/${companyId}/user/${user.id}`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                ).pipe(
                    tap((res) => {
                        if (res.headers[this.setCookiesForPlatform(res)]) {
                            this.storageService.set(
                                IonicStorageModelKeys.XSRFTokenLocalStorageKey,
                                this.parseToken(res),
                            );
                            this.setCookies(this.parseToken(res));
                        }
                    }),
                    map((response) => {
                        const data = this.onError(response, true);
                        return {
                            user: user,
                            userSettings: data,
                            companyId: companyId,
                            newPhone: newPhone,
                        };
                    }),
                );
            }),
        );
    }

    getOnboardingApiV1CustomersCustomeridIbanActivations(customerId: number) {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        url: `${this.BASE_URL_NATIVE}/onboarding/api/v1/customers/${customerId}/iban-activations`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getReportApiDownloadSupplierSupplieridGenericUuid(
        supplierId: number,
        uuid: string,
    ) {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/octet-stream',
                            'x-xsrf-token': xsrftoken,
                        },
                        responseType: 'blob',
                        url: `${
                            this.BASE_URL_NATIVE
                        }/report/api/download/supplier/${encodeURIComponent(
                            String(supplierId),
                        )}/generic/${encodeURIComponent(String(uuid))}`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => {
                if (response.status === 401) {
                    this.authService.logout();
                    this.doLogout();
                    this.navController.navigateRoot('public');
                    throw new Error(`${response.data}`);
                } else {
                    return 'data:image/png;base64,' + response.data;
                }
            }),
        );
    }

    getSignatureApiV1AccountsAccountidSignableDocuments(
        accountId: number,
    ): Observable<SignableDocumentsResponse> {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },

                        url: `${
                            this.BASE_URL_NATIVE
                        }/signature/api/v1/accounts/${encodeURIComponent(
                            String(accountId),
                        )}/signable-documents`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    getSignatureApiV1AccountsAccountidSignableDocumentsCount(
        accountId: number,
    ): Observable<SignableDocumentsBadgeResponse> {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.get({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },

                        url: `${
                            this.BASE_URL_NATIVE
                        }/signature/api/v1/accounts/${encodeURIComponent(
                            String(accountId),
                        )}/signable-documents-count`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(
        supplierId: number,
        supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest,
    ): Observable<DiscountRequestsCheckoutResponse> {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.post({
                        data: supplierAutoDiscountsRequest,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-fd-one-time-token':
                                this.storageService.getOneTimeTokenLocalStorageKey()!,
                            'x-fd-otp':
                                this.storageService.getOtpLocalStorageKey()!,
                            'x-xsrf-token': xsrftoken,
                        },
                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/invoices/discount-requests-checkout-otp`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, false)),
        );
    }

    postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(
        supplierId: number,
        confirmingInvoicesSelectionsRequest: ConfirmingInvoicesSelectionsRequest,
    ): Observable<ConfirmingDiscountRequestSelectionResponse> {
        const body: ConfirmingInvoicesSelectionsRequest =
            confirmingInvoicesSelectionsRequest;

        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.post({
                        data: body,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },

                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/invoices/discount-requests-selection`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    postConfirmingApiV1SuppliersSupplieridInvoicesSummary(
        supplierId: number,
        exclude: boolean,
        endDate?: string,
        invoiceIds?: Array<number>,
        startDate?: string,
        statuses?: Array<InvoiceStatusApi>,
    ): Observable<ConfirmingInvoicesSummaryResponse> {
        const body: SupplierInvoicesSummaryQuery = {
            exclude: exclude,
        };
        if (!!endDate && endDate !== undefined) {
            body['endDueDate'] = endDate;
        }
        if (!!invoiceIds && invoiceIds !== undefined) {
            body['invoiceIds'] = invoiceIds;
        }
        if (!!startDate && startDate !== undefined) {
            body['startDueDate'] = startDate;
        }
        if (!!statuses && statuses !== undefined) {
            body['statuses'] = statuses;
        }

        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.post({
                        data: body,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },

                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/invoices/summary`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(
        supplierId: number,
        supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest,
    ) {
        const body: SupplierAutoDiscountsRequest = supplierAutoDiscountsRequest;
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.post({
                        data: body,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-fd-one-time-token':
                                this.storageService.getOneTimeTokenLocalStorageKey()!,
                            'x-fd-otp':
                                this.storageService.getOtpLocalStorageKey()!,
                            'x-xsrf-token': xsrftoken,
                        },
                        url: `${this.BASE_URL_NATIVE}/confirming/api/v1/suppliers/${supplierId}/settings/autodiscounts`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    postOnboardingApiV1CustomersCustomeridFactorsFactoridIbanActivations(
        customerId: number,
        factorId: number,
        changeIbanRequest: ChangeIbanRequest,
    ) {
        const body: ChangeIbanRequest = changeIbanRequest;
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.post({
                        data: body,
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        url: `${this.BASE_URL_NATIVE}/onboarding/api/v1/customers/${customerId}/factors/${factorId}/iban-activations`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    postSignatureApiV1AccountsAccountidSignableDocumentsSignatureid(
        accountId: number,
        signatureId: number,
    ): Observable<SignResponse1> {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.post({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },

                        url: `${
                            this.BASE_URL_NATIVE
                        }/signature/api/v1/accounts/${encodeURIComponent(
                            String(accountId),
                        )}/signable-documents/${encodeURIComponent(
                            String(signatureId),
                        )}`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, true)),
        );
    }

    postThothApiV1IdentityAuthorizeOtp(): Observable<OtpResponse> {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.post({
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },

                        url: `${this.BASE_URL_NATIVE}/thoth/api/v1/identity/authorize/otp`,
                        webFetchExtra: {
                            credentials: 'include',
                        },
                    }),
                );
            }),
            map((response) => this.onError(response, false)),
        );
    }

    updateInfo(
        data: UpdatePhoneResponse,
    ): Observable<HttpResponse | IdentityUser> {
        return this.storageService.getXSRFTokenLocalStorageKey().pipe(
            exhaustMap((xsrftoken) => {
                return from(
                    CapacitorHttp.put({
                        data: {
                            ...data.userSettings,
                            phone: data.newPhone,
                            updateTime: TimeService.timestamp(),
                        },
                        headers: {
                            'Content-Type': 'application/json',
                            'x-xsrf-token': xsrftoken,
                        },
                        url: `${this.BASE_URL_NATIVE}/portal/api/option/${data.companyId}/user/${data.user.id}/self`,
                    }),
                ).pipe(
                    map((res) => {
                        if (res.status === 200) {
                            return res?.data
                                ? { ...data.user, phone: res.data.phone }
                                : res;
                        } else {
                            throw new Error(res.data);
                        }
                    }),
                );
            }),
        );
    }

    private encodedPlatform(token: string) {
        if (this.platform.is('android')) {
            return encodeURIComponent(token);
        } else {
            return token;
        }
    }

    private onError<T>(
        response: { data: T; status: number },
        logout: boolean,
    ): T | void {
        if (response.status === 401 && logout) {
            this.authService.logout();
            this.doLogout();
            this.navController.navigateRoot('public');
        } else if (response.status === 401 && !logout) {
            throw new Error(`${response.data}`);
        } else if (response.status === 400) {
            throw new Error(`${response.data}`);
        } else {
            return response.data;
        }
    }

    private parseToken(res: HttpResponse) {
        const cookies = res.headers[this.setCookiesForPlatform(res)].split(',');
        for (const i of cookies) {
            const c = i.split(';')[0].split('=');

            if (c[0].trim() == this.xsrftoken) {
                return this.encodedPlatform(c[1].trim());
            }
        }
        return '';
    }

    private setCookies(token: string) {
        CapacitorCookies.setCookie({
            key: this.xsrftoken,
            value: token,
        });
    }

    private clearCookies() {
        CapacitorCookies.deleteCookie({ key: this.xsrftoken });
        CapacitorCookies.deleteCookie({ key: this.fdauth });
    }

    private setCookiesForPlatform(res: HttpResponse) {
        if (res.headers['set-cookie']) {
            return 'set-cookie';
        } else {
            return 'Set-Cookie';
        }
    }
}

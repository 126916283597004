import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import {
    BASE_PATH as THOTH_MOBILE_BASE_PATH,
    DefaultService as ThothMobileClientService,
    Configuration as ThothMobileConfiguration,
} from '@fd/clients/thoth-mobile/client';
import {
    BASE_PATH as THOTH_BASE_PATH,
    DefaultService as ThothClientService,
    Configuration as ThothConfiguration,
} from '@fd/clients/thoth/client';
import { IonicStorageModule } from '@ionic/storage-angular';
import {
    ConfirmingService,
    BASE_PATH as FINDYNAMIC_BASE_PATH,
    Configuration as FindynamicConfiguration,
    OnboardingService,
    SignatureService,
} from 'packages/clients/findynamic/client';
import { CurrencyService } from 'src/shared/services/currency/currency.service';

import { environment } from './environments/environment';
import { HttpXsrfInterceptor } from './shared/interceptors/HttpXsrfInterceptor';
import { NoCacheInterceptor } from './shared/interceptors/noCacheInterceptor';
import { ConfirmingApiService } from './shared/services/confirming-api-service/confirmingApi.service';
import { DeepLinkService } from './shared/services/deep-link-service/deep-link.service';
import { NativeApiService } from './shared/services/native-api-service/nativeApi.service';
import { OnboardingApiService } from './shared/services/onboarding-api-service/onboardingApi.service';
import { PhonePrefixService } from './shared/services/phone-prefix/phone-prefix.service';
import { DocumentsToSignNotificationService } from './shared/services/polling-service/document-to-sign-service/documents-to-sign-notification.service';
import { RemoteConfigurationService } from './shared/services/remote-configuration-service/remote-configuration.service';
import { SignatureApiService } from './shared/services/signature-api-service/signatureApi.service';
import { SupplierStatusService } from './shared/services/supplier-status-service/supplierStatus.service';
import { ThothApiService } from './shared/services/thoth-api-service/thothApi.service';
import { TimeService } from './shared/services/time-service/time.service';
import { IonicStorageService } from './shared/storages/ionic-storage.service';

export const CoreProviders = [
    importProvidersFrom(HttpClientModule, IonicStorageModule.forRoot()),
    IonicStorageService,
    {
        deps: [IonicStorageService],
        multi: true,
        provide: APP_INITIALIZER,
        useFactory: (storage: IonicStorageService) => () => storage.init(),
    },
    {
        multi: true,
        provide: HTTP_INTERCEPTORS,
        useClass: NoCacheInterceptor,
    },
    {
        multi: true,
        provide: HTTP_INTERCEPTORS,
        useClass: HttpXsrfInterceptor,
    },
    {
        deps: [IonicStorageService],
        multi: false,
        provide: FindynamicConfiguration,
        useFactory: (storage: IonicStorageService) => {
            return new FindynamicConfiguration({
                credentials: {
                    apiKeyAuth: () => {
                        const oneTimeToken =
                            storage.getOneTimeTokenLocalStorageKey();

                        if (oneTimeToken) {
                            return oneTimeToken;
                        } else {
                            return undefined;
                        }
                    },
                    apiKeyAuth1: () => '',
                    apiKeyAuth2: () => '',
                    apiKeyAuth3: () => {
                        const otpLocalStorageKey =
                            storage.getOtpLocalStorageKey();
                        if (otpLocalStorageKey) {
                            return otpLocalStorageKey;
                        } else {
                            return undefined;
                        }
                    },
                    apiKeyAuth4: () => '',
                },
                withCredentials: true,
            });
        },
    },
    {
        multi: false,
        provide: ThothConfiguration,
        useFactory: () => {
            return new ThothConfiguration({
                credentials: {
                    apiKeyAuth: () => '',
                    apiKeyAuth1: () => '',
                    apiKeyAuth2: () => '',
                    apiKeyAuth3: () => '',
                    apiKeyAuth4: () => '',
                },
                withCredentials: true,
            });
        },
    },
    {
        multi: false,
        provide: ThothMobileConfiguration,
        useFactory: () => {
            return new ThothMobileConfiguration({
                credentials: {
                    apiKeyAuth: () => '',
                    apiKeyAuth1: () => '',
                    apiKeyAuth2: () => '',
                },
                withCredentials: true,
            });
        },
    },
    {
        provide: FINDYNAMIC_BASE_PATH,
        useValue: environment.apiBasePath,
    },
    {
        provide: THOTH_BASE_PATH,
        useValue: `${environment.apiBasePath}/thoth`,
    },
    {
        provide: THOTH_MOBILE_BASE_PATH,
        useValue: environment.apiBasePath,
    },

    ConfirmingApiService,
    ConfirmingService,
    CurrencyService,
    DeepLinkService,
    DocumentsToSignNotificationService,
    NativeApiService,
    OnboardingApiService,
    OnboardingService,
    PhonePrefixService,
    RemoteConfigurationService,
    SignatureApiService,
    SignatureService,
    SupplierStatusService,
    ThothApiService,
    ThothClientService,
    ThothMobileClientService,
    TimeService,
];

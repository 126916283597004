/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RatingMoreClassView = 'AAA' | 'AA' | 'A' | 'BBB' | 'BB' | 'B' | 'CCC' | 'CC' | 'C' | 'D';

export const RatingMoreClassView = {
    Aaa: 'AAA' as RatingMoreClassView,
    Aa: 'AA' as RatingMoreClassView,
    A: 'A' as RatingMoreClassView,
    Bbb: 'BBB' as RatingMoreClassView,
    Bb: 'BB' as RatingMoreClassView,
    B: 'B' as RatingMoreClassView,
    Ccc: 'CCC' as RatingMoreClassView,
    Cc: 'CC' as RatingMoreClassView,
    C: 'C' as RatingMoreClassView,
    D: 'D' as RatingMoreClassView
};


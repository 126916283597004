/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DeferralStatusApi = 'Allowed' | 'Approved' | 'NotAvailable' | 'Requested';

export const DeferralStatusApi = {
    Allowed: 'Allowed' as DeferralStatusApi,
    Approved: 'Approved' as DeferralStatusApi,
    NotAvailable: 'NotAvailable' as DeferralStatusApi,
    Requested: 'Requested' as DeferralStatusApi
};


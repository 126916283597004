/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EsgSimulationsResponse } from './esgSimulationsResponse';
import { EsgScoreDataApi } from './esgScoreDataApi';
import { EsgMultiProviderScoreDataApi } from './esgMultiProviderScoreDataApi';
import { EsgDocumentApi } from './esgDocumentApi';


export interface EsgScoreApi { 
    scoreId: number;
    expired: boolean;
    documents?: Array<EsgDocumentApi>;
    issueDate: string;
    expirationDate: string;
    atecoCode?: string;
    sharedAt?: string;
    data?: EsgScoreDataApi;
    simulations?: EsgSimulationsResponse;
    multiProviderData: EsgMultiProviderScoreDataApi;
    sharedWithRequestersIds?: Array<number>;
    lastSharedWithRequestersAt?: string;
}


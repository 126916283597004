/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EsgPerformanceApi = 'VeryLow' | 'Low' | 'Medium' | 'High' | 'VeryHigh';

export const EsgPerformanceApi = {
    VeryLow: 'VeryLow' as EsgPerformanceApi,
    Low: 'Low' as EsgPerformanceApi,
    Medium: 'Medium' as EsgPerformanceApi,
    High: 'High' as EsgPerformanceApi,
    VeryHigh: 'VeryHigh' as EsgPerformanceApi
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeferralStatusApi } from './deferralStatusApi';
import { InvoiceStatusApi } from './invoiceStatusApi';
import { InvoiceDetailResponse } from './invoiceDetailResponse';
import { VatView } from './vatView';


export interface FactorInvoiceResponse { 
    id: number;
    /**
     * use documentNumber
     */
    invoiceNumber: string;
    buyerName: string;
    buyerVat: VatView;
    documentNumber: string;
    currency: string;
    notesAmount: number;
    prizeAmount: number;
    /**
     * use supplierCompanyId
     */
    supplierId: number;
    supplierCompanyId: number;
    supplierName: string;
    supplierCompanyName: string;
    supplierVat: VatView;
    date: string;
    dueDate: string;
    deferralApprovalDate?: string;
    deferralDueDate?: string;
    deferralStatus: DeferralStatusApi;
    soldToFactorDate?: string;
    amount: number;
    newAmount: number;
    status: InvoiceStatusApi;
    enabled: boolean;
    cigCode?: string;
    cupCode?: string;
    iban?: string;
    invoiceDetails?: Array<InvoiceDetailResponse>;
    factorPaymentMethod?: string;
}
export namespace FactorInvoiceResponse {
}



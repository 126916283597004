import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonComponent } from 'src/components/button/button.component';

export type DeviceOs = 'ios' | 'android';

@Component({
    selector: 'fd2-outdated-version',
    templateUrl: 'outdated-version.component.html',
    styleUrls: ['outdated-version.component.scss'],
    standalone: true,
    imports: [ButtonComponent, CommonModule, IonicModule, TranslocoModule],
})
export class OutdatedVersionComponent {
    @Input() deviceOs: DeviceOs;

    onClick() {
        if (this.deviceOs === 'ios') {
            window.open(
                'https://apps.apple.com/us/app/laboral-kutxa-confirming/id6467654680',
            );
        } else {
            window.open(
                'https://play.google.com/store/apps/details?id=com.findynamic.mobile.laboralkutxa',
            );
        }
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ConfirmRequestLineResponse { 
    id: number;
    documentNumber: string;
    supplierId: number;
    supplierName: string;
    netAmount: number;
    currency: string;
    date: string;
    dueDate: string;
    notesAmount: number;
    prizeAmount: number;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FactorServiceProcessView = 'ActiveFinancingDiscount' | 'ConfirmingConfirm' | 'ReverseDiscount' | 'ReverseRecognize';

export const FactorServiceProcessView = {
    ActiveFinancingDiscount: 'ActiveFinancingDiscount' as FactorServiceProcessView,
    ConfirmingConfirm: 'ConfirmingConfirm' as FactorServiceProcessView,
    ReverseDiscount: 'ReverseDiscount' as FactorServiceProcessView,
    ReverseRecognize: 'ReverseRecognize' as FactorServiceProcessView
};


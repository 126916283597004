/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields12 = 'company' | 'factorSupplierCode';

export const SortableFields12 = {
    Company: 'company' as SortableFields12,
    FactorSupplierCode: 'factorSupplierCode' as SortableFields12
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditCardCycleRequest } from './creditCardCycleRequest';
import { CCPlafondModelRequest } from './cCPlafondModelRequest';


export interface CreditCardSettingsRequest { 
    shopCode: string;
    discountRatePercentage: number;
    keyResultApi: string;
    cycles: Array<CreditCardCycleRequest>;
    plafondModel: CCPlafondModelRequest;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Factor1 } from './factor1';


export interface FactorEnabledResponse1 { 
    factor: Factor1;
    enabled: boolean;
    factorBuyerCodeView?: string;
    activationDate?: string;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetStatus } from './budgetStatus';


export interface BudgetCheck { 
    allocated: number;
    currency: string;
    invoicesAmount: number;
    limit: number;
    remaining: number;
    selectedInvoices: number;
    status: BudgetStatus;
}
export namespace BudgetCheck {
}



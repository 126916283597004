import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonComponent } from 'src/components/button/button.component';

@Component({
    selector: 'fd2-offline',
    templateUrl: './offline.component.html',
    styleUrls: ['./offline.component.scss'],
    standalone: true,
    imports: [ButtonComponent, CommonModule, IonicModule, TranslocoModule],
})
export class OfflineComponent {}

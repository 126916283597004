/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFieldsSort } from './paginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFieldsSort';
import { BuyerListItemResponse } from './buyerListItemResponse';
import { BuyerListFiltersResponse } from './buyerListFiltersResponse';
import { PaginationResponse } from './paginationResponse';


export interface PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFields { 
    filters: BuyerListFiltersResponse;
    pagination: PaginationResponse;
    sort?: PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFieldsSort;
    page?: Array<BuyerListItemResponse>;
}


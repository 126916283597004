/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeferralStatusApi } from './deferralStatusApi';
import { FilteredReasonView } from './filteredReasonView';
import { CreditNoteResponse } from './creditNoteResponse';
import { InvoiceStatusApi } from './invoiceStatusApi';
import { VatView } from './vatView';
import { BuyerInvoiceIntegrationDocument } from './buyerInvoiceIntegrationDocument';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface BuyerInvoiceResponse { 
    id: number;
    documentNumber: string;
    buyerId: number;
    buyerVatCountry: string;
    buyerVatCode: string;
    supplierId: number;
    supplierCompanyId: number;
    supplierName: string;
    supplierCompanyName: string;
    factorId: number;
    factorName: string;
    factorVat: VatView;
    division: string;
    divisionName: string;
    date: string;
    dueDate: string;
    deferralDueDate?: string;
    confirmDate?: string;
    confirmDocumentId?: number;
    amount: number;
    confirmedAmount: number;
    status: InvoiceStatusApi;
    filteredReason?: FilteredReasonView;
    currency: string;
    deferralAnnualRate?: number;
    deferredDays?: number;
    notesAmount?: number;
    prizeAmount?: number;
    deferralStatus: DeferralStatusApi;
    notes?: Array<CreditNoteResponse>;
    cigCode?: string;
    cupCode?: string;
    integrationType: FactorIntegrationTypeView;
    latestIntegrationDocument?: BuyerInvoiceIntegrationDocument;
}
export namespace BuyerInvoiceResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DpoView } from './dpoView';


export interface CountFilterOptionDpoView { 
    label: string;
    value: DpoView;
    selected: boolean;
    count: number;
}
export namespace CountFilterOptionDpoView {
}



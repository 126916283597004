/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorServiceProcessView } from './factorServiceProcessView';
import { VatView } from './vatView';
import { FactorDocumentCreationStatusView } from './factorDocumentCreationStatusView';
import { FactorDocumentUserView } from './factorDocumentUserView';


export interface FactorDocumentResponse { 
    documentId: number;
    documentName?: string;
    factorName: string;
    factorId: number;
    factorVat: VatView;
    requestProcessType?: FactorServiceProcessView;
    createdAt?: string;
    firstDownloadedAt?: string;
    requester: FactorDocumentUserView;
    firstDownloader?: FactorDocumentUserView;
    status: FactorDocumentCreationStatusView;
    actionableInvoicesCount: number;
}
export namespace FactorDocumentResponse {
}



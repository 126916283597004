/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Quiz } from './quiz';
import { Carousel } from './carousel';
import { Video } from './video';
import { Question } from './question';
import { Text } from './text';
import { Audio } from './audio';
import { Image } from './image';


/**
 * @type LearningLessonContentResponse
 * @export
 */
export type LearningLessonContentResponse = Audio | Carousel | Quiz | Text | Video;


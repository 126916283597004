/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserStatus } from './userStatus';


export interface SupplierUser { 
    id: number;
    name: string;
    surname: string;
    email: string;
    phone?: string;
    status: UserStatus;
    groups?: Array<string>;
}
export namespace SupplierUser {
}



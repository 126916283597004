/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields1 = 'amount' | 'buyerName' | 'discountAmount' | 'discountRequestDate' | 'documentNumber' | 'dueDate' | 'issueDate' | 'newAmount' | 'newDueDate' | 'status' | 'supplierName';

export const SortableFields1 = {
    Amount: 'amount' as SortableFields1,
    BuyerName: 'buyerName' as SortableFields1,
    DiscountAmount: 'discountAmount' as SortableFields1,
    DiscountRequestDate: 'discountRequestDate' as SortableFields1,
    DocumentNumber: 'documentNumber' as SortableFields1,
    DueDate: 'dueDate' as SortableFields1,
    IssueDate: 'issueDate' as SortableFields1,
    NewAmount: 'newAmount' as SortableFields1,
    NewDueDate: 'newDueDate' as SortableFields1,
    Status: 'status' as SortableFields1,
    SupplierName: 'supplierName' as SortableFields1
};


import {
  Directive,
  Inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";

@Directive({
  selector: "[fd2Let]",
})
export class LetDirective<T> {
  @Input() fd2Let: T;
  constructor(
    @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
    @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>,
  ) {
    viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
  }
}

export class LetContext<T> {
  constructor(private readonly dir: LetDirective<T>) {}

  get fd2Let(): T {
    return this.dir.fd2Let;
  }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FilteredReasonView = 'Blocked' | 'CigCup' | 'MaximumAmount' | 'MaximumCreditDays' | 'MaximumDaysToExpiration' | 'MinimumAmount' | 'MinimumDaysToExpiration' | 'MissingSettings';

export const FilteredReasonView = {
    Blocked: 'Blocked' as FilteredReasonView,
    CigCup: 'CigCup' as FilteredReasonView,
    MaximumAmount: 'MaximumAmount' as FilteredReasonView,
    MaximumCreditDays: 'MaximumCreditDays' as FilteredReasonView,
    MaximumDaysToExpiration: 'MaximumDaysToExpiration' as FilteredReasonView,
    MinimumAmount: 'MinimumAmount' as FilteredReasonView,
    MinimumDaysToExpiration: 'MinimumDaysToExpiration' as FilteredReasonView,
    MissingSettings: 'MissingSettings' as FilteredReasonView
};


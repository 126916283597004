/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DivisionReverseInfoView } from './divisionReverseInfoView';
import { DivisionConfirmingInfoView } from './divisionConfirmingInfoView';
import { DivisionDynamicDiscountingInfoView } from './divisionDynamicDiscountingInfoView';


export interface DivisionInfoView { 
    id: number;
    name: string;
    code: string;
    supplierCode: string;
    supplierVat: string;
    confirmingInfo: DivisionConfirmingInfoView;
    dynamicDiscountingInfo: DivisionDynamicDiscountingInfoView;
    reverseInfo: DivisionReverseInfoView;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTargetWriteBoolean } from './kpiTargetWriteBoolean';
import { KpiTarget0WriteBoolean } from './kpiTarget0WriteBoolean';
import { KpiView } from './kpiView';


export interface KpiGridBooleanWrite { 
    kpi: KpiView;
    target0: KpiTarget0WriteBoolean;
    targets?: Array<KpiTargetWriteBoolean>;
    kind: string;
}
export namespace KpiGridBooleanWrite {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFieldsSort { 
    field: PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFieldsSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFieldsSort {
    export type FieldEnum = 'company' | 'factorBuyerCode';
    export const FieldEnum = {
        Company: 'company' as FieldEnum,
        FactorBuyerCode: 'factorBuyerCode' as FieldEnum
    };
}



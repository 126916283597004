/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataResponseRatingMoreClassView } from './dataResponseRatingMoreClassView';
import { ServicesInfoResponse } from './servicesInfoResponse';
import { DataResponseCompanySizeView } from './dataResponseCompanySizeView';
import { DataResponseDpoView } from './dataResponseDpoView';
import { DataResponseString } from './dataResponseString';


export interface OverviewDataResponse { 
    suppliersInfo: ServicesInfoResponse;
    suppliersSizes?: Array<DataResponseCompanySizeView>;
    suppliersRatings?: Array<DataResponseRatingMoreClassView>;
    suppliersSectors?: Array<DataResponseString>;
    suppliersDpo?: Array<DataResponseDpoView>;
}


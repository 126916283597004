/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerSupplierResponseFilters } from './buyerSupplierResponseFilters';
import { PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFieldsSort } from './paginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFieldsSort';
import { PaginationResponse } from './paginationResponse';
import { BuyerSupplierListItemResponse } from './buyerSupplierListItemResponse';


export interface PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFields { 
    filters: BuyerSupplierResponseFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFieldsSort;
    page?: Array<BuyerSupplierListItemResponse>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NamedFilter } from './namedFilter';
import { ConfirmingBuyerInvoiceDocumentFilter } from './confirmingBuyerInvoiceDocumentFilter';
import { CountFilter } from './countFilter';
import { FactorFilter } from './factorFilter';


export interface BuyerInvoiceFiltersResponse { 
    totalAmount: number;
    insertedAmount: number;
    confirmingAmount: number;
    confirmedAmount: number;
    filteredAmount: number;
    documentToSignAmount: number;
    waitingForDocumentAmount: number;
    startDate?: string;
    endDate?: string;
    buyerDivisionFilter?: Array<NamedFilter>;
    currencyFilter?: Array<NamedFilter>;
    deferralFilter?: Array<NamedFilter>;
    documentFilter?: ConfirmingBuyerInvoiceDocumentFilter;
    factorFilter?: Array<FactorFilter>;
    statusFilter?: Array<CountFilter>;
    supplierFilter?: Array<NamedFilter>;
    supplierCompanyFilter?: Array<NamedFilter>;
    enableUpload: boolean;
    notIntegratedProcessingCount: number;
}


import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';

export const fd2ButtonWidths = ['auto', 'full'] as const;
export type Width = (typeof fd2ButtonWidths)[number];

export type ButtonType = 'button' | 'submit';
export type ButtonStyle = 'primary' | 'secondary' | 'transparent' | 'link';
export type ButtonAlignment = 'right' | 'left' | 'center';
export type IconSlot = 'start' | 'end';

@Component({
    selector: 'fd2-button',
    templateUrl: 'button.component.html',
    styleUrls: ['button.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule],
})
export class ButtonComponent {
    @Input()
    disabled = false;

    @Input()
    label: string;

    @Input()
    icon: string;

    @Input()
    iconSlot: IconSlot = 'start';

    @Output()
    onClick = new EventEmitter<void>();

    @Input()
    buttonType: ButtonType = 'button';

    @Input()
    buttonStyle: ButtonStyle = 'primary';

    @Input()
    alignment: ButtonAlignment = 'center';

    onClickHandler() {
        this.onClick.emit();
    }

    onSetStyle(buttonStyle: ButtonStyle) {
        switch (buttonStyle) {
            case 'secondary':
                return 'outline';
            case 'transparent':
                return 'clear';
            case 'link':
                return 'clear';
            default:
                return 'solid';
        }
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NamedFilter } from './namedFilter';
import { CountFilter } from './countFilter';


export interface FactorActiveDiscountRequestFiltersResponse { 
    totalAmount: number;
    startDate: string;
    endDate: string;
    statusFilters?: Array<CountFilter>;
    supplierDivisionFilters?: Array<NamedFilter>;
    currencyFilters?: Array<NamedFilter>;
}


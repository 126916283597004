/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTarget0TemplateDouble } from './kpiTarget0TemplateDouble';
import { KpiTargetTemplateDouble } from './kpiTargetTemplateDouble';
import { KpiView } from './kpiView';


export interface KpiGridTemplateDouble { 
    kpi: KpiView;
    target0: KpiTarget0TemplateDouble;
    targets?: Array<KpiTargetTemplateDouble>;
    kind: string;
}
export namespace KpiGridTemplateDouble {
}



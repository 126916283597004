/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseFactorSupplierListItemResponse } from './reverseFactorSupplierListItemResponse';
import { PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFieldsSort } from './paginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFieldsSort';
import { PaginationResponse } from './paginationResponse';
import { SupplierFilters2 } from './supplierFilters2';


export interface PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFields { 
    filters: SupplierFilters2;
    pagination: PaginationResponse;
    sort?: PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFieldsSort;
    page?: Array<ReverseFactorSupplierListItemResponse>;
}


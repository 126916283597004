/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OverviewServiceInfoTypeView = 'Confirming' | 'DynamicDiscounting' | 'NotActive';

export const OverviewServiceInfoTypeView = {
    Confirming: 'Confirming' as OverviewServiceInfoTypeView,
    DynamicDiscounting: 'DynamicDiscounting' as OverviewServiceInfoTypeView,
    NotActive: 'NotActive' as OverviewServiceInfoTypeView
};


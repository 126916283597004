/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Service = 'Confirming' | 'DynamicDiscounting' | 'ActiveFinancing' | 'EsgScore' | 'NoIntegration' | 'Onboarding' | 'PSD2' | 'Reverse' | 'ExtraFiliera' | 'DataHub';

export const Service = {
    Confirming: 'Confirming' as Service,
    DynamicDiscounting: 'DynamicDiscounting' as Service,
    ActiveFinancing: 'ActiveFinancing' as Service,
    EsgScore: 'EsgScore' as Service,
    NoIntegration: 'NoIntegration' as Service,
    Onboarding: 'Onboarding' as Service,
    Psd2: 'PSD2' as Service,
    Reverse: 'Reverse' as Service,
    ExtraFiliera: 'ExtraFiliera' as Service,
    DataHub: 'DataHub' as Service
};


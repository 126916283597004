import { InjectionToken } from '@angular/core';

export const BASE_PATH = new InjectionToken<string>('basePath');
export const SCROLL_VALUE_TOP = new InjectionToken<string>('scrollValueTop');
export const SCROLL_VALUE_BOTTOM = new InjectionToken<string>(
    'scrollValueBottom',
);
export const COLLECTION_FORMATS = {
    csv: ',',
    tsv: '   ',
    ssv: ' ',
    pipes: '|',
};

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFieldsSort } from './paginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFieldsSort';
import { PaginationResponse } from './paginationResponse';
import { CCProviderBuyerSupplierItem } from './cCProviderBuyerSupplierItem';
import { CCProviderBuyerSupplierFilters } from './cCProviderBuyerSupplierFilters';


export interface PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFields { 
    filters: CCProviderBuyerSupplierFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseCCProviderBuyerSupplierFiltersCCProviderBuyerSupplierItemCCProviderBuyerSupplierSortableFieldsSort;
    page?: Array<CCProviderBuyerSupplierItem>;
}


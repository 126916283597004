/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiscountRateResponse } from './discountRateResponse';
import { JointPlafondView } from './jointPlafondView';
import { VatView } from './vatView';
import { ConfirmingRelationStatusView } from './confirmingRelationStatusView';


export interface BuyerSupplierListItemResponse { 
    buyerCompanyId: number;
    factorSupplierCode?: string;
    supplierCompanyId: number;
    supplierCompanyName: string;
    supplierCompanyVat: VatView;
    supplierStatus: ConfirmingRelationStatusView;
    onboardingId?: number;
    discountRate?: DiscountRateResponse;
    jointPlafond?: JointPlafondView;
    onboardingCompletionRatio?: number;
}
export namespace BuyerSupplierListItemResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReverseStatusUpdated = 'Approved' | 'Credited' | 'PaidByBuyer' | 'Refused' | 'Rejected' | 'ToRecognize';

export const ReverseStatusUpdated = {
    Approved: 'Approved' as ReverseStatusUpdated,
    Credited: 'Credited' as ReverseStatusUpdated,
    PaidByBuyer: 'PaidByBuyer' as ReverseStatusUpdated,
    Refused: 'Refused' as ReverseStatusUpdated,
    Rejected: 'Rejected' as ReverseStatusUpdated,
    ToRecognize: 'ToRecognize' as ReverseStatusUpdated
};


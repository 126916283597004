/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PSD2AssociateBankAccountCompaniesRequest } from '../model/pSD2AssociateBankAccountCompaniesRequest';
// @ts-ignore
import { PSD2CreateOnboardingError } from '../model/pSD2CreateOnboardingError';
// @ts-ignore
import { PSD2CreateOnboardingRequest } from '../model/pSD2CreateOnboardingRequest';
// @ts-ignore
import { PSD2CreateOnboardingResponse } from '../model/pSD2CreateOnboardingResponse';
// @ts-ignore
import { PSD2ListBankAccountsResponse } from '../model/pSD2ListBankAccountsResponse';
// @ts-ignore
import { PSD2MovementCategoryView } from '../model/pSD2MovementCategoryView';
// @ts-ignore
import { PSD2MovementDirectionView } from '../model/pSD2MovementDirectionView';
// @ts-ignore
import { PSD2MovementListSortableFieldsView } from '../model/pSD2MovementListSortableFieldsView';
// @ts-ignore
import { PSD2MovementOperationKindView } from '../model/pSD2MovementOperationKindView';
// @ts-ignore
import { PSD2SyncUserError } from '../model/pSD2SyncUserError';
// @ts-ignore
import { PSD2UserStatusResponse } from '../model/pSD2UserStatusResponse';
// @ts-ignore
import { PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsView } from '../model/paginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsView';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PSD2Service {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param accountId 
     * @param paginationStart 
     * @param paginationMax 
     * @param bankAccountIds 
     * @param categories 
     * @param currencies 
     * @param minAmount 
     * @param maxAmount 
     * @param bookingDateFrom 
     * @param bookingDateTo 
     * @param valueDateFrom 
     * @param valueDateTo 
     * @param operationKinds 
     * @param directions 
     * @param search 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPsd2ApiV1AccountAccountidMovements(accountId: number, paginationStart: number, paginationMax: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsView>;
    public getPsd2ApiV1AccountAccountidMovements(accountId: number, paginationStart: number, paginationMax: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsView>>;
    public getPsd2ApiV1AccountAccountidMovements(accountId: number, paginationStart: number, paginationMax: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsView>>;
    public getPsd2ApiV1AccountAccountidMovements(accountId: number, paginationStart: number, paginationMax: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getPsd2ApiV1AccountAccountidMovements.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getPsd2ApiV1AccountAccountidMovements.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getPsd2ApiV1AccountAccountidMovements.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (bankAccountIds) {
            bankAccountIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'bankAccountIds');
            })
        }
        if (categories) {
            categories.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'categories');
            })
        }
        if (currencies) {
            currencies.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'currencies');
            })
        }
        if (minAmount !== undefined && minAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minAmount, 'minAmount');
        }
        if (maxAmount !== undefined && maxAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxAmount, 'maxAmount');
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingDateFrom, 'bookingDateFrom');
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingDateTo, 'bookingDateTo');
        }
        if (valueDateFrom !== undefined && valueDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>valueDateFrom, 'valueDateFrom');
        }
        if (valueDateTo !== undefined && valueDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>valueDateTo, 'valueDateTo');
        }
        if (operationKinds) {
            operationKinds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'operationKinds');
            })
        }
        if (directions) {
            directions.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'directions');
            })
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/psd2/api/v1/account/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/movements`;
        return this.httpClient.request<PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param format 
     * @param paginationStart 
     * @param paginationMax 
     * @param bankAccountIds 
     * @param categories 
     * @param currencies 
     * @param minAmount 
     * @param maxAmount 
     * @param bookingDateFrom 
     * @param bookingDateTo 
     * @param valueDateFrom 
     * @param valueDateTo 
     * @param operationKinds 
     * @param directions 
     * @param search 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPsd2ApiV1AccountAccountidMovementsReport(accountId: number, format: ReportFormat, paginationStart?: number, paginationMax?: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getPsd2ApiV1AccountAccountidMovementsReport(accountId: number, format: ReportFormat, paginationStart?: number, paginationMax?: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getPsd2ApiV1AccountAccountidMovementsReport(accountId: number, format: ReportFormat, paginationStart?: number, paginationMax?: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getPsd2ApiV1AccountAccountidMovementsReport(accountId: number, format: ReportFormat, paginationStart?: number, paginationMax?: number, bankAccountIds?: Array<number>, categories?: Array<PSD2MovementCategoryView>, currencies?: Array<string>, minAmount?: number, maxAmount?: number, bookingDateFrom?: string, bookingDateTo?: string, valueDateFrom?: string, valueDateTo?: string, operationKinds?: Array<PSD2MovementOperationKindView>, directions?: Array<PSD2MovementDirectionView>, search?: string, sortField?: PSD2MovementListSortableFieldsView, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getPsd2ApiV1AccountAccountidMovementsReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getPsd2ApiV1AccountAccountidMovementsReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }
        if (bankAccountIds) {
            bankAccountIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'bankAccountIds');
            })
        }
        if (categories) {
            categories.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'categories');
            })
        }
        if (currencies) {
            currencies.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'currencies');
            })
        }
        if (minAmount !== undefined && minAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>minAmount, 'minAmount');
        }
        if (maxAmount !== undefined && maxAmount !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>maxAmount, 'maxAmount');
        }
        if (bookingDateFrom !== undefined && bookingDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingDateFrom, 'bookingDateFrom');
        }
        if (bookingDateTo !== undefined && bookingDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>bookingDateTo, 'bookingDateTo');
        }
        if (valueDateFrom !== undefined && valueDateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>valueDateFrom, 'valueDateFrom');
        }
        if (valueDateTo !== undefined && valueDateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>valueDateTo, 'valueDateTo');
        }
        if (operationKinds) {
            operationKinds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'operationKinds');
            })
        }
        if (directions) {
            directions.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'directions');
            })
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/psd2/api/v1/account/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/movements-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPsd2ApiV1UserBankAccounts(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PSD2ListBankAccountsResponse>;
    public getPsd2ApiV1UserBankAccounts(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PSD2ListBankAccountsResponse>>;
    public getPsd2ApiV1UserBankAccounts(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PSD2ListBankAccountsResponse>>;
    public getPsd2ApiV1UserBankAccounts(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/psd2/api/v1/user/bank-accounts`;
        return this.httpClient.request<PSD2ListBankAccountsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPsd2ApiV1UserStatus(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PSD2UserStatusResponse>;
    public getPsd2ApiV1UserStatus(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PSD2UserStatusResponse>>;
    public getPsd2ApiV1UserStatus(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PSD2UserStatusResponse>>;
    public getPsd2ApiV1UserStatus(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/psd2/api/v1/user/status`;
        return this.httpClient.request<PSD2UserStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param bankAccountId 
     * @param pSD2AssociateBankAccountCompaniesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPsd2ApiV1UserBankAccountsBankaccountidAssociateCompanies(bankAccountId: number, pSD2AssociateBankAccountCompaniesRequest: PSD2AssociateBankAccountCompaniesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postPsd2ApiV1UserBankAccountsBankaccountidAssociateCompanies(bankAccountId: number, pSD2AssociateBankAccountCompaniesRequest: PSD2AssociateBankAccountCompaniesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postPsd2ApiV1UserBankAccountsBankaccountidAssociateCompanies(bankAccountId: number, pSD2AssociateBankAccountCompaniesRequest: PSD2AssociateBankAccountCompaniesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postPsd2ApiV1UserBankAccountsBankaccountidAssociateCompanies(bankAccountId: number, pSD2AssociateBankAccountCompaniesRequest: PSD2AssociateBankAccountCompaniesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (bankAccountId === null || bankAccountId === undefined) {
            throw new Error('Required parameter bankAccountId was null or undefined when calling postPsd2ApiV1UserBankAccountsBankaccountidAssociateCompanies.');
        }
        if (pSD2AssociateBankAccountCompaniesRequest === null || pSD2AssociateBankAccountCompaniesRequest === undefined) {
            throw new Error('Required parameter pSD2AssociateBankAccountCompaniesRequest was null or undefined when calling postPsd2ApiV1UserBankAccountsBankaccountidAssociateCompanies.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/psd2/api/v1/user/bank-accounts/${this.configuration.encodeParam({name: "bankAccountId", value: bankAccountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/associate-companies`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pSD2AssociateBankAccountCompaniesRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pSD2CreateOnboardingRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPsd2ApiV1UserOnboarding(pSD2CreateOnboardingRequest: PSD2CreateOnboardingRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PSD2CreateOnboardingResponse>;
    public postPsd2ApiV1UserOnboarding(pSD2CreateOnboardingRequest: PSD2CreateOnboardingRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PSD2CreateOnboardingResponse>>;
    public postPsd2ApiV1UserOnboarding(pSD2CreateOnboardingRequest: PSD2CreateOnboardingRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PSD2CreateOnboardingResponse>>;
    public postPsd2ApiV1UserOnboarding(pSD2CreateOnboardingRequest: PSD2CreateOnboardingRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (pSD2CreateOnboardingRequest === null || pSD2CreateOnboardingRequest === undefined) {
            throw new Error('Required parameter pSD2CreateOnboardingRequest was null or undefined when calling postPsd2ApiV1UserOnboarding.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/psd2/api/v1/user/onboarding`;
        return this.httpClient.request<PSD2CreateOnboardingResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pSD2CreateOnboardingRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPsd2ApiV1UserSync(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postPsd2ApiV1UserSync(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postPsd2ApiV1UserSync(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postPsd2ApiV1UserSync(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/psd2/api/v1/user/sync`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

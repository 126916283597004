/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EarlySalaryHRRequestResponse } from './earlySalaryHRRequestResponse';
import { PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort } from './paginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort';
import { PaginationResponse } from './paginationResponse';
import { EarlySalaryHRRequestFiltersResponse } from './earlySalaryHRRequestFiltersResponse';


export interface PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsView { 
    filters: EarlySalaryHRRequestFiltersResponse;
    pagination: PaginationResponse;
    sort?: PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort;
    page?: Array<EarlySalaryHRRequestResponse>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PSD2MovementOperationKindView = 'AccountTransfer' | 'BankTransfer' | 'Bill' | 'CBill' | 'CarTax' | 'Check' | 'Commission' | 'CounterWithdrawal' | 'CreditCard' | 'DebitCardATM' | 'DebitCardEcommerce' | 'Deposit' | 'DepositAccount' | 'DiscountRevenue' | 'Dividend' | 'F24' | 'Financing' | 'InsolventRIBA' | 'InsurancePolicy' | 'InterestTax' | 'Interests' | 'InvoicePayment' | 'Lease' | 'Loan' | 'MAV' | 'Other' | 'POS' | 'PagoPA' | 'PaymentAdjustment' | 'PaymentSlip' | 'PendingCharge' | 'PostalBill' | 'RAV' | 'Recharge' | 'Refund' | 'Reversal' | 'Riba' | 'RicavoEffettiAlDopoIncasso' | 'SBF' | 'SCTFiscalReduction' | 'SEPA' | 'Salary' | 'StampTax' | 'StocksAndBonds' | 'Taxes';

export const PSD2MovementOperationKindView = {
    AccountTransfer: 'AccountTransfer' as PSD2MovementOperationKindView,
    BankTransfer: 'BankTransfer' as PSD2MovementOperationKindView,
    Bill: 'Bill' as PSD2MovementOperationKindView,
    CBill: 'CBill' as PSD2MovementOperationKindView,
    CarTax: 'CarTax' as PSD2MovementOperationKindView,
    Check: 'Check' as PSD2MovementOperationKindView,
    Commission: 'Commission' as PSD2MovementOperationKindView,
    CounterWithdrawal: 'CounterWithdrawal' as PSD2MovementOperationKindView,
    CreditCard: 'CreditCard' as PSD2MovementOperationKindView,
    DebitCardAtm: 'DebitCardATM' as PSD2MovementOperationKindView,
    DebitCardEcommerce: 'DebitCardEcommerce' as PSD2MovementOperationKindView,
    Deposit: 'Deposit' as PSD2MovementOperationKindView,
    DepositAccount: 'DepositAccount' as PSD2MovementOperationKindView,
    DiscountRevenue: 'DiscountRevenue' as PSD2MovementOperationKindView,
    Dividend: 'Dividend' as PSD2MovementOperationKindView,
    F24: 'F24' as PSD2MovementOperationKindView,
    Financing: 'Financing' as PSD2MovementOperationKindView,
    InsolventRiba: 'InsolventRIBA' as PSD2MovementOperationKindView,
    InsurancePolicy: 'InsurancePolicy' as PSD2MovementOperationKindView,
    InterestTax: 'InterestTax' as PSD2MovementOperationKindView,
    Interests: 'Interests' as PSD2MovementOperationKindView,
    InvoicePayment: 'InvoicePayment' as PSD2MovementOperationKindView,
    Lease: 'Lease' as PSD2MovementOperationKindView,
    Loan: 'Loan' as PSD2MovementOperationKindView,
    Mav: 'MAV' as PSD2MovementOperationKindView,
    Other: 'Other' as PSD2MovementOperationKindView,
    Pos: 'POS' as PSD2MovementOperationKindView,
    PagoPa: 'PagoPA' as PSD2MovementOperationKindView,
    PaymentAdjustment: 'PaymentAdjustment' as PSD2MovementOperationKindView,
    PaymentSlip: 'PaymentSlip' as PSD2MovementOperationKindView,
    PendingCharge: 'PendingCharge' as PSD2MovementOperationKindView,
    PostalBill: 'PostalBill' as PSD2MovementOperationKindView,
    Rav: 'RAV' as PSD2MovementOperationKindView,
    Recharge: 'Recharge' as PSD2MovementOperationKindView,
    Refund: 'Refund' as PSD2MovementOperationKindView,
    Reversal: 'Reversal' as PSD2MovementOperationKindView,
    Riba: 'Riba' as PSD2MovementOperationKindView,
    RicavoEffettiAlDopoIncasso: 'RicavoEffettiAlDopoIncasso' as PSD2MovementOperationKindView,
    Sbf: 'SBF' as PSD2MovementOperationKindView,
    SctFiscalReduction: 'SCTFiscalReduction' as PSD2MovementOperationKindView,
    Sepa: 'SEPA' as PSD2MovementOperationKindView,
    Salary: 'Salary' as PSD2MovementOperationKindView,
    StampTax: 'StampTax' as PSD2MovementOperationKindView,
    StocksAndBonds: 'StocksAndBonds' as PSD2MovementOperationKindView,
    Taxes: 'Taxes' as PSD2MovementOperationKindView
};


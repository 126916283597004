/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PSD2MovementListSortableFieldsView = 'amount' | 'bankAccount' | 'beneficiary' | 'bookingDate' | 'debtor' | 'operationKind';

export const PSD2MovementListSortableFieldsView = {
    Amount: 'amount' as PSD2MovementListSortableFieldsView,
    BankAccount: 'bankAccount' as PSD2MovementListSortableFieldsView,
    Beneficiary: 'beneficiary' as PSD2MovementListSortableFieldsView,
    BookingDate: 'bookingDate' as PSD2MovementListSortableFieldsView,
    Debtor: 'debtor' as PSD2MovementListSortableFieldsView,
    OperationKind: 'operationKind' as PSD2MovementListSortableFieldsView
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';
import { AutoDiscountStatusView } from './autoDiscountStatusView';
import { ConfirmingServiceStatusView } from './confirmingServiceStatusView';
import { ConfirmingTypeApi } from './confirmingTypeApi';
import { ValidIbanResponse } from './validIbanResponse';
import { SupplierBuyerIbanResponse } from './supplierBuyerIbanResponse';


export interface SupplierBuyerFactorConfigurationResponse { 
    autoDiscountStatus: AutoDiscountStatusView;
    confirmingType: ConfirmingTypeApi;
    factorCompanyId: number;
    factorId: number;
    factorName: string;
    factorVat: VatView;
    iban: SupplierBuyerIbanResponse;
    ibansValidated?: Array<ValidIbanResponse>;
    status: ConfirmingServiceStatusView;
}
export namespace SupplierBuyerFactorConfigurationResponse {
}



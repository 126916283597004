/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AutocompleteResponseApiStringAutocompleteType } from '../model/autocompleteResponseApiStringAutocompleteType';
// @ts-ignore
import { AutocompleteResponseApiTypeAutocompleteType } from '../model/autocompleteResponseApiTypeAutocompleteType';
// @ts-ignore
import { AvailableFactorsResponse } from '../model/availableFactorsResponse';
// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { BadRequestErrorBudgetCheck } from '../model/badRequestErrorBudgetCheck';
// @ts-ignore
import { BuyerActivationRequest } from '../model/buyerActivationRequest';
// @ts-ignore
import { BuyerConfirmingConfirmInvoiceStatusUpdateInput } from '../model/buyerConfirmingConfirmInvoiceStatusUpdateInput';
// @ts-ignore
import { BuyerInvoiceResponse } from '../model/buyerInvoiceResponse';
// @ts-ignore
import { BuyerInvoiceSummaryResponse } from '../model/buyerInvoiceSummaryResponse';
// @ts-ignore
import { BuyerResponse } from '../model/buyerResponse';
// @ts-ignore
import { BuyerSettingsRequest } from '../model/buyerSettingsRequest';
// @ts-ignore
import { BuyerSettingsResponseView } from '../model/buyerSettingsResponseView';
// @ts-ignore
import { ConfirmCheckoutResponse } from '../model/confirmCheckoutResponse';
// @ts-ignore
import { ConfirmRequestSelectionResponse } from '../model/confirmRequestSelectionResponse';
// @ts-ignore
import { ConfirmingBuyerDivisionResponse } from '../model/confirmingBuyerDivisionResponse';
// @ts-ignore
import { ConfirmingDiscountRequestSelectionResponse } from '../model/confirmingDiscountRequestSelectionResponse';
// @ts-ignore
import { ConfirmingInvoicesSelectionsRequest } from '../model/confirmingInvoicesSelectionsRequest';
// @ts-ignore
import { ConfirmingInvoicesSummaryResponse } from '../model/confirmingInvoicesSummaryResponse';
// @ts-ignore
import { ConfirmingSupplierStatusResponse } from '../model/confirmingSupplierStatusResponse';
// @ts-ignore
import { ConfirmingTypeApi } from '../model/confirmingTypeApi';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { DashboardContentResponse } from '../model/dashboardContentResponse';
// @ts-ignore
import { DashboardSuppliersContentResponse } from '../model/dashboardSuppliersContentResponse';
// @ts-ignore
import { DateAggregationApi } from '../model/dateAggregationApi';
// @ts-ignore
import { DeferralInvoiceSelectionResponse } from '../model/deferralInvoiceSelectionResponse';
// @ts-ignore
import { DeferralInvoicesSelectionsRequest } from '../model/deferralInvoicesSelectionsRequest';
// @ts-ignore
import { DeferralRequestedResponse } from '../model/deferralRequestedResponse';
// @ts-ignore
import { DeferralStatusApi } from '../model/deferralStatusApi';
// @ts-ignore
import { DiscountRateRequest } from '../model/discountRateRequest';
// @ts-ignore
import { DiscountRequestResponseApi } from '../model/discountRequestResponseApi';
// @ts-ignore
import { DiscountRequestSelectionResponse } from '../model/discountRequestSelectionResponse';
// @ts-ignore
import { DiscountRequestsCheckoutResponse } from '../model/discountRequestsCheckoutResponse';
// @ts-ignore
import { DiscountStatusApi } from '../model/discountStatusApi';
// @ts-ignore
import { DocumentsResponse } from '../model/documentsResponse';
// @ts-ignore
import { EnableSupplierRequestBody } from '../model/enableSupplierRequestBody';
// @ts-ignore
import { FactorConfirmingBuyerStatusView } from '../model/factorConfirmingBuyerStatusView';
// @ts-ignore
import { FactorConfirmingRelationActionRequiredTypeView } from '../model/factorConfirmingRelationActionRequiredTypeView';
// @ts-ignore
import { FactorConfirmingRelationStatusView } from '../model/factorConfirmingRelationStatusView';
// @ts-ignore
import { FactorEnabledListResponse } from '../model/factorEnabledListResponse';
// @ts-ignore
import { FactorIntegrationTypeView } from '../model/factorIntegrationTypeView';
// @ts-ignore
import { FactorSelectionListResponse } from '../model/factorSelectionListResponse';
// @ts-ignore
import { FactorSupplierDetailResponse } from '../model/factorSupplierDetailResponse';
// @ts-ignore
import { FactorsResponse } from '../model/factorsResponse';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { InvoiceContentResponseBuyerInvoiceResponseBuyerInvoiceFiltersResponse } from '../model/invoiceContentResponseBuyerInvoiceResponseBuyerInvoiceFiltersResponse';
// @ts-ignore
import { InvoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse } from '../model/invoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse';
// @ts-ignore
import { InvoiceContentResponseDiscountRequestResponseApiDiscountRequestFiltersResponse } from '../model/invoiceContentResponseDiscountRequestResponseApiDiscountRequestFiltersResponse';
// @ts-ignore
import { InvoiceContentResponseFactorInvoiceResponseFactorInvoiceFiltersResponse } from '../model/invoiceContentResponseFactorInvoiceResponseFactorInvoiceFiltersResponse';
// @ts-ignore
import { InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse } from '../model/invoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse';
// @ts-ignore
import { InvoiceStatusApi } from '../model/invoiceStatusApi';
// @ts-ignore
import { InvoiceSummaryQuery } from '../model/invoiceSummaryQuery';
// @ts-ignore
import { JointPlafondRequest } from '../model/jointPlafondRequest';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PaginatedResponseBuyerFiltersBuyerResponseSortableFields } from '../model/paginatedResponseBuyerFiltersBuyerResponseSortableFields';
// @ts-ignore
import { PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFields } from '../model/paginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFields';
// @ts-ignore
import { PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFields } from '../model/paginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFields';
// @ts-ignore
import { PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFields } from '../model/paginatedResponseSupplierFiltersSupplierListItemResponseSortableFields';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { SignResponse } from '../model/signResponse';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { SortableFields } from '../model/sortableFields';
// @ts-ignore
import { SortableFields1 } from '../model/sortableFields1';
// @ts-ignore
import { SortableFields2 } from '../model/sortableFields2';
// @ts-ignore
import { SortableFields3 } from '../model/sortableFields3';
// @ts-ignore
import { SortableFields4 } from '../model/sortableFields4';
// @ts-ignore
import { SortableFields5 } from '../model/sortableFields5';
// @ts-ignore
import { SortableFields6 } from '../model/sortableFields6';
// @ts-ignore
import { SortableFields7 } from '../model/sortableFields7';
// @ts-ignore
import { SortableFields8 } from '../model/sortableFields8';
// @ts-ignore
import { SupplierActivationRequest } from '../model/supplierActivationRequest';
// @ts-ignore
import { SupplierAutoDiscountsRequest } from '../model/supplierAutoDiscountsRequest';
// @ts-ignore
import { SupplierAutoDiscountsResponse } from '../model/supplierAutoDiscountsResponse';
// @ts-ignore
import { SupplierBuyerStatusResponse } from '../model/supplierBuyerStatusResponse';
// @ts-ignore
import { SupplierConfirmingStatusResponse } from '../model/supplierConfirmingStatusResponse';
// @ts-ignore
import { SupplierInvoicesSummaryQuery } from '../model/supplierInvoicesSummaryQuery';
// @ts-ignore
import { SupplierListItemResponse } from '../model/supplierListItemResponse';
// @ts-ignore
import { SuppliersCounterResponseApi } from '../model/suppliersCounterResponseApi';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ConfirmingService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param buyerId 
     * @param factorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionFactorsFactorid(buyerId: number, factorId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionFactorsFactorid(buyerId: number, factorId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionFactorsFactorid(buyerId: number, factorId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionFactorsFactorid(buyerId: number, factorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteConfirmingApiV1BuyersBuyeridConfirmSelectionFactorsFactorid.');
        }
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling deleteConfirmingApiV1BuyersBuyeridConfirmSelectionFactorsFactorid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/confirm-selection/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteConfirmingApiV1BuyersBuyeridConfirmSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteConfirmingApiV1BuyersBuyeridConfirmSelectionInvoicesInvoiceid.');
        }
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling deleteConfirmingApiV1BuyersBuyeridConfirmSelectionInvoicesInvoiceid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/confirm-selection/invoices/${this.configuration.encodeParam({name: "invoiceId", value: invoiceId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling deleteConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling deleteConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/joint-plafond`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridActiveSuppliers(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SuppliersCounterResponseApi>;
    public getConfirmingApiV1BuyersBuyeridActiveSuppliers(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SuppliersCounterResponseApi>>;
    public getConfirmingApiV1BuyersBuyeridActiveSuppliers(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SuppliersCounterResponseApi>>;
    public getConfirmingApiV1BuyersBuyeridActiveSuppliers(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridActiveSuppliers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/active-suppliers`;
        return this.httpClient.request<SuppliersCounterResponseApi>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param query 
     * @param max 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridAutocomplete(buyerId: number, query: string, max?: number, supplierId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getConfirmingApiV1BuyersBuyeridAutocomplete(buyerId: number, query: string, max?: number, supplierId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1BuyersBuyeridAutocomplete(buyerId: number, query: string, max?: number, supplierId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1BuyersBuyeridAutocomplete(buyerId: number, query: string, max?: number, supplierId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV1BuyersBuyeridAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridAvailableFactors(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FactorSelectionListResponse>>;
    public getConfirmingApiV1BuyersBuyeridAvailableFactors(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FactorSelectionListResponse>>>;
    public getConfirmingApiV1BuyersBuyeridAvailableFactors(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FactorSelectionListResponse>>>;
    public getConfirmingApiV1BuyersBuyeridAvailableFactors(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridAvailableFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/available-factors`;
        return this.httpClient.request<Array<FactorSelectionListResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ConfirmRequestSelectionResponse>;
    public getConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ConfirmRequestSelectionResponse>>;
    public getConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ConfirmRequestSelectionResponse>>;
    public getConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridConfirmSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/confirm-selection`;
        return this.httpClient.request<ConfirmRequestSelectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param accumulate 
     * @param currency 
     * @param dateAggregation 
     * @param endDate 
     * @param startDate 
     * @param supplierId 
     * @param divisionId 
     * @param factorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridDashboard(buyerId: number, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DashboardContentResponse>;
    public getConfirmingApiV1BuyersBuyeridDashboard(buyerId: number, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DashboardContentResponse>>;
    public getConfirmingApiV1BuyersBuyeridDashboard(buyerId: number, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DashboardContentResponse>>;
    public getConfirmingApiV1BuyersBuyeridDashboard(buyerId: number, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridDashboard.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accumulate !== undefined && accumulate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accumulate, 'accumulate');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (dateAggregation !== undefined && dateAggregation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateAggregation, 'dateAggregation');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }
        if (divisionId !== undefined && divisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>divisionId, 'divisionId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dashboard`;
        return this.httpClient.request<DashboardContentResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param format 
     * @param accumulate 
     * @param currency 
     * @param dateAggregation 
     * @param endDate 
     * @param startDate 
     * @param supplierId 
     * @param divisionId 
     * @param factorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridDashboardReport(buyerId: number, format: ReportFormat, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV1BuyersBuyeridDashboardReport(buyerId: number, format: ReportFormat, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV1BuyersBuyeridDashboardReport(buyerId: number, format: ReportFormat, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV1BuyersBuyeridDashboardReport(buyerId: number, format: ReportFormat, accumulate?: boolean, currency?: string, dateAggregation?: DateAggregationApi, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridDashboardReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV1BuyersBuyeridDashboardReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accumulate !== undefined && accumulate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accumulate, 'accumulate');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (dateAggregation !== undefined && dateAggregation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateAggregation, 'dateAggregation');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }
        if (divisionId !== undefined && divisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>divisionId, 'divisionId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dashboard-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param currency 
     * @param endDate 
     * @param startDate 
     * @param supplierId 
     * @param divisionId 
     * @param factorId 
     * @param max 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliers(buyerId: number, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DashboardSuppliersContentResponse>;
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliers(buyerId: number, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DashboardSuppliersContentResponse>>;
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliers(buyerId: number, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DashboardSuppliersContentResponse>>;
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliers(buyerId: number, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridDashboardSuppliers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }
        if (divisionId !== undefined && divisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>divisionId, 'divisionId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dashboard/suppliers`;
        return this.httpClient.request<DashboardSuppliersContentResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param format 
     * @param currency 
     * @param endDate 
     * @param startDate 
     * @param supplierId 
     * @param divisionId 
     * @param factorId 
     * @param max 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliersReport(buyerId: number, format: ReportFormat, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliersReport(buyerId: number, format: ReportFormat, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliersReport(buyerId: number, format: ReportFormat, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV1BuyersBuyeridDashboardSuppliersReport(buyerId: number, format: ReportFormat, currency?: string, endDate?: string, startDate?: string, supplierId?: number, divisionId?: number, factorId?: number, max?: number, search?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridDashboardSuppliersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV1BuyersBuyeridDashboardSuppliersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }
        if (divisionId !== undefined && divisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>divisionId, 'divisionId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dashboard/suppliers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridDivisions(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<ConfirmingBuyerDivisionResponse>>;
    public getConfirmingApiV1BuyersBuyeridDivisions(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ConfirmingBuyerDivisionResponse>>>;
    public getConfirmingApiV1BuyersBuyeridDivisions(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ConfirmingBuyerDivisionResponse>>>;
    public getConfirmingApiV1BuyersBuyeridDivisions(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridDivisions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/divisions`;
        return this.httpClient.request<Array<ConfirmingBuyerDivisionResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridEnabledFactors(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FactorEnabledListResponse>>;
    public getConfirmingApiV1BuyersBuyeridEnabledFactors(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FactorEnabledListResponse>>>;
    public getConfirmingApiV1BuyersBuyeridEnabledFactors(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FactorEnabledListResponse>>>;
    public getConfirmingApiV1BuyersBuyeridEnabledFactors(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridEnabledFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/enabled-factors`;
        return this.httpClient.request<Array<FactorEnabledListResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridFactors(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<FactorsResponse>;
    public getConfirmingApiV1BuyersBuyeridFactors(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<FactorsResponse>>;
    public getConfirmingApiV1BuyersBuyeridFactors(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<FactorsResponse>>;
    public getConfirmingApiV1BuyersBuyeridFactors(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/factors`;
        return this.httpClient.request<FactorsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param buyerDivision 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param currency 
     * @param deferralStatus 
     * @param endDate 
     * @param factor 
     * @param invoiceIds 
     * @param max 
     * @param sort use sortField and sortOrder
     * @param start 
     * @param startDate 
     * @param status 
     * @param supplierId 
     * @param factorIntegrationType 
     * @param documentId 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridInvoiceIds(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getConfirmingApiV1BuyersBuyeridInvoiceIds(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getConfirmingApiV1BuyersBuyeridInvoiceIds(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getConfirmingApiV1BuyersBuyeridInvoiceIds(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoiceIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (deferralStatus) {
            deferralStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'deferralStatus');
            })
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (factor !== undefined && factor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factor, 'factor');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }
        if (factorIntegrationType !== undefined && factorIntegrationType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorIntegrationType, 'factorIntegrationType');
        }
        if (documentId !== undefined && documentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentId, 'documentId');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoice-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param buyerDivision 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param currency 
     * @param deferralStatus 
     * @param endDate 
     * @param factor 
     * @param invoiceIds 
     * @param max 
     * @param sort use sortField and sortOrder
     * @param start 
     * @param startDate 
     * @param status 
     * @param supplierId 
     * @param factorIntegrationType 
     * @param documentId 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridInvoices(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseBuyerInvoiceResponseBuyerInvoiceFiltersResponse>;
    public getConfirmingApiV1BuyersBuyeridInvoices(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseBuyerInvoiceResponseBuyerInvoiceFiltersResponse>>;
    public getConfirmingApiV1BuyersBuyeridInvoices(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseBuyerInvoiceResponseBuyerInvoiceFiltersResponse>>;
    public getConfirmingApiV1BuyersBuyeridInvoices(buyerId: number, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoices.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (deferralStatus) {
            deferralStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'deferralStatus');
            })
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (factor !== undefined && factor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factor, 'factor');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }
        if (factorIntegrationType !== undefined && factorIntegrationType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorIntegrationType, 'factorIntegrationType');
        }
        if (documentId !== undefined && documentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentId, 'documentId');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices`;
        return this.httpClient.request<InvoiceContentResponseBuyerInvoiceResponseBuyerInvoiceFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BuyerInvoiceResponse>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BuyerInvoiceResponse>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BuyerInvoiceResponse>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceid(buyerId: number, invoiceId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceid.');
        }
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/${this.configuration.encodeParam({name: "invoiceId", value: invoiceId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BuyerInvoiceResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param invoiceId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidConfirmedDocumentsDocumentid(buyerId: number, invoiceId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidConfirmedDocumentsDocumentid(buyerId: number, invoiceId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidConfirmedDocumentsDocumentid(buyerId: number, invoiceId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidConfirmedDocumentsDocumentid(buyerId: number, invoiceId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidConfirmedDocumentsDocumentid.');
        }
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidConfirmedDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidConfirmedDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/${this.configuration.encodeParam({name: "invoiceId", value: invoiceId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/confirmed/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param invoiceId 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays(buyerId: number, invoiceId: number, startDate: string, endDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Set<string>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays(buyerId: number, invoiceId: number, startDate: string, endDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Set<string>>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays(buyerId: number, invoiceId: number, startDate: string, endDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Set<string>>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays(buyerId: number, invoiceId: number, startDate: string, endDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays.');
        }
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesInvoiceidDeferralExcludedDays.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/${this.configuration.encodeParam({name: "invoiceId", value: invoiceId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/deferral-excluded-days`;
        return this.httpClient.request<Set<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param format 
     * @param buyerDivision 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param currency 
     * @param deferralStatus 
     * @param endDate 
     * @param factor 
     * @param invoiceIds 
     * @param max 
     * @param sort use sortField and sortOrder
     * @param start 
     * @param startDate 
     * @param status 
     * @param supplierId 
     * @param factorIntegrationType 
     * @param documentId 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridInvoicesReport(buyerId: number, format: ReportFormat, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV1BuyersBuyeridInvoicesReport(buyerId: number, format: ReportFormat, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesReport(buyerId: number, format: ReportFormat, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV1BuyersBuyeridInvoicesReport(buyerId: number, format: ReportFormat, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, currency?: string, deferralStatus?: Array<DeferralStatusApi>, endDate?: string, factor?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, startDate?: string, status?: InvoiceStatusApi, supplierId?: number, factorIntegrationType?: FactorIntegrationTypeView, documentId?: number, sortField?: SortableFields, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV1BuyersBuyeridInvoicesReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (deferralStatus) {
            deferralStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'deferralStatus');
            })
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (factor !== undefined && factor !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factor, 'factor');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (supplierId !== undefined && supplierId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierId, 'supplierId');
        }
        if (factorIntegrationType !== undefined && factorIntegrationType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorIntegrationType, 'factorIntegrationType');
        }
        if (documentId !== undefined && documentId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>documentId, 'documentId');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridReportsDiscounts(buyerId: number, startDate: string, endDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getConfirmingApiV1BuyersBuyeridReportsDiscounts(buyerId: number, startDate: string, endDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getConfirmingApiV1BuyersBuyeridReportsDiscounts(buyerId: number, startDate: string, endDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getConfirmingApiV1BuyersBuyeridReportsDiscounts(buyerId: number, startDate: string, endDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridReportsDiscounts.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getConfirmingApiV1BuyersBuyeridReportsDiscounts.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getConfirmingApiV1BuyersBuyeridReportsDiscounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reports/discounts`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridSuppliersAutocomplete(buyerId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getConfirmingApiV1BuyersBuyeridSuppliersAutocomplete(buyerId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1BuyersBuyeridSuppliersAutocomplete(buyerId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1BuyersBuyeridSuppliersAutocomplete(buyerId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridSuppliersAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV1BuyersBuyeridSuppliersAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierConfirmingStatusResponse>;
    public getConfirmingApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierConfirmingStatusResponse>>;
    public getConfirmingApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierConfirmingStatusResponse>>;
    public getConfirmingApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getConfirmingApiV1BuyersBuyeridSuppliersSupplieridStatus.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1BuyersBuyeridSuppliersSupplieridStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/status`;
        return this.httpClient.request<SupplierConfirmingStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<FactorSupplierDetailResponse>;
    public getConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<FactorSupplierDetailResponse>>;
    public getConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<FactorSupplierDetailResponse>>;
    public getConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<FactorSupplierDetailResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param buyer 
     * @param buyerCompanyIds 
     * @param confirmingType 
     * @param currency 
     * @param status 
     * @param sort use sortField and sortOrder
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridDiscountRequestIds(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestIds(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestIds(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestIds(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyer !== undefined && buyer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyer, 'buyer');
        }
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-request-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param buyer 
     * @param buyerCompanyIds 
     * @param confirmingType 
     * @param currency 
     * @param status 
     * @param sort use sortField and sortOrder
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridDiscountRequests(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseDiscountRequestResponseApiDiscountRequestFiltersResponse>;
    public getConfirmingApiV1FactorsFactoridDiscountRequests(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseDiscountRequestResponseApiDiscountRequestFiltersResponse>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequests(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseDiscountRequestResponseApiDiscountRequestFiltersResponse>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequests(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequests.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyer !== undefined && buyer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyer, 'buyer');
        }
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests`;
        return this.httpClient.request<InvoiceContentResponseDiscountRequestResponseApiDiscountRequestFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridDiscountRequestsAutocomplete(factorId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsAutocomplete(factorId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsAutocomplete(factorId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsAutocomplete(factorId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests-autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridDiscountRequestsId(factorId: number, id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DiscountRequestResponseApi>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsId(factorId: number, id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DiscountRequestResponseApi>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsId(factorId: number, id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DiscountRequestResponseApi>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsId(factorId: number, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsId.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsId.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<DiscountRequestResponseApi>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param buyer 
     * @param buyerCompanyIds 
     * @param confirmingType 
     * @param currency 
     * @param status 
     * @param sort use sortField and sortOrder
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridDiscountRequestsReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyer?: number, buyerCompanyIds?: Array<number>, confirmingType?: ConfirmingTypeApi, currency?: string, status?: DiscountStatusApi, sort?: string, sortField?: SortableFields1, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyer !== undefined && buyer !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyer, 'buyer');
        }
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocuments(factorId: number, requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DocumentsResponse>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocuments(factorId: number, requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DocumentsResponse>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocuments(factorId: number, requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DocumentsResponse>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocuments(factorId: number, requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocuments.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocuments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents`;
        return this.httpClient.request<DocumentsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param requestId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocumentsDocumentid.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getConfirmingApiV1FactorsFactoridDiscountRequestsRequestidDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param startDate 
     * @param endDate 
     * @param buyerDivision 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridInvoiceIds(factorId: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getConfirmingApiV1FactorsFactoridInvoiceIds(factorId: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getConfirmingApiV1FactorsFactoridInvoiceIds(factorId: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getConfirmingApiV1FactorsFactoridInvoiceIds(factorId: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridInvoiceIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoice-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param buyerDivision 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param sort use sortField and sortOrder
     * @param status 
     * @param invoiceIds 
     * @param currency 
     * @param deferralStatus 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridInvoices(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseFactorInvoiceResponseFactorInvoiceFiltersResponse>;
    public getConfirmingApiV1FactorsFactoridInvoices(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseFactorInvoiceResponseFactorInvoiceFiltersResponse>>;
    public getConfirmingApiV1FactorsFactoridInvoices(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseFactorInvoiceResponseFactorInvoiceFiltersResponse>>;
    public getConfirmingApiV1FactorsFactoridInvoices(factorId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridInvoices.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (deferralStatus) {
            deferralStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'deferralStatus');
            })
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices`;
        return this.httpClient.request<InvoiceContentResponseFactorInvoiceResponseFactorInvoiceFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getConfirmingApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridInvoicesAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV1FactorsFactoridInvoicesAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param buyerDivision 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param sort use sortField and sortOrder
     * @param status 
     * @param invoiceIds 
     * @param currency 
     * @param deferralStatus 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1FactorsFactoridInvoicesReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV1FactorsFactoridInvoicesReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV1FactorsFactoridInvoicesReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV1FactorsFactoridInvoicesReport(factorId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerDivision?: string, buyerCompanyId?: number, supplierCompanyId?: number, sort?: string, status?: InvoiceStatusApi, invoiceIds?: Array<number>, currency?: string, deferralStatus?: Array<DeferralStatusApi>, sortField?: SortableFields2, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV1FactorsFactoridInvoicesReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV1FactorsFactoridInvoicesReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (deferralStatus) {
            deferralStatus.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'deferralStatus');
            })
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridAvailableFactors(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AvailableFactorsResponse>;
    public getConfirmingApiV1SuppliersSupplieridAvailableFactors(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AvailableFactorsResponse>>;
    public getConfirmingApiV1SuppliersSupplieridAvailableFactors(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AvailableFactorsResponse>>;
    public getConfirmingApiV1SuppliersSupplieridAvailableFactors(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridAvailableFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/available-factors`;
        return this.httpClient.request<AvailableFactorsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierBuyerStatusResponse>;
    public getConfirmingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierBuyerStatusResponse>>;
    public getConfirmingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierBuyerStatusResponse>>;
    public getConfirmingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/status`;
        return this.httpClient.request<SupplierBuyerStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DocumentsResponse>;
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DocumentsResponse>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DocumentsResponse>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents`;
        return this.httpClient.request<DocumentsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param requestId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param buyerCompanyIds 
     * @param status 
     * @param searchQuery 
     * @param sort use sortField and sortOrder
     * @param discountIds 
     * @param confirmingType 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridDiscounts(supplierId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse>;
    public getConfirmingApiV1SuppliersSupplieridDiscounts(supplierId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse>>;
    public getConfirmingApiV1SuppliersSupplieridDiscounts(supplierId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse>>;
    public getConfirmingApiV1SuppliersSupplieridDiscounts(supplierId: number, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (searchQuery !== undefined && searchQuery !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchQuery, 'searchQuery');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (discountIds) {
            discountIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'discountIds');
            })
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts`;
        return this.httpClient.request<InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getConfirmingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountsAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountsAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param format 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param buyerCompanyIds 
     * @param status 
     * @param searchQuery 
     * @param sort use sortField and sortOrder
     * @param discountIds 
     * @param confirmingType 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridDiscountsReport(supplierId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV1SuppliersSupplieridDiscountsReport(supplierId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountsReport(supplierId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV1SuppliersSupplieridDiscountsReport(supplierId: number, format: ReportFormat, max?: number, start?: number, startDate?: string, endDate?: string, buyerCompanyIds?: Array<number>, status?: DiscountStatusApi, searchQuery?: string, sort?: string, discountIds?: Array<number>, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields8, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountsReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV1SuppliersSupplieridDiscountsReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (searchQuery !== undefined && searchQuery !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchQuery, 'searchQuery');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (discountIds) {
            discountIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'discountIds');
            })
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param currency 
     * @param endDueDate 
     * @param endIssueDate 
     * @param max 
     * @param start 
     * @param startDueDate 
     * @param startIssueDate 
     * @param buyerCompanyIds 
     * @param factorId 
     * @param invoiceIds 
     * @param searchQuery 
     * @param sort use sortField and sortOrder
     * @param status 
     * @param confirmingType 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse>;
    public getConfirmingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse>>;
    public getConfirmingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse>>;
    public getConfirmingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }
        if (endIssueDate === null || endIssueDate === undefined) {
            throw new Error('Required parameter endIssueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }
        if (startIssueDate === null || startIssueDate === undefined) {
            throw new Error('Required parameter startIssueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoices.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (endIssueDate !== undefined && endIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endIssueDate, 'endIssueDate');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (searchQuery !== undefined && searchQuery !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchQuery, 'searchQuery');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (startIssueDate !== undefined && startIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIssueDate, 'startIssueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices`;
        return this.httpClient.request<InvoiceContentResponseConfirmingInvoiceResponseSupplierInvoiceFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCart(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ConfirmingDiscountRequestSelectionResponse>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCart(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ConfirmingDiscountRequestSelectionResponse>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCart(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ConfirmingDiscountRequestSelectionResponse>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCart(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCart.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/discount-requests-cart`;
        return this.httpClient.request<ConfirmingDiscountRequestSelectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DiscountRequestSelectionResponse>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DiscountRequestSelectionResponse>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DiscountRequestSelectionResponse>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/discount-requests-selection`;
        return this.httpClient.request<DiscountRequestSelectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param currency 
     * @param endDueDate 
     * @param endIssueDate 
     * @param startDueDate 
     * @param startIssueDate 
     * @param status 
     * @param buyerCompanyIds 
     * @param factorId 
     * @param invoiceIds 
     * @param confirmingType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, startDueDate: string, startIssueDate: string, status: InvoiceStatusApi, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, confirmingType?: ConfirmingTypeApi, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, startDueDate: string, startIssueDate: string, status: InvoiceStatusApi, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, confirmingType?: ConfirmingTypeApi, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, startDueDate: string, startIssueDate: string, status: InvoiceStatusApi, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, confirmingType?: ConfirmingTypeApi, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, startDueDate: string, startIssueDate: string, status: InvoiceStatusApi, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, confirmingType?: ConfirmingTypeApi, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (endIssueDate === null || endIssueDate === undefined) {
            throw new Error('Required parameter endIssueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (startIssueDate === null || startIssueDate === undefined) {
            throw new Error('Required parameter startIssueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (endIssueDate !== undefined && endIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endIssueDate, 'endIssueDate');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (startIssueDate !== undefined && startIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIssueDate, 'startIssueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/invoice-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param invoiceId 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays(supplierId: number, invoiceId: number, startDate: string, endDate: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Set<string>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays(supplierId: number, invoiceId: number, startDate: string, endDate: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Set<string>>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays(supplierId: number, invoiceId: number, startDate: string, endDate: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Set<string>>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays(supplierId: number, invoiceId: number, startDate: string, endDate: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays.');
        }
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesInvoiceidExcludedDays.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/${this.configuration.encodeParam({name: "invoiceId", value: invoiceId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/excluded-days`;
        return this.httpClient.request<Set<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param currency 
     * @param endDueDate 
     * @param endIssueDate 
     * @param max 
     * @param start 
     * @param startDueDate 
     * @param startIssueDate 
     * @param format 
     * @param buyerCompanyIds 
     * @param factorId 
     * @param invoiceIds 
     * @param searchQuery 
     * @param sort use sortField and sortOrder
     * @param status 
     * @param confirmingType 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridInvoicesReport(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, format: ReportFormat, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesReport(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, format: ReportFormat, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesReport(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, format: ReportFormat, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV1SuppliersSupplieridInvoicesReport(supplierId: number, currency: string, endDueDate: string, endIssueDate: string, max: number, start: number, startDueDate: string, startIssueDate: string, format: ReportFormat, buyerCompanyIds?: Array<number>, factorId?: number, invoiceIds?: Array<number>, searchQuery?: string, sort?: string, status?: InvoiceStatusApi, confirmingType?: ConfirmingTypeApi, sortField?: SortableFields7, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (endIssueDate === null || endIssueDate === undefined) {
            throw new Error('Required parameter endIssueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (startIssueDate === null || startIssueDate === undefined) {
            throw new Error('Required parameter startIssueDate was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV1SuppliersSupplieridInvoicesReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerCompanyIds) {
            buyerCompanyIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'buyerCompanyIds');
            })
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (endIssueDate !== undefined && endIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endIssueDate, 'endIssueDate');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (searchQuery !== undefined && searchQuery !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>searchQuery, 'searchQuery');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (startIssueDate !== undefined && startIssueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startIssueDate, 'startIssueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (confirmingType !== undefined && confirmingType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>confirmingType, 'confirmingType');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierAutoDiscountsResponse>;
    public getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierAutoDiscountsResponse>>;
    public getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierAutoDiscountsResponse>>;
    public getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/settings/autodiscounts`;
        return this.httpClient.request<SupplierAutoDiscountsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV1SuppliersSupplieridStatus(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ConfirmingSupplierStatusResponse>;
    public getConfirmingApiV1SuppliersSupplieridStatus(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ConfirmingSupplierStatusResponse>>;
    public getConfirmingApiV1SuppliersSupplieridStatus(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ConfirmingSupplierStatusResponse>>;
    public getConfirmingApiV1SuppliersSupplieridStatus(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getConfirmingApiV1SuppliersSupplieridStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/status`;
        return this.httpClient.request<ConfirmingSupplierStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param buyerCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseBuyerFiltersBuyerResponseSortableFields>;
    public getConfirmingApiV2FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseBuyerFiltersBuyerResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseBuyerFiltersBuyerResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<PaginatedResponseBuyerFiltersBuyerResponseSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridBuyersAutocomplete(factorId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getConfirmingApiV2FactorsFactoridBuyersAutocomplete(factorId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV2FactorsFactoridBuyersAutocomplete(factorId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getConfirmingApiV2FactorsFactoridBuyersAutocomplete(factorId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BuyerResponse>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BuyerResponse>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BuyerResponse>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BuyerResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BuyerSettingsResponseView>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BuyerSettingsResponseView>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BuyerSettingsResponseView>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/settings`;
        return this.httpClient.request<BuyerSettingsResponseView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param relationStatus 
     * @param actionRequiredType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFields>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (relationStatus !== undefined && relationStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relationStatus, 'relationStatus');
        }
        if (actionRequiredType !== undefined && actionRequiredType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>actionRequiredType, 'actionRequiredType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers`;
        return this.httpClient.request<PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param relationStatus 
     * @param actionRequiredType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersReport(factorId: number, buyerCompanyId: number, format: ReportFormat, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersReport(factorId: number, buyerCompanyId: number, format: ReportFormat, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersReport(factorId: number, buyerCompanyId: number, format: ReportFormat, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersReport(factorId: number, buyerCompanyId: number, format: ReportFormat, sortField?: SortableFields5, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersReport.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (relationStatus !== undefined && relationStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relationStatus, 'relationStatus');
        }
        if (actionRequiredType !== undefined && actionRequiredType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>actionRequiredType, 'actionRequiredType');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param buyerCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV2FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV2FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV2FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields3, sortOrder?: SortOrder, buyerCompanyId?: number, status?: FactorConfirmingBuyerStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV2FactorsFactoridBuyersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param supplierCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFields>;
    public getConfirmingApiV2FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers`;
        return this.httpClient.request<PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridSuppliersAutocomplete(factorId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiTypeAutocompleteType>;
    public getConfirmingApiV2FactorsFactoridSuppliersAutocomplete(factorId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiTypeAutocompleteType>>;
    public getConfirmingApiV2FactorsFactoridSuppliersAutocomplete(factorId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiTypeAutocompleteType>>;
    public getConfirmingApiV2FactorsFactoridSuppliersAutocomplete(factorId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiTypeAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param supplierCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV2FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV2FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV2FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields6, sortOrder?: SortOrder, supplierCompanyId?: number, status?: FactorConfirmingRelationStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierListItemResponse>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierListItemResponse>>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierListItemResponse>>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<SupplierListItemResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param supplierCompanyId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param relationStatus 
     * @param actionRequiredType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFields>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFields>>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (relationStatus !== undefined && relationStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relationStatus, 'relationStatus');
        }
        if (actionRequiredType !== undefined && actionRequiredType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>actionRequiredType, 'actionRequiredType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param supplierCompanyId 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param relationStatus 
     * @param actionRequiredType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyersReport(factorId: number, supplierCompanyId: number, format: ReportFormat, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyersReport(factorId: number, supplierCompanyId: number, format: ReportFormat, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyersReport(factorId: number, supplierCompanyId: number, format: ReportFormat, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyersReport(factorId: number, supplierCompanyId: number, format: ReportFormat, sortField?: SortableFields4, sortOrder?: SortOrder, search?: string, relationStatus?: FactorConfirmingRelationStatusView, actionRequiredType?: FactorConfirmingRelationActionRequiredTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyersReport.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getConfirmingApiV2FactorsFactoridSuppliersSuppliercompanyidBuyersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (relationStatus !== undefined && relationStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relationStatus, 'relationStatus');
        }
        if (actionRequiredType !== undefined && actionRequiredType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>actionRequiredType, 'actionRequiredType');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1BuyersBuyeridConfirmCheckout(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ConfirmCheckoutResponse>;
    public postConfirmingApiV1BuyersBuyeridConfirmCheckout(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ConfirmCheckoutResponse>>;
    public postConfirmingApiV1BuyersBuyeridConfirmCheckout(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ConfirmCheckoutResponse>>;
    public postConfirmingApiV1BuyersBuyeridConfirmCheckout(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postConfirmingApiV1BuyersBuyeridConfirmCheckout.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-ONE-TIME-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth3) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth3');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-OTP', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/confirm-checkout`;
        return this.httpClient.request<ConfirmCheckoutResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, requestBody?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, requestBody?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, requestBody?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postConfirmingApiV1BuyersBuyeridConfirmSelection(buyerId: number, requestBody?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postConfirmingApiV1BuyersBuyeridConfirmSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/confirm-selection`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1BuyersBuyeridDeferrals(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DeferralRequestedResponse>;
    public postConfirmingApiV1BuyersBuyeridDeferrals(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DeferralRequestedResponse>>;
    public postConfirmingApiV1BuyersBuyeridDeferrals(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DeferralRequestedResponse>>;
    public postConfirmingApiV1BuyersBuyeridDeferrals(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postConfirmingApiV1BuyersBuyeridDeferrals.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-ONE-TIME-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth3) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth3');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-OTP', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/deferrals`;
        return this.httpClient.request<DeferralRequestedResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param deferralInvoicesSelectionsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1BuyersBuyeridInvoicesDeferralRequestsSelection(buyerId: number, deferralInvoicesSelectionsRequest: DeferralInvoicesSelectionsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DeferralInvoiceSelectionResponse>;
    public postConfirmingApiV1BuyersBuyeridInvoicesDeferralRequestsSelection(buyerId: number, deferralInvoicesSelectionsRequest: DeferralInvoicesSelectionsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DeferralInvoiceSelectionResponse>>;
    public postConfirmingApiV1BuyersBuyeridInvoicesDeferralRequestsSelection(buyerId: number, deferralInvoicesSelectionsRequest: DeferralInvoicesSelectionsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DeferralInvoiceSelectionResponse>>;
    public postConfirmingApiV1BuyersBuyeridInvoicesDeferralRequestsSelection(buyerId: number, deferralInvoicesSelectionsRequest: DeferralInvoicesSelectionsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postConfirmingApiV1BuyersBuyeridInvoicesDeferralRequestsSelection.');
        }
        if (deferralInvoicesSelectionsRequest === null || deferralInvoicesSelectionsRequest === undefined) {
            throw new Error('Required parameter deferralInvoicesSelectionsRequest was null or undefined when calling postConfirmingApiV1BuyersBuyeridInvoicesDeferralRequestsSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/deferral-requests-selection`;
        return this.httpClient.request<DeferralInvoiceSelectionResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deferralInvoicesSelectionsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param invoiceSummaryQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceSummaryQuery: InvoiceSummaryQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BuyerInvoiceSummaryResponse>;
    public postConfirmingApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceSummaryQuery: InvoiceSummaryQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BuyerInvoiceSummaryResponse>>;
    public postConfirmingApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceSummaryQuery: InvoiceSummaryQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BuyerInvoiceSummaryResponse>>;
    public postConfirmingApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceSummaryQuery: InvoiceSummaryQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postConfirmingApiV1BuyersBuyeridInvoicesSummary.');
        }
        if (invoiceSummaryQuery === null || invoiceSummaryQuery === undefined) {
            throw new Error('Required parameter invoiceSummaryQuery was null or undefined when calling postConfirmingApiV1BuyersBuyeridInvoicesSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/summary`;
        return this.httpClient.request<BuyerInvoiceSummaryResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: invoiceSummaryQuery,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param signatureId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1BuyersBuyeridSignableDocumentsSignatureid(buyerId: number, signatureId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SignResponse>;
    public postConfirmingApiV1BuyersBuyeridSignableDocumentsSignatureid(buyerId: number, signatureId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SignResponse>>;
    public postConfirmingApiV1BuyersBuyeridSignableDocumentsSignatureid(buyerId: number, signatureId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SignResponse>>;
    public postConfirmingApiV1BuyersBuyeridSignableDocumentsSignatureid(buyerId: number, signatureId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postConfirmingApiV1BuyersBuyeridSignableDocumentsSignatureid.');
        }
        if (signatureId === null || signatureId === undefined) {
            throw new Error('Required parameter signatureId was null or undefined when calling postConfirmingApiV1BuyersBuyeridSignableDocumentsSignatureid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/signable-documents/${this.configuration.encodeParam({name: "signatureId", value: signatureId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<SignResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param supplierAutoDiscountsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckout(supplierId: number, supplierAutoDiscountsRequest?: SupplierAutoDiscountsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DiscountRequestsCheckoutResponse>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckout(supplierId: number, supplierAutoDiscountsRequest?: SupplierAutoDiscountsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DiscountRequestsCheckoutResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckout(supplierId: number, supplierAutoDiscountsRequest?: SupplierAutoDiscountsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DiscountRequestsCheckoutResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckout(supplierId: number, supplierAutoDiscountsRequest?: SupplierAutoDiscountsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckout.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/discount-requests-checkout`;
        return this.httpClient.request<DiscountRequestsCheckoutResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierAutoDiscountsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param supplierAutoDiscountsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DiscountRequestsCheckoutResponse>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DiscountRequestsCheckoutResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DiscountRequestsCheckoutResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp.');
        }
        if (supplierAutoDiscountsRequest === null || supplierAutoDiscountsRequest === undefined) {
            throw new Error('Required parameter supplierAutoDiscountsRequest was null or undefined when calling postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsCheckoutOtp.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-ONE-TIME-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth3) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth3');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-OTP', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/discount-requests-checkout-otp`;
        return this.httpClient.request<DiscountRequestsCheckoutResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierAutoDiscountsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param confirmingInvoicesSelectionsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, confirmingInvoicesSelectionsRequest: ConfirmingInvoicesSelectionsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ConfirmingDiscountRequestSelectionResponse>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, confirmingInvoicesSelectionsRequest: ConfirmingInvoicesSelectionsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ConfirmingDiscountRequestSelectionResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, confirmingInvoicesSelectionsRequest: ConfirmingInvoicesSelectionsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ConfirmingDiscountRequestSelectionResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, confirmingInvoicesSelectionsRequest: ConfirmingInvoicesSelectionsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection.');
        }
        if (confirmingInvoicesSelectionsRequest === null || confirmingInvoicesSelectionsRequest === undefined) {
            throw new Error('Required parameter confirmingInvoicesSelectionsRequest was null or undefined when calling postConfirmingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/discount-requests-selection`;
        return this.httpClient.request<ConfirmingDiscountRequestSelectionResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: confirmingInvoicesSelectionsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param supplierInvoicesSummaryQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, supplierInvoicesSummaryQuery: SupplierInvoicesSummaryQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ConfirmingInvoicesSummaryResponse>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, supplierInvoicesSummaryQuery: SupplierInvoicesSummaryQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ConfirmingInvoicesSummaryResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, supplierInvoicesSummaryQuery: SupplierInvoicesSummaryQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ConfirmingInvoicesSummaryResponse>>;
    public postConfirmingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, supplierInvoicesSummaryQuery: SupplierInvoicesSummaryQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postConfirmingApiV1SuppliersSupplieridInvoicesSummary.');
        }
        if (supplierInvoicesSummaryQuery === null || supplierInvoicesSummaryQuery === undefined) {
            throw new Error('Required parameter supplierInvoicesSummaryQuery was null or undefined when calling postConfirmingApiV1SuppliersSupplieridInvoicesSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/summary`;
        return this.httpClient.request<ConfirmingInvoicesSummaryResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierInvoicesSummaryQuery,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param supplierAutoDiscountsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts(supplierId: number, supplierAutoDiscountsRequest: SupplierAutoDiscountsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts.');
        }
        if (supplierAutoDiscountsRequest === null || supplierAutoDiscountsRequest === undefined) {
            throw new Error('Required parameter supplierAutoDiscountsRequest was null or undefined when calling postConfirmingApiV1SuppliersSupplieridSettingsAutodiscounts.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-ONE-TIME-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth3) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth3');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-OTP', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/settings/autodiscounts`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierAutoDiscountsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param buyerActivationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, buyerActivationRequest: BuyerActivationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, buyerActivationRequest: BuyerActivationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, buyerActivationRequest: BuyerActivationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, buyerActivationRequest: BuyerActivationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidActivation.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidActivation.');
        }
        if (buyerActivationRequest === null || buyerActivationRequest === undefined) {
            throw new Error('Required parameter buyerActivationRequest was null or undefined when calling postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: buyerActivationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param enableSupplierRequestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, enableSupplierRequestBody: EnableSupplierRequestBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, enableSupplierRequestBody: EnableSupplierRequestBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, enableSupplierRequestBody: EnableSupplierRequestBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, enableSupplierRequestBody: EnableSupplierRequestBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (enableSupplierRequestBody === null || enableSupplierRequestBody === undefined) {
            throw new Error('Required parameter enableSupplierRequestBody was null or undefined when calling postConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: enableSupplierRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param buyerConfirmingConfirmInvoiceStatusUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putConfirmingApiV1BuyersBuyeridInvoicesStatusUpdate(buyerId: number, buyerConfirmingConfirmInvoiceStatusUpdateInput: BuyerConfirmingConfirmInvoiceStatusUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putConfirmingApiV1BuyersBuyeridInvoicesStatusUpdate(buyerId: number, buyerConfirmingConfirmInvoiceStatusUpdateInput: BuyerConfirmingConfirmInvoiceStatusUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putConfirmingApiV1BuyersBuyeridInvoicesStatusUpdate(buyerId: number, buyerConfirmingConfirmInvoiceStatusUpdateInput: BuyerConfirmingConfirmInvoiceStatusUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putConfirmingApiV1BuyersBuyeridInvoicesStatusUpdate(buyerId: number, buyerConfirmingConfirmInvoiceStatusUpdateInput: BuyerConfirmingConfirmInvoiceStatusUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling putConfirmingApiV1BuyersBuyeridInvoicesStatusUpdate.');
        }
        if (buyerConfirmingConfirmInvoiceStatusUpdateInput === null || buyerConfirmingConfirmInvoiceStatusUpdateInput === undefined) {
            throw new Error('Required parameter buyerConfirmingConfirmInvoiceStatusUpdateInput was null or undefined when calling putConfirmingApiV1BuyersBuyeridInvoicesStatusUpdate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/status-update`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: buyerConfirmingConfirmInvoiceStatusUpdateInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param supplierActivationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, supplierActivationRequest: SupplierActivationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, supplierActivationRequest: SupplierActivationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, supplierActivationRequest: SupplierActivationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, supplierActivationRequest: SupplierActivationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling putConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling putConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }
        if (supplierActivationRequest === null || supplierActivationRequest === undefined) {
            throw new Error('Required parameter supplierActivationRequest was null or undefined when calling putConfirmingApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierActivationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param discountRateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate(factorId: number, buyerCompanyId: number, supplierCompanyId: number, discountRateRequest: DiscountRateRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate(factorId: number, buyerCompanyId: number, supplierCompanyId: number, discountRateRequest: DiscountRateRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate(factorId: number, buyerCompanyId: number, supplierCompanyId: number, discountRateRequest: DiscountRateRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate(factorId: number, buyerCompanyId: number, supplierCompanyId: number, discountRateRequest: DiscountRateRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate.');
        }
        if (discountRateRequest === null || discountRateRequest === undefined) {
            throw new Error('Required parameter discountRateRequest was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidDiscountRate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-rate`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: discountRateRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage(factorId: number, buyerCompanyId: number, supplierCompanyId: number, body: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage(factorId: number, buyerCompanyId: number, supplierCompanyId: number, body: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage(factorId: number, buyerCompanyId: number, supplierCompanyId: number, body: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage(factorId: number, buyerCompanyId: number, supplierCompanyId: number, body: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage.');
        }
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling putConfirmingApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidFeePercentage.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/fee-percentage`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: body,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param buyerSettingsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, buyerSettingsRequest: BuyerSettingsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, buyerSettingsRequest: BuyerSettingsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, buyerSettingsRequest: BuyerSettingsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings(factorId: number, buyerCompanyId: number, buyerSettingsRequest: BuyerSettingsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings.');
        }
        if (buyerSettingsRequest === null || buyerSettingsRequest === undefined) {
            throw new Error('Required parameter buyerSettingsRequest was null or undefined when calling putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSettings.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/settings`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: buyerSettingsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param jointPlafondRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, jointPlafondRequest: JointPlafondRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, jointPlafondRequest: JointPlafondRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, jointPlafondRequest: JointPlafondRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond(factorId: number, buyerCompanyId: number, supplierCompanyId: number, jointPlafondRequest: JointPlafondRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond.');
        }
        if (jointPlafondRequest === null || jointPlafondRequest === undefined) {
            throw new Error('Required parameter jointPlafondRequest was null or undefined when calling putConfirmingApiV2FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidJointPlafond.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/confirming/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/joint-plafond`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jointPlafondRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

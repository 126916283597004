import { Routes, mapToCanActivate } from '@angular/router';
import { IsLoginGuard } from 'src/shared/guards/is-login-guard';
import { IsPrivateGuard } from 'src/shared/guards/is-private-guard';

export const routes: Routes = [
    {
        canActivateChild: mapToCanActivate([IsLoginGuard]),
        loadChildren: () =>
            import('./private/tabs/tabs.routes').then((m) => m.TabRoutes),
        path: 'private',
    },
    {
        canActivateChild: mapToCanActivate([IsPrivateGuard]),
        loadChildren: () =>
            import('./public/public.routes').then((m) => m.LoginRoutes),
        path: '',
    },
];

import { Injectable } from '@angular/core';
import {
    DiscountStatusApi,
    InvoiceStatusApi,
} from '@fd/clients/findynamic/client';
import { StateColorEnum } from 'src/components/table-status-cell/table-status-cell.component';

@Injectable()
export class SupplierStatusService {
    static getStateClass(
        status: InvoiceStatusApi | DiscountStatusApi,
    ): StateColorEnum {
        switch (status) {
            case InvoiceStatusApi.Discounted:
            case InvoiceStatusApi.Confirming:
                return 'success';

            case DiscountStatusApi.Requested:
            case InvoiceStatusApi.Inserted:
            case InvoiceStatusApi.Confirmed:
                return 'warning';

            case DiscountStatusApi.Approved:
            case InvoiceStatusApi.DiscountApproval:
            case InvoiceStatusApi.Paid:
                return 'info';

            case DiscountStatusApi.Rejected:
            case InvoiceStatusApi.Canceled:
                return 'danger';

            case InvoiceStatusApi.Filtered:
            default:
                return 'default';
        }
    }
}

import { Injectable } from '@angular/core';
import { DateTime, Duration, Interval, Interval as LuxonInterval } from 'luxon';
import moment from 'moment';

@Injectable()
export class TimeService {
    static DATE_FORMAT = 'YYYY-MM-DD';

    static DATE_FORMAT_FOR_LUXON = 'yyyy-MM-dd';

    static SATURDAY_WEEKDAY_INDEX = 6;

    static SUNDAY_WEEKDAY_INDEX = 7;

    static daysBetween(start: DateTime, end: DateTime): number {
        return LuxonInterval.fromDateTimes(start, end).length('days');
    }

    static daysListBetween(start: DateTime, end: DateTime): DateTime[] {
        const daysBetween = TimeService.daysBetween(start, end);

        const result: DateTime[] = [];
        result.push(start);
        for (let i = 0; i < daysBetween; i++) {
            result.push(result[i].plus({ days: 1 }));
        }

        return result;
    }

    static findSmallestDate(dates: DateTime[]): DateTime | null {
        if (!dates?.length) return null;

        let minorDate: DateTime = dates[0].startOf('day');
        dates.forEach((d) => {
            const currentDate = d.startOf('day');
            if (currentDate < minorDate) {
                minorDate = currentDate;
            }
        });

        return minorDate;
    }

    static getFirstDayCurrentMonth(): DateTime {
        return TimeService.today().startOf('month');
    }

    static getLastDayOfMonth(
        month: number,
        year: number = TimeService.today().get('year'),
    ): DateTime {
        return TimeService.today()
            .set({ month, year })
            .plus({ month: 1 })
            .set({ day: 1 })
            .minus({ day: 1 });
    }

    static getTimeout(minutesToTimeout: number): Date {
        const newTimeout = new Date();
        newTimeout.setMinutes(newTimeout.getMinutes() + minutesToTimeout);
        return newTimeout;
    }

    static lastYearInterval(): Interval {
        const now = DateTime.local().startOf('day');

        return Interval.fromDateTimes(
            now.minus(Duration.fromObject({ months: 12 })),
            now,
        );
    }

    static lastYearIntervalFrom(date: DateTime): Interval {
        const endDate = date.startOf('day');

        return Interval.fromDateTimes(
            endDate.minus(Duration.fromObject({ months: 12 })).startOf('month'),
            endDate,
        );
    }

    static nextYearInterval(): Interval {
        const now = DateTime.local().startOf('day');

        return Interval.fromDateTimes(
            now,
            now.plus(Duration.fromObject({ months: 12 })),
        );
    }

    static timestamp(): string {
        return moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS');
    }

    static toDate(toConvert: string): DateTime {
        return DateTime.fromFormat(
            toConvert,
            TimeService.DATE_FORMAT_FOR_LUXON,
        ).startOf('day');
    }

    static toInterval(startDate: string, endDate: string): Interval {
        const startDateMoment = moment(startDate, TimeService.DATE_FORMAT);
        const endDateMoment = moment(endDate, TimeService.DATE_FORMAT);
        return Interval.fromDateTimes(
            startDateMoment.toDate(),
            endDateMoment.toDate(),
        );
    }

    static today(): DateTime {
        return DateTime.local().startOf('day');
    }

    static weekdaysIntoInterval(
        startDate: DateTime,
        endDate: DateTime,
        weekdays: number[],
    ): DateTime[] {
        const duration: number | undefined = LuxonInterval.fromDateTimes(
            startDate.endOf('day'),
            endDate.endOf('day'),
        )
            .toDuration('days')
            .toObject().days;

        const durationList = Array.from(Array(duration).keys());

        const days: DateTime[] = [];
        durationList.forEach((day) => {
            const dayToCheck = startDate.plus({ days: day });
            if (weekdays.includes(dayToCheck.weekday)) days.push(dayToCheck);
        });

        return days;
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LoanCreationRegistry } from './loanCreationRegistry';
import { KpiGridWrite } from './kpiGridWrite';


export interface LoanCreationRequest { 
    loanRegistry: LoanCreationRegistry;
    kpi1: KpiGridWrite;
    kpi2: KpiGridWrite;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AllocatedService2 } from './allocatedService2';
import { ReverseServiceStatus } from './reverseServiceStatus';


export interface BuyerDivision2 { 
    allocatedService?: AllocatedService2;
    code: string;
    name: string;
    status: ReverseServiceStatus;
}
export namespace BuyerDivision2 {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DiscountStatusApi = 'Approved' | 'DocumentToSign' | 'Paid' | 'Rejected' | 'Requested' | 'WaitingForDocument';

export const DiscountStatusApi = {
    Approved: 'Approved' as DiscountStatusApi,
    DocumentToSign: 'DocumentToSign' as DiscountStatusApi,
    Paid: 'Paid' as DiscountStatusApi,
    Rejected: 'Rejected' as DiscountStatusApi,
    Requested: 'Requested' as DiscountStatusApi,
    WaitingForDocument: 'WaitingForDocument' as DiscountStatusApi
};


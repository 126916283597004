/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FactorSignatureServiceView = 'ActiveFinancing' | 'ConfirmingConfirm' | 'ConfirmingDiscount' | 'ReverseRecognize';

export const FactorSignatureServiceView = {
    ActiveFinancing: 'ActiveFinancing' as FactorSignatureServiceView,
    ConfirmingConfirm: 'ConfirmingConfirm' as FactorSignatureServiceView,
    ConfirmingDiscount: 'ConfirmingDiscount' as FactorSignatureServiceView,
    ReverseRecognize: 'ReverseRecognize' as FactorSignatureServiceView
};


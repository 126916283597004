/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RateApplicationTimeView } from './rateApplicationTimeView';
import { ConfirmRequestBudgetGroupResponse } from './confirmRequestBudgetGroupResponse';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface ConfirmRequestFactorGroupResponse { 
    factorId: number;
    factorName: string;
    factorVatCountry: string;
    factorVatCode: string;
    invoicesAmount: number;
    invoicesPrizeAmount: number;
    confirmedNetAmount: number;
    notesAmount: number;
    currency: string;
    rateApplicationTime: RateApplicationTimeView;
    groups?: Array<ConfirmRequestBudgetGroupResponse>;
    integrationType: FactorIntegrationTypeView;
}
export namespace ConfirmRequestFactorGroupResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReferenceRateTypeView = 'Euribor12M' | 'Euribor1M' | 'Euribor3M' | 'Euribor6M' | 'EuriborWeekly' | 'USDLibor12M' | 'USDLibor1M' | 'USDLibor3M' | 'USDLibor6M' | 'USDLiborDaily' | 'ECBRate' | 'FEDRate';

export const ReferenceRateTypeView = {
    Euribor12M: 'Euribor12M' as ReferenceRateTypeView,
    Euribor1M: 'Euribor1M' as ReferenceRateTypeView,
    Euribor3M: 'Euribor3M' as ReferenceRateTypeView,
    Euribor6M: 'Euribor6M' as ReferenceRateTypeView,
    EuriborWeekly: 'EuriborWeekly' as ReferenceRateTypeView,
    UsdLibor12M: 'USDLibor12M' as ReferenceRateTypeView,
    UsdLibor1M: 'USDLibor1M' as ReferenceRateTypeView,
    UsdLibor3M: 'USDLibor3M' as ReferenceRateTypeView,
    UsdLibor6M: 'USDLibor6M' as ReferenceRateTypeView,
    UsdLiborDaily: 'USDLiborDaily' as ReferenceRateTypeView,
    EcbRate: 'ECBRate' as ReferenceRateTypeView,
    FedRate: 'FEDRate' as ReferenceRateTypeView
};


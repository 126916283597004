/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerDivision1 } from './buyerDivision1';
import { VatView } from './vatView';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface BuyerFactorConfigurationResponse { 
    divisions?: Array<BuyerDivision1>;
    factorId: number;
    factorName: string;
    factorVat: VatView;
    currency?: string;
    originalBuyerBudget?: number;
    availableBuyerBudget?: number;
    integrationType: FactorIntegrationTypeView;
}
export namespace BuyerFactorConfigurationResponse {
}



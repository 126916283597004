/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditCardPaymentStatus1 } from './creditCardPaymentStatus1';
import { CreditCardPaymentSettings1 } from './creditCardPaymentSettings1';
import { CreditCardProvider1 } from './creditCardProvider1';


export interface CreditCardPayment1 { 
    provider: CreditCardProvider1;
    status: CreditCardPaymentStatus1;
    settings?: CreditCardPaymentSettings1;
}
export namespace CreditCardPayment1 {
}



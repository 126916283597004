/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EarlySalaryEmployeeFiltersResponse } from './earlySalaryEmployeeFiltersResponse';
import { EarlySalaryEmployeeResponse } from './earlySalaryEmployeeResponse';
import { PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsViewSort } from './paginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsViewSort';
import { PaginationResponse } from './paginationResponse';


export interface PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsView { 
    filters: EarlySalaryEmployeeFiltersResponse;
    pagination: PaginationResponse;
    sort?: PaginatedResponseEarlySalaryEmployeeFiltersResponseEarlySalaryEmployeeResponseEarlySalaryEmployeeSortableFieldsViewSort;
    page?: Array<EarlySalaryEmployeeResponse>;
}


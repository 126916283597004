/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EsgDistributionApi } from './esgDistributionApi';
import { EsgValueApi } from './esgValueApi';
import { EsgSectorApi } from './esgSectorApi';


export interface EsgScoreDataApi { 
    values?: Array<EsgValueApi>;
    sectors?: Array<EsgSectorApi>;
    distributions?: Array<EsgDistributionApi>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfirmingTypeApi } from './confirmingTypeApi';


export interface SelectionServiceSummaryResponse { 
    averageAccelerationDays: number;
    averageDiscountPercentage: number;
    confirmingType: ConfirmingTypeApi;
    count: number;
    discountAmount: number;
    discountableAmount: number;
    invoiceAmount: number;
    netAmount: number;
    notesAmount: number;
    prizeAmount: number;
}
export namespace SelectionServiceSummaryResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';
import { OnboardingDocumentResponse } from './onboardingDocumentResponse';
import { OnboardingServiceView } from './onboardingServiceView';


export interface CustomerOnboardingResponse { 
    factorName: string;
    service: OnboardingServiceView;
    missingDocuments: number;
    uploadedDocuments: number;
    totalDocuments: number;
    customerName?: string;
    customerVat?: VatView;
    documents?: Array<OnboardingDocumentResponse>;
}
export namespace CustomerOnboardingResponse {
}



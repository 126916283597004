/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsViewSort { 
    field: PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsViewSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsViewSort {
    export type FieldEnum = 'amount' | 'bankAccount' | 'beneficiary' | 'bookingDate' | 'debtor' | 'operationKind';
    export const FieldEnum = {
        Amount: 'amount' as FieldEnum,
        BankAccount: 'bankAccount' as FieldEnum,
        Beneficiary: 'beneficiary' as FieldEnum,
        BookingDate: 'bookingDate' as FieldEnum,
        Debtor: 'debtor' as FieldEnum,
        OperationKind: 'operationKind' as FieldEnum
    };
}



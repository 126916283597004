/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetCheck } from './budgetCheck';
import { InvoiceStatusApi } from './invoiceStatusApi';
import { InvoiceDetailResponse } from './invoiceDetailResponse';
import { ConfirmingSupplierFilteredReasonView } from './confirmingSupplierFilteredReasonView';
import { VatView } from './vatView';
import { ConfirmingTypeApi } from './confirmingTypeApi';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface ConfirmingInvoiceResponse { 
    id: number;
    amount: number;
    buyerCompanyId: number;
    buyerId: number;
    buyerName: string;
    confirmingType: ConfirmingTypeApi;
    division: string;
    factorIntegrationType: FactorIntegrationTypeView;
    factorName: string;
    factorVat: VatView;
    documentNumber: string;
    dueDate: string;
    issueDate: string;
    notesAmount?: number;
    prizeAmount?: number;
    reason?: ConfirmingSupplierFilteredReasonView;
    status: InvoiceStatusApi;
    budget: BudgetCheck;
    cigCode?: string;
    cupCode?: string;
    iban?: string;
    invoiceDetails?: Array<InvoiceDetailResponse>;
    factorPaymentMethod?: string;
}
export namespace ConfirmingInvoiceResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EsgDistributionApi } from './esgDistributionApi';
import { EsgPerformanceApi } from './esgPerformanceApi';
import { EsgOpenEsSectionApi } from './esgOpenEsSectionApi';


export interface EsgOpenEsDistributionApi { 
    section: EsgOpenEsSectionApi;
    myPerformance?: EsgPerformanceApi;
    data?: Array<EsgDistributionApi>;
}
export namespace EsgOpenEsDistributionApi {
}



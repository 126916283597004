import { Injectable } from "@angular/core";
import toCurrency from "currency-symbol-map";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";

export type FLAG_MAP = "EUR" | "USD";

const FLAG_MAP = {
  EUR: "eu" as FLAG_MAP,
  USD: "us" as FLAG_MAP,
};

export type Currency = {
  code: string;
  symbol?: string;
};

export type CurrencyCodeType = "EUR" | "USD";

export const CurrencyCodeType = {
  EUR: "EUR" as CurrencyCodeType,
  USD: "USD" as CurrencyCodeType,
};

export const toCurrencyCodeType = (
  currency: string,
): CurrencyCodeType | null => {
  switch (currency) {
    case "EUR":
      return CurrencyCodeType.EUR;
    case "USD":
      return CurrencyCodeType.USD;
    default:
      return null;
  }
};

const DEFAULT_CURRENCY = CurrencyCodeType.EUR;

@Injectable()
export class CurrencyService {
  private readonly currencyChanges$: Observable<Currency>;
  private readonly currencyChangesSubject$ = new BehaviorSubject<FLAG_MAP>(
    DEFAULT_CURRENCY,
  );

  constructor(
  ) {
    this.currencyChanges$ = this.currencyChangesSubject$.pipe(
      map(CurrencyService.toCurrency),
      shareReplay(),
    );
  }

  static toCurrency(string: FLAG_MAP): Currency {
    const currencyCode = string;
    return {
      code: currencyCode,
      symbol: toCurrency(currencyCode),
    };
  }

  getCurrentCurrency(): Currency {
    return CurrencyService.toCurrency(
        this.currencyChangesSubject$.getValue(),
    );
}

  private static getFlag(code: FLAG_MAP): string {
    const flag = FLAG_MAP[code];
    if (!flag) {
      console.error(`No flag found for ${code}`);
    }
    return flag;
  }

  getCurrencyChanges(): Observable<Currency> {
    return this.currencyChanges$;
  }
}

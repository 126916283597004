/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplierBuyerServiceStatusView } from './supplierBuyerServiceStatusView';
import { SupplierBuyerServiceTypeView } from './supplierBuyerServiceTypeView';
import { VatView } from './vatView';


export interface BuyerListItemResponse { 
    buyerCompanyId: number;
    name: string;
    vat: VatView;
    serviceStatus: SupplierBuyerServiceStatusView;
    serviceType: SupplierBuyerServiceTypeView;
}
export namespace BuyerListItemResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CCSupplierStatus = 'Enabled' | 'Disabled' | 'Pending' | 'Inserted';

export const CCSupplierStatus = {
    Enabled: 'Enabled' as CCSupplierStatus,
    Disabled: 'Disabled' as CCSupplierStatus,
    Pending: 'Pending' as CCSupplierStatus,
    Inserted: 'Inserted' as CCSupplierStatus
};


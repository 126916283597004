/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields5 = 'supplierCompanyName' | 'factorSupplierCode' | 'company';

export const SortableFields5 = {
    SupplierCompanyName: 'supplierCompanyName' as SortableFields5,
    FactorSupplierCode: 'factorSupplierCode' as SortableFields5,
    Company: 'company' as SortableFields5
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyListFilters } from './companyListFilters';
import { CompanyResponse } from './companyResponse';
import { PaginationResponse } from './paginationResponse';
import { PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApiSort } from './paginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApiSort';


export interface PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApi { 
    filters: CompanyListFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApiSort;
    page?: Array<CompanyResponse>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PSD2SyncUserErrorGeneric } from './pSD2SyncUserErrorGeneric';
import { PSD2SyncUserErrorProviderUnreachable } from './pSD2SyncUserErrorProviderUnreachable';


/**
 * @type PSD2SyncUserError
 * @export
 */
export type PSD2SyncUserError = PSD2SyncUserErrorGeneric | PSD2SyncUserErrorProviderUnreachable;


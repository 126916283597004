export * from './confirming.service';
import { ConfirmingService } from './confirming.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './dynamicDiscounting.service';
import { DynamicDiscountingService } from './dynamicDiscounting.service';
export * from './earlySalary.service';
import { EarlySalaryService } from './earlySalary.service';
export * from './esg.service';
import { EsgService } from './esg.service';
export * from './learning.service';
import { LearningService } from './learning.service';
export * from './loan.service';
import { LoanService } from './loan.service';
export * from './onboarding.service';
import { OnboardingService } from './onboarding.service';
export * from './pSD2.service';
import { PSD2Service } from './pSD2.service';
export * from './receivableFinancing.service';
import { ReceivableFinancingService } from './receivableFinancing.service';
export * from './registry.service';
import { RegistryService } from './registry.service';
export * from './reverseFactoring.service';
import { ReverseFactoringService } from './reverseFactoring.service';
export * from './signature.service';
import { SignatureService } from './signature.service';
export const APIS = [ConfirmingService, DefaultService, DocumentService, DynamicDiscountingService, EarlySalaryService, EsgService, LearningService, LoanService, OnboardingService, PSD2Service, ReceivableFinancingService, RegistryService, ReverseFactoringService, SignatureService];

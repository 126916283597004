/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveDiscountRequestLineResponse } from './activeDiscountRequestLineResponse';
import { ActiveFinancingBudgetResponse } from './activeFinancingBudgetResponse';


export interface ActiveDiscountRequestBudgetGroupResponse { 
    supplierDivisionId: number;
    divisionName: string;
    divisionVatCountry: string;
    divisionVatCode: string;
    budget: ActiveFinancingBudgetResponse;
    lines?: Array<ActiveDiscountRequestLineResponse>;
}


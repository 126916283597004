/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields6 = 'supplierCompanyName' | 'supplierCompanyVat' | 'activatedBuyers';

export const SortableFields6 = {
    SupplierCompanyName: 'supplierCompanyName' as SortableFields6,
    SupplierCompanyVat: 'supplierCompanyVat' as SortableFields6,
    ActivatedBuyers: 'activatedBuyers' as SortableFields6
};


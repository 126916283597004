/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PSD2CreateOnboardingErrorFailedComplianceCheck } from './pSD2CreateOnboardingErrorFailedComplianceCheck';
import { PSD2CreateOnboardingErrorGeneric } from './pSD2CreateOnboardingErrorGeneric';
import { PSD2CreateOnboardingErrorInvalidCitizenship } from './pSD2CreateOnboardingErrorInvalidCitizenship';
import { PSD2CreateOnboardingErrorProviderUnreachable } from './pSD2CreateOnboardingErrorProviderUnreachable';
import { PSD2CreateOnboardingErrorInvalidFiscalCode } from './pSD2CreateOnboardingErrorInvalidFiscalCode';


/**
 * @type PSD2CreateOnboardingError
 * @export
 */
export type PSD2CreateOnboardingError = PSD2CreateOnboardingErrorFailedComplianceCheck | PSD2CreateOnboardingErrorGeneric | PSD2CreateOnboardingErrorInvalidCitizenship | PSD2CreateOnboardingErrorInvalidFiscalCode | PSD2CreateOnboardingErrorProviderUnreachable;


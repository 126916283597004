/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTargetInt } from './kpiTargetInt';
import { KpiGridBoolean } from './kpiGridBoolean';
import { KpiView } from './kpiView';
import { KpiGridDouble } from './kpiGridDouble';
import { KpiGridInt } from './kpiGridInt';
import { KpiTarget0Int } from './kpiTarget0Int';


/**
 * @type KpiGrid
 * @export
 */
export type KpiGrid = KpiGridBoolean | KpiGridDouble | KpiGridInt;


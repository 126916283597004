/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SortableFields8 = 'amount' | 'buyerName' | 'date' | 'discountAmount' | 'discountRequestDate' | 'documentNumber' | 'dueDate' | 'issueDate' | 'newAmount' | 'newDueDate' | 'status' | 'supplierName';

export const SortableFields8 = {
    Amount: 'amount' as SortableFields8,
    BuyerName: 'buyerName' as SortableFields8,
    Date: 'date' as SortableFields8,
    DiscountAmount: 'discountAmount' as SortableFields8,
    DiscountRequestDate: 'discountRequestDate' as SortableFields8,
    DocumentNumber: 'documentNumber' as SortableFields8,
    DueDate: 'dueDate' as SortableFields8,
    IssueDate: 'issueDate' as SortableFields8,
    NewAmount: 'newAmount' as SortableFields8,
    NewDueDate: 'newDueDate' as SortableFields8,
    Status: 'status' as SortableFields8,
    SupplierName: 'supplierName' as SortableFields8
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReverseInvoiceFilteredReasonView = 'ActivationPending' | 'Blocked' | 'ExpiredDueDate' | 'MissingSettings' | 'OutOfIssueDateRange';

export const ReverseInvoiceFilteredReasonView = {
    ActivationPending: 'ActivationPending' as ReverseInvoiceFilteredReasonView,
    Blocked: 'Blocked' as ReverseInvoiceFilteredReasonView,
    ExpiredDueDate: 'ExpiredDueDate' as ReverseInvoiceFilteredReasonView,
    MissingSettings: 'MissingSettings' as ReverseInvoiceFilteredReasonView,
    OutOfIssueDateRange: 'OutOfIssueDateRange' as ReverseInvoiceFilteredReasonView
};


/**
 * Identity API
 * Identity API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Saml2Attribute { 
    nameId: string;
    nome: string;
    cognome: string;
    codUoAppartenenza: string;
    codUoMissione?: string;
    istitutoMissione?: string;
    profileFinDyn: string;
    email: string;
}


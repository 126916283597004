/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTarget0WriteDouble } from './kpiTarget0WriteDouble';
import { KpiView } from './kpiView';
import { KpiTargetWriteDouble } from './kpiTargetWriteDouble';


export interface KpiGridDoubleWrite { 
    kpi: KpiView;
    target0: KpiTarget0WriteDouble;
    targets?: Array<KpiTargetWriteDouble>;
    kind: string;
}
export namespace KpiGridDoubleWrite {
}



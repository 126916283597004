/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentTypeView = 'AmlQuestionnaire' | 'BalanceLastYear' | 'BalanceSecondToLastYear' | 'BalanceThirdToLastYear' | 'BeneficialOwnerId' | 'BeneficialOwnerFiscalCode' | 'ChamberOfCommerceRegistration' | 'ConstitutiveAct' | 'ConfirmingDelegationGranting' | 'CooperativeControllingShares' | 'CurrentStatute' | 'FiduciarySignificantOwnership' | 'Iban' | 'IndemnityContract' | 'LegalEntityFiscalCode' | 'LegalEntityId' | 'OtherBankDueDiligence' | 'PersonsWithMajoritySharesOfFunds' | 'Poderes' | 'PowersOfSignature' | 'SummaryDocument' | 'SupplierConfirmingContract' | 'TrustAct';

export const DocumentTypeView = {
    AmlQuestionnaire: 'AmlQuestionnaire' as DocumentTypeView,
    BalanceLastYear: 'BalanceLastYear' as DocumentTypeView,
    BalanceSecondToLastYear: 'BalanceSecondToLastYear' as DocumentTypeView,
    BalanceThirdToLastYear: 'BalanceThirdToLastYear' as DocumentTypeView,
    BeneficialOwnerId: 'BeneficialOwnerId' as DocumentTypeView,
    BeneficialOwnerFiscalCode: 'BeneficialOwnerFiscalCode' as DocumentTypeView,
    ChamberOfCommerceRegistration: 'ChamberOfCommerceRegistration' as DocumentTypeView,
    ConstitutiveAct: 'ConstitutiveAct' as DocumentTypeView,
    ConfirmingDelegationGranting: 'ConfirmingDelegationGranting' as DocumentTypeView,
    CooperativeControllingShares: 'CooperativeControllingShares' as DocumentTypeView,
    CurrentStatute: 'CurrentStatute' as DocumentTypeView,
    FiduciarySignificantOwnership: 'FiduciarySignificantOwnership' as DocumentTypeView,
    Iban: 'Iban' as DocumentTypeView,
    IndemnityContract: 'IndemnityContract' as DocumentTypeView,
    LegalEntityFiscalCode: 'LegalEntityFiscalCode' as DocumentTypeView,
    LegalEntityId: 'LegalEntityId' as DocumentTypeView,
    OtherBankDueDiligence: 'OtherBankDueDiligence' as DocumentTypeView,
    PersonsWithMajoritySharesOfFunds: 'PersonsWithMajoritySharesOfFunds' as DocumentTypeView,
    Poderes: 'Poderes' as DocumentTypeView,
    PowersOfSignature: 'PowersOfSignature' as DocumentTypeView,
    SummaryDocument: 'SummaryDocument' as DocumentTypeView,
    SupplierConfirmingContract: 'SupplierConfirmingContract' as DocumentTypeView,
    TrustAct: 'TrustAct' as DocumentTypeView
};


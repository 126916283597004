/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EsgScoreApi } from './esgScoreApi';
import { EsgProviderApi } from './esgProviderApi';
import { PaymentMethodApi } from './paymentMethodApi';
import { EsgManagerApi } from './esgManagerApi';
import { CompanyEsgStatusApi } from './companyEsgStatusApi';
import { EsgRequesterApi } from './esgRequesterApi';


export interface EsgRequestApi { 
    requestId: number;
    companyName: string;
    score?: EsgScoreApi;
    status: CompanyEsgStatusApi;
    providerId: number;
    provider: EsgProviderApi;
    manager: EsgManagerApi;
    paymentMethod: PaymentMethodApi;
    requester: EsgRequesterApi;
}
export namespace EsgRequestApi {
}



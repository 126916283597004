/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ReverseBuyerSortableFieldsView = 'BuyerDivision' | 'DocumentNumber' | 'DueDate' | 'FinancialInstitution' | 'IssueDate' | 'SupplierName';

export const ReverseBuyerSortableFieldsView = {
    BuyerDivision: 'BuyerDivision' as ReverseBuyerSortableFieldsView,
    DocumentNumber: 'DocumentNumber' as ReverseBuyerSortableFieldsView,
    DueDate: 'DueDate' as ReverseBuyerSortableFieldsView,
    FinancialInstitution: 'FinancialInstitution' as ReverseBuyerSortableFieldsView,
    IssueDate: 'IssueDate' as ReverseBuyerSortableFieldsView,
    SupplierName: 'SupplierName' as ReverseBuyerSortableFieldsView
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseFactorBuyersListItemResponse } from './reverseFactorBuyersListItemResponse';
import { PaginationResponse } from './paginationResponse';
import { BuyerFilters1 } from './buyerFilters1';
import { PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFieldsSort } from './paginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFieldsSort';


export interface PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFields { 
    filters: BuyerFilters1;
    pagination: PaginationResponse;
    sort?: PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFieldsSort;
    page?: Array<ReverseFactorBuyersListItemResponse>;
}


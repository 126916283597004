/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerFactorConfigurationResponse } from './buyerFactorConfigurationResponse';
import { ActiveStatusView } from './activeStatusView';


export interface BuyerActiveFinancingStatusResponse { 
    status: ActiveStatusView;
    buyerCompanyName: string;
    buyerCompanyVat: string;
    configurations?: Array<BuyerFactorConfigurationResponse>;
}
export namespace BuyerActiveFinancingStatusResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type LearningLessonTypeView = 'Audio' | 'Carousel' | 'Quiz' | 'Text' | 'Video';

export const LearningLessonTypeView = {
    Audio: 'Audio' as LearningLessonTypeView,
    Carousel: 'Carousel' as LearningLessonTypeView,
    Quiz: 'Quiz' as LearningLessonTypeView,
    Text: 'Text' as LearningLessonTypeView,
    Video: 'Video' as LearningLessonTypeView
};


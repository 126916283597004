/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FilteredReasonView1 = 'BuyerBudgetNotEnough' | 'Expired' | 'MaximumCreditDays' | 'ServiceNotEnabled';

export const FilteredReasonView1 = {
    BuyerBudgetNotEnough: 'BuyerBudgetNotEnough' as FilteredReasonView1,
    Expired: 'Expired' as FilteredReasonView1,
    MaximumCreditDays: 'MaximumCreditDays' as FilteredReasonView1,
    ServiceNotEnabled: 'ServiceNotEnabled' as FilteredReasonView1
};


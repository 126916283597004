/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FinancialInfoView1 } from './financialInfoView1';
import { DivisionInfoView } from './divisionInfoView';
import { ReverseInfoView } from './reverseInfoView';
import { ConfirmingInfoView } from './confirmingInfoView';
import { DynamicDiscountingInfoView } from './dynamicDiscountingInfoView';
import { EsgInfoView } from './esgInfoView';


export interface SupplierListItem { 
    buyerId: number;
    supplierId: number;
    supplierCompanyId: number;
    supplierName: string;
    supplierVat: string;
    divisionsInfo?: Array<DivisionInfoView>;
    confirmingInfo: ConfirmingInfoView;
    dynamicDiscountingInfo: DynamicDiscountingInfoView;
    esgInfo: EsgInfoView;
    reverseInfo: ReverseInfoView;
    financialInfo?: FinancialInfoView1;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnboardingServiceView } from './onboardingServiceView';
import { OnboardingDocumentResponse1 } from './onboardingDocumentResponse1';


export interface FactorOnboardingResponse { 
    factorName: string;
    service: OnboardingServiceView;
    uploadedByCustomerDocuments: number;
    validatedDocuments: number;
    totalDocuments: number;
    documents?: Array<OnboardingDocumentResponse1>;
}
export namespace FactorOnboardingResponse {
}



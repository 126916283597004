/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ActiveBuyersSortableFields } from '../model/activeBuyersSortableFields';
// @ts-ignore
import { ActiveDashboardResponse } from '../model/activeDashboardResponse';
// @ts-ignore
import { ActiveDiscountCheckoutResponse } from '../model/activeDiscountCheckoutResponse';
// @ts-ignore
import { ActiveDiscountDetailResponse } from '../model/activeDiscountDetailResponse';
// @ts-ignore
import { ActiveDiscountDetailResponse1 } from '../model/activeDiscountDetailResponse1';
// @ts-ignore
import { ActiveDiscountRequestSelectionResponse } from '../model/activeDiscountRequestSelectionResponse';
// @ts-ignore
import { ActiveDiscountStatusApi } from '../model/activeDiscountStatusApi';
// @ts-ignore
import { ActiveFactorsResponse } from '../model/activeFactorsResponse';
// @ts-ignore
import { ActiveInvoicesSelectionsRequest } from '../model/activeInvoicesSelectionsRequest';
// @ts-ignore
import { ActiveInvoicesSummaryRequest } from '../model/activeInvoicesSummaryRequest';
// @ts-ignore
import { ActiveInvoicesSummaryResponse } from '../model/activeInvoicesSummaryResponse';
// @ts-ignore
import { ActiveSupplierFactorRelationsResponse } from '../model/activeSupplierFactorRelationsResponse';
// @ts-ignore
import { AutocompleteResponseApiStringAutocompleteType } from '../model/autocompleteResponseApiStringAutocompleteType';
// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { BadRequestErrorBudgetCheck1 } from '../model/badRequestErrorBudgetCheck1';
// @ts-ignore
import { BuyerActivationRequest1 } from '../model/buyerActivationRequest1';
// @ts-ignore
import { BuyerActiveFinancingStatusResponse } from '../model/buyerActiveFinancingStatusResponse';
// @ts-ignore
import { CassettoFiscaleCredentialRequest } from '../model/cassettoFiscaleCredentialRequest';
// @ts-ignore
import { CassettoFiscaleCredentialResponse } from '../model/cassettoFiscaleCredentialResponse';
// @ts-ignore
import { CassettoFiscaleStatusResponse } from '../model/cassettoFiscaleStatusResponse';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { DateAggregationApi } from '../model/dateAggregationApi';
// @ts-ignore
import { DocumentsResponse } from '../model/documentsResponse';
// @ts-ignore
import { FactorActiveDiscountStatusUpdateInput } from '../model/factorActiveDiscountStatusUpdateInput';
// @ts-ignore
import { FactorActiveDiscountsSummaryResponse } from '../model/factorActiveDiscountsSummaryResponse';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { InvoiceContentResponseActiveDiscountResponseActiveDiscountRequestFiltersResponse } from '../model/invoiceContentResponseActiveDiscountResponseActiveDiscountRequestFiltersResponse';
// @ts-ignore
import { InvoiceContentResponseActiveDiscountResponseFactorActiveDiscountRequestFiltersResponse } from '../model/invoiceContentResponseActiveDiscountResponseFactorActiveDiscountRequestFiltersResponse';
// @ts-ignore
import { InvoiceContentResponseActiveInvoiceResponseSupplierActiveInvoiceFiltersResponse } from '../model/invoiceContentResponseActiveInvoiceResponseSupplierActiveInvoiceFiltersResponse';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFields } from '../model/paginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFields';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { SupplierInvoiceStatus } from '../model/supplierInvoiceStatus';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ReceivableFinancingService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1CassettoFiscaleCredentials(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CassettoFiscaleCredentialResponse>;
    public getActiveFinancingApiV1CassettoFiscaleCredentials(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CassettoFiscaleCredentialResponse>>;
    public getActiveFinancingApiV1CassettoFiscaleCredentials(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CassettoFiscaleCredentialResponse>>;
    public getActiveFinancingApiV1CassettoFiscaleCredentials(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/cassetto-fiscale/credentials`;
        return this.httpClient.request<CassettoFiscaleCredentialResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1CassettoFiscaleCredentialsStatus(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CassettoFiscaleStatusResponse>;
    public getActiveFinancingApiV1CassettoFiscaleCredentialsStatus(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CassettoFiscaleStatusResponse>>;
    public getActiveFinancingApiV1CassettoFiscaleCredentialsStatus(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CassettoFiscaleStatusResponse>>;
    public getActiveFinancingApiV1CassettoFiscaleCredentialsStatus(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/cassetto-fiscale/credentials-status`;
        return this.httpClient.request<CassettoFiscaleStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param startDate 
     * @param endDate 
     * @param currency 
     * @param supplierDivisionId 
     * @param status 
     * @param sort 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscountIds(factorId: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountIds(factorId: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountIds(factorId: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountIds(factorId: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountIds.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountIds.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountIds.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (supplierDivisionId !== undefined && supplierDivisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierDivisionId, 'supplierDivisionId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param start 
     * @param max 
     * @param startDate 
     * @param endDate 
     * @param currency 
     * @param supplierDivisionId 
     * @param status 
     * @param sort 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscounts(factorId: number, start: number, max: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseActiveDiscountResponseFactorActiveDiscountRequestFiltersResponse>;
    public getActiveFinancingApiV1FactorsFactoridDiscounts(factorId: number, start: number, max: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseActiveDiscountResponseFactorActiveDiscountRequestFiltersResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscounts(factorId: number, start: number, max: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseActiveDiscountResponseFactorActiveDiscountRequestFiltersResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscounts(factorId: number, start: number, max: number, startDate: string, endDate: string, currency: string, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscounts.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscounts.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscounts.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscounts.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscounts.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (supplierDivisionId !== undefined && supplierDivisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierDivisionId, 'supplierDivisionId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts`;
        return this.httpClient.request<InvoiceContentResponseActiveDiscountResponseFactorActiveDiscountRequestFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscountsAutocomplete(factorId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsAutocomplete(factorId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsAutocomplete(factorId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsAutocomplete(factorId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param startDate 
     * @param endDate 
     * @param currency 
     * @param format 
     * @param supplierDivisionId 
     * @param status 
     * @param sort 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscountsReports(factorId: number, startDate: string, endDate: string, currency: string, format: ReportFormat, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsReports(factorId: number, startDate: string, endDate: string, currency: string, format: ReportFormat, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsReports(factorId: number, startDate: string, endDate: string, currency: string, format: ReportFormat, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsReports(factorId: number, startDate: string, endDate: string, currency: string, format: ReportFormat, supplierDivisionId?: number, status?: ActiveDiscountStatusApi, sort?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsReports.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsReports.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsReports.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsReports.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (supplierDivisionId !== undefined && supplierDivisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierDivisionId, 'supplierDivisionId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/reports`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestid(factorId: number, requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveDiscountDetailResponse>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestid(factorId: number, requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveDiscountDetailResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestid(factorId: number, requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveDiscountDetailResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestid(factorId: number, requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsRequestid.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsRequestid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ActiveDiscountDetailResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocuments(factorId: number, requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DocumentsResponse>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocuments(factorId: number, requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DocumentsResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocuments(factorId: number, requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DocumentsResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocuments(factorId: number, requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocuments.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocuments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents`;
        return this.httpClient.request<DocumentsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param requestId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocumentsDocumentid(factorId: number, requestId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocumentsDocumentid.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsRequestidDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param discountIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1FactorsFactoridDiscountsSummary(factorId: number, discountIds: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<FactorActiveDiscountsSummaryResponse>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsSummary(factorId: number, discountIds: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<FactorActiveDiscountsSummaryResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsSummary(factorId: number, discountIds: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<FactorActiveDiscountsSummaryResponse>>;
    public getActiveFinancingApiV1FactorsFactoridDiscountsSummary(factorId: number, discountIds: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsSummary.');
        }
        if (discountIds === null || discountIds === undefined) {
            throw new Error('Required parameter discountIds was null or undefined when calling getActiveFinancingApiV1FactorsFactoridDiscountsSummary.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (discountIds) {
            discountIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'discountIds');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/summary`;
        return this.httpClient.request<FactorActiveDiscountsSummaryResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridActiveFactorRelations(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveSupplierFactorRelationsResponse>;
    public getActiveFinancingApiV1SuppliersSupplieridActiveFactorRelations(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveSupplierFactorRelationsResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridActiveFactorRelations(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveSupplierFactorRelationsResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridActiveFactorRelations(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridActiveFactorRelations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/active-factor-relations`;
        return this.httpClient.request<ActiveSupplierFactorRelationsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param max 
     * @param start 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridBuyers(supplierId: number, max: number, start: number, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFields>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyers(supplierId: number, max: number, start: number, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFields>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyers(supplierId: number, max: number, start: number, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFields>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyers(supplierId: number, max: number, start: number, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyers.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyers.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<PaginatedResponseFiltersActiveBuyerResponseActiveBuyersSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridBuyersAutocomplete(supplierId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersAutocomplete(supplierId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersAutocomplete(supplierId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersAutocomplete(supplierId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BuyerActiveFinancingStatusResponse>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BuyerActiveFinancingStatusResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BuyerActiveFinancingStatusResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus(supplierId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/status`;
        return this.httpClient.request<BuyerActiveFinancingStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param max 
     * @param start 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridBuyersReports(supplierId: number, max: number, start: number, format: ReportFormat, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersReports(supplierId: number, max: number, start: number, format: ReportFormat, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersReports(supplierId: number, max: number, start: number, format: ReportFormat, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getActiveFinancingApiV1SuppliersSupplieridBuyersReports(supplierId: number, max: number, start: number, format: ReportFormat, sortField?: ActiveBuyersSortableFields, sortOrder?: SortOrder, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersReports.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersReports.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersReports.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridBuyersReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/reports`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param accumulate 
     * @param currency 
     * @param dateAggregation 
     * @param endDate 
     * @param startDate 
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridDashboard(supplierId: number, accumulate: boolean, currency: string, dateAggregation: DateAggregationApi, endDate: string, startDate: string, buyerId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveDashboardResponse>;
    public getActiveFinancingApiV1SuppliersSupplieridDashboard(supplierId: number, accumulate: boolean, currency: string, dateAggregation: DateAggregationApi, endDate: string, startDate: string, buyerId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveDashboardResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridDashboard(supplierId: number, accumulate: boolean, currency: string, dateAggregation: DateAggregationApi, endDate: string, startDate: string, buyerId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveDashboardResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridDashboard(supplierId: number, accumulate: boolean, currency: string, dateAggregation: DateAggregationApi, endDate: string, startDate: string, buyerId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDashboard.');
        }
        if (accumulate === null || accumulate === undefined) {
            throw new Error('Required parameter accumulate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDashboard.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDashboard.');
        }
        if (dateAggregation === null || dateAggregation === undefined) {
            throw new Error('Required parameter dateAggregation was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDashboard.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDashboard.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDashboard.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accumulate !== undefined && accumulate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accumulate, 'accumulate');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (dateAggregation !== undefined && dateAggregation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateAggregation, 'dateAggregation');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (buyerId !== undefined && buyerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerId, 'buyerId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dashboard`;
        return this.httpClient.request<ActiveDashboardResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsId(supplierId: number, id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveDiscountDetailResponse1>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsId(supplierId: number, id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveDiscountDetailResponse1>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsId(supplierId: number, id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveDiscountDetailResponse1>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsId(supplierId: number, id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsId.');
        }
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsId.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ActiveDiscountDetailResponse1>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<DocumentsResponse>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<DocumentsResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<DocumentsResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments(supplierId: number, requestId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocuments.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents`;
        return this.httpClient.request<DocumentsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param requestId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Blob>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Blob>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Blob>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid(supplierId: number, requestId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid.');
        }
        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountRequestsRequestidDocumentsDocumentid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-requests/${this.configuration.encodeParam({name: "requestId", value: requestId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/documents/${this.configuration.encodeParam({name: "documentId", value: documentId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param currency 
     * @param buyerCompanyId 
     * @param factorId 
     * @param status 
     * @param sort 
     * @param discountIds 
     * @param discountOrderNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridDiscounts(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseActiveDiscountResponseActiveDiscountRequestFiltersResponse>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscounts(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseActiveDiscountResponseActiveDiscountRequestFiltersResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscounts(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseActiveDiscountResponseActiveDiscountRequestFiltersResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscounts(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscounts.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscounts.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscounts.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscounts.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscounts.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscounts.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (discountIds) {
            discountIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'discountIds');
            })
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (discountOrderNumber !== undefined && discountOrderNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>discountOrderNumber, 'discountOrderNumber');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts`;
        return this.httpClient.request<InvoiceContentResponseActiveDiscountResponseActiveDiscountRequestFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsAutocomplete(supplierId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param max 
     * @param start 
     * @param startDate 
     * @param endDate 
     * @param currency 
     * @param format 
     * @param buyerCompanyId 
     * @param factorId 
     * @param status 
     * @param sort 
     * @param discountIds 
     * @param discountOrderNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsReports(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, format: ReportFormat, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsReports(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, format: ReportFormat, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsReports(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, format: ReportFormat, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getActiveFinancingApiV1SuppliersSupplieridDiscountsReports(supplierId: number, max: number, start: number, startDate: string, endDate: string, currency: string, format: ReportFormat, buyerCompanyId?: number, factorId?: number, status?: ActiveDiscountStatusApi, sort?: string, discountIds?: Array<number>, discountOrderNumber?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsReports.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsReports.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsReports.');
        }
        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsReports.');
        }
        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsReports.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsReports.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridDiscountsReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'endDate');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (discountIds) {
            discountIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'discountIds');
            })
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (discountOrderNumber !== undefined && discountOrderNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>discountOrderNumber, 'discountOrderNumber');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discounts/reports`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridFactors(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveFactorsResponse>;
    public getActiveFinancingApiV1SuppliersSupplieridFactors(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveFactorsResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridFactors(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveFactorsResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridFactors(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/factors`;
        return this.httpClient.request<ActiveFactorsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param currency 
     * @param endDueDate 
     * @param max 
     * @param start 
     * @param startDueDate 
     * @param buyerCompanyId 
     * @param supplierDivisionId 
     * @param factorId 
     * @param invoiceIds 
     * @param sort 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, max: number, start: number, startDueDate: string, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, sort?: string, status?: SupplierInvoiceStatus, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseActiveInvoiceResponseSupplierActiveInvoiceFiltersResponse>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, max: number, start: number, startDueDate: string, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, sort?: string, status?: SupplierInvoiceStatus, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseActiveInvoiceResponseSupplierActiveInvoiceFiltersResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, max: number, start: number, startDueDate: string, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, sort?: string, status?: SupplierInvoiceStatus, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseActiveInvoiceResponseSupplierActiveInvoiceFiltersResponse>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoices(supplierId: number, currency: string, endDueDate: string, max: number, start: number, startDueDate: string, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, sort?: string, status?: SupplierInvoiceStatus, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoices.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoices.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoices.');
        }
        if (max === null || max === undefined) {
            throw new Error('Required parameter max was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoices.');
        }
        if (start === null || start === undefined) {
            throw new Error('Required parameter start was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoices.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoices.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (supplierDivisionId !== undefined && supplierDivisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierDivisionId, 'supplierDivisionId');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices`;
        return this.httpClient.request<InvoiceContentResponseActiveInvoiceResponseSupplierActiveInvoiceFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesAutocomplete(supplierId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param currency 
     * @param endDueDate 
     * @param startDueDate 
     * @param status 
     * @param buyerCompanyId 
     * @param supplierDivisionId 
     * @param factorId 
     * @param invoiceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, startDueDate: string, status: SupplierInvoiceStatus, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, startDueDate: string, status: SupplierInvoiceStatus, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, startDueDate: string, status: SupplierInvoiceStatus, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds(supplierId: number, currency: string, endDueDate: string, startDueDate: string, status: SupplierInvoiceStatus, buyerCompanyId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (supplierDivisionId !== undefined && supplierDivisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierDivisionId, 'supplierDivisionId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/invoice-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param currency 
     * @param endDueDate 
     * @param startDueDate 
     * @param format 
     * @param buyerId 
     * @param supplierDivisionId 
     * @param factorId 
     * @param invoiceIds 
     * @param max 
     * @param sort 
     * @param start 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesReports(supplierId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, status?: SupplierInvoiceStatus, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesReports(supplierId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, status?: SupplierInvoiceStatus, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesReports(supplierId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, status?: SupplierInvoiceStatus, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getActiveFinancingApiV1SuppliersSupplieridInvoicesReports(supplierId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerId?: number, supplierDivisionId?: number, factorId?: number, invoiceIds?: Array<number>, max?: number, sort?: string, start?: number, status?: SupplierInvoiceStatus, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesReports.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesReports.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesReports.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesReports.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getActiveFinancingApiV1SuppliersSupplieridInvoicesReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerId !== undefined && buyerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerId, 'buyerId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (supplierDivisionId !== undefined && supplierDivisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierDivisionId, 'supplierDivisionId');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (start !== undefined && start !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>start, 'start');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/reports`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param cassettoFiscaleCredentialRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActiveFinancingApiV1CassettoFiscaleCredentials(cassettoFiscaleCredentialRequest: CassettoFiscaleCredentialRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postActiveFinancingApiV1CassettoFiscaleCredentials(cassettoFiscaleCredentialRequest: CassettoFiscaleCredentialRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postActiveFinancingApiV1CassettoFiscaleCredentials(cassettoFiscaleCredentialRequest: CassettoFiscaleCredentialRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postActiveFinancingApiV1CassettoFiscaleCredentials(cassettoFiscaleCredentialRequest: CassettoFiscaleCredentialRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (cassettoFiscaleCredentialRequest === null || cassettoFiscaleCredentialRequest === undefined) {
            throw new Error('Required parameter cassettoFiscaleCredentialRequest was null or undefined when calling postActiveFinancingApiV1CassettoFiscaleCredentials.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/cassetto-fiscale/credentials`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: cassettoFiscaleCredentialRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidInterestEnable(supplierId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidInterestEnable(supplierId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidInterestEnable(supplierId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidInterestEnable(supplierId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidInterestEnable.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidInterestEnable.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/interest-enable`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActiveFinancingApiV1SuppliersSupplieridDiscountCheckoutsCheckout(supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveDiscountCheckoutResponse>;
    public postActiveFinancingApiV1SuppliersSupplieridDiscountCheckoutsCheckout(supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveDiscountCheckoutResponse>>;
    public postActiveFinancingApiV1SuppliersSupplieridDiscountCheckoutsCheckout(supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveDiscountCheckoutResponse>>;
    public postActiveFinancingApiV1SuppliersSupplieridDiscountCheckoutsCheckout(supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridDiscountCheckoutsCheckout.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-ONE-TIME-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth3) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth3');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-OTP', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-checkouts/checkout`;
        return this.httpClient.request<ActiveDiscountCheckoutResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param divisionId 
     * @param factorId 
     * @param factorSupplierCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation(supplierId: number, divisionId: number, factorId: number, factorSupplierCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation(supplierId: number, divisionId: number, factorId: number, factorSupplierCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation(supplierId: number, divisionId: number, factorId: number, factorSupplierCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation(supplierId: number, divisionId: number, factorId: number, factorSupplierCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation.');
        }
        if (divisionId === null || divisionId === undefined) {
            throw new Error('Required parameter divisionId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation.');
        }
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation.');
        }
        if (factorSupplierCode === null || factorSupplierCode === undefined) {
            throw new Error('Required parameter factorSupplierCode was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridDivisionsDivisionidActivation.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (factorSupplierCode !== undefined && factorSupplierCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorSupplierCode, 'factorSupplierCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/divisions/${this.configuration.encodeParam({name: "divisionId", value: divisionId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param activeInvoicesSelectionsRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, activeInvoicesSelectionsRequest: ActiveInvoicesSelectionsRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveDiscountRequestSelectionResponse>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, activeInvoicesSelectionsRequest: ActiveInvoicesSelectionsRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveDiscountRequestSelectionResponse>>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, activeInvoicesSelectionsRequest: ActiveInvoicesSelectionsRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveDiscountRequestSelectionResponse>>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection(supplierId: number, activeInvoicesSelectionsRequest: ActiveInvoicesSelectionsRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection.');
        }
        if (activeInvoicesSelectionsRequest === null || activeInvoicesSelectionsRequest === undefined) {
            throw new Error('Required parameter activeInvoicesSelectionsRequest was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridInvoicesDiscountRequestsSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/discount-requests-selection`;
        return this.httpClient.request<ActiveDiscountRequestSelectionResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: activeInvoicesSelectionsRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceidInterestEnable(supplierId: number, invoiceId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceidInterestEnable(supplierId: number, invoiceId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceidInterestEnable(supplierId: number, invoiceId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceidInterestEnable(supplierId: number, invoiceId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceidInterestEnable.');
        }
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridInvoicesInvoiceidInterestEnable.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/${this.configuration.encodeParam({name: "invoiceId", value: invoiceId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/interest-enable`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param activeInvoicesSummaryRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, activeInvoicesSummaryRequest: ActiveInvoicesSummaryRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ActiveInvoicesSummaryResponse>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, activeInvoicesSummaryRequest: ActiveInvoicesSummaryRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ActiveInvoicesSummaryResponse>>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, activeInvoicesSummaryRequest: ActiveInvoicesSummaryRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ActiveInvoicesSummaryResponse>>;
    public postActiveFinancingApiV1SuppliersSupplieridInvoicesSummary(supplierId: number, activeInvoicesSummaryRequest: ActiveInvoicesSummaryRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridInvoicesSummary.');
        }
        if (activeInvoicesSummaryRequest === null || activeInvoicesSummaryRequest === undefined) {
            throw new Error('Required parameter activeInvoicesSummaryRequest was null or undefined when calling postActiveFinancingApiV1SuppliersSupplieridInvoicesSummary.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/summary`;
        return this.httpClient.request<ActiveInvoicesSummaryResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: activeInvoicesSummaryRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param factorActiveDiscountStatusUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putActiveFinancingApiV1FactorsFactoridDiscountStatuses(factorId: number, factorActiveDiscountStatusUpdateInput: FactorActiveDiscountStatusUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putActiveFinancingApiV1FactorsFactoridDiscountStatuses(factorId: number, factorActiveDiscountStatusUpdateInput: FactorActiveDiscountStatusUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putActiveFinancingApiV1FactorsFactoridDiscountStatuses(factorId: number, factorActiveDiscountStatusUpdateInput: FactorActiveDiscountStatusUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putActiveFinancingApiV1FactorsFactoridDiscountStatuses(factorId: number, factorActiveDiscountStatusUpdateInput: FactorActiveDiscountStatusUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putActiveFinancingApiV1FactorsFactoridDiscountStatuses.');
        }
        if (factorActiveDiscountStatusUpdateInput === null || factorActiveDiscountStatusUpdateInput === undefined) {
            throw new Error('Required parameter factorActiveDiscountStatusUpdateInput was null or undefined when calling putActiveFinancingApiV1FactorsFactoridDiscountStatuses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-statuses`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: factorActiveDiscountStatusUpdateInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param supplierId 
     * @param buyerCompanyId 
     * @param buyerActivationRequest1 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidActivation(supplierId: number, buyerCompanyId: number, buyerActivationRequest1: BuyerActivationRequest1, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidActivation(supplierId: number, buyerCompanyId: number, buyerActivationRequest1: BuyerActivationRequest1, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidActivation(supplierId: number, buyerCompanyId: number, buyerActivationRequest1: BuyerActivationRequest1, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidActivation(supplierId: number, buyerCompanyId: number, buyerActivationRequest1: BuyerActivationRequest1, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling putActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidActivation.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling putActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidActivation.');
        }
        if (buyerActivationRequest1 === null || buyerActivationRequest1 === undefined) {
            throw new Error('Required parameter buyerActivationRequest1 was null or undefined when calling putActiveFinancingApiV1SuppliersSupplieridBuyersBuyercompanyidActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/active-financing/api/v1/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: buyerActivationRequest1,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

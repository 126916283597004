import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { IonicStorageService } from 'src/shared/storages/ionic-storage.service';

@Injectable()
export class DeepLinkService {
    domain = environment.apiBasePath;

    constructor(
        private navCtrl: NavController,
        private storageService: IonicStorageService,
    ) {}

    getRoute(url: string) {
        const path = url.split(this.domain).pop();

        if (path) {
            if (path.includes('app/reports/')) {
                this.onReportDownload(path);
            } else if (path.includes('app/customer/sign-documents')) {
                if (this.storageService.signOnCheckout) {
                    this.navCtrl.navigateForward([
                        `/private/tabs/confirming-invoices/sign`,
                    ]);
                    this.storageService.setCheckoutSign(false);
                } else {
                    this.navCtrl.navigateForward([
                        '/private/tabs/settings/documents',
                    ]);
                }
            } else if (
                path.includes('app/customer/active/confirming/discounts')
            ) {
                this.navCtrl.navigateForward([
                    `/private/tabs/confirming-requests`,
                ]);
            } else {
                this.navCtrl.navigateRoot([`/private`], { replaceUrl: true });
            }
        } else {
            this.navCtrl.navigateRoot([`/private`], { replaceUrl: true });
        }
    }

    onReportDownload(path: string) {
        const uuid = path.split('app/reports/').pop();

        const navigationExtras: NavigationExtras = {
            state: {
                uuid: uuid,
            },
        };

        this.navCtrl.navigateForward(
            ['/private/tabs/app/reports'],
            navigationExtras,
        );
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerSupplierServiceStatusView } from './buyerSupplierServiceStatusView';
import { CompanyEsgStatusApi } from './companyEsgStatusApi';


export interface EsgInfoView { 
    status: BuyerSupplierServiceStatusView;
    serviceStatus: BuyerSupplierServiceStatusView;
    lastRequestStatus: CompanyEsgStatusApi;
    scoreExpirationDate?: string;
}
export namespace EsgInfoView {
}



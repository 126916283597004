/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DDServiceStatus1 } from './dDServiceStatus1';
import { SupplierActivationCluster1 } from './supplierActivationCluster1';
import { SupplierActivationDivision2 } from './supplierActivationDivision2';
import { CustomParametersView } from './customParametersView';
import { CompanyView } from './companyView';


export interface SupplierCompanyActivationInfoResponse { 
    supplierCompany: CompanyView;
    selectedCluster?: SupplierActivationCluster1;
    clusters?: Array<SupplierActivationCluster1>;
    customParameter: CustomParametersView;
    divisions?: Array<SupplierActivationDivision2>;
    status: DDServiceStatus1;
}
export namespace SupplierCompanyActivationInfoResponse {
}



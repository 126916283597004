/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveDiscountRequestBudgetGroupResponse } from './activeDiscountRequestBudgetGroupResponse';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface ActiveDiscountRequestFactorGroupResponse { 
    factorId: number;
    factorName: string;
    groups?: Array<ActiveDiscountRequestBudgetGroupResponse>;
    integrationType: FactorIntegrationTypeView;
}
export namespace ActiveDiscountRequestFactorGroupResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplierActivationDivision1 } from './supplierActivationDivision1';
import { DDServiceStatus } from './dDServiceStatus';
import { CustomParametersView } from './customParametersView';
import { SupplierActivationCluster } from './supplierActivationCluster';
import { CompanyView } from './companyView';


export interface SupplierActivationInfoResponse { 
    supplierCompanies: Array<CompanyView>;
    selectedCluster?: SupplierActivationCluster;
    clusters?: Array<SupplierActivationCluster>;
    customParameter: CustomParametersView;
    divisions?: Array<SupplierActivationDivision1>;
    status: DDServiceStatus;
}
export namespace SupplierActivationInfoResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AllocatedService } from './allocatedService';
import { ConfirmingServiceStatusView } from './confirmingServiceStatusView';


export interface BuyerDivision { 
    allocatedService?: AllocatedService;
    code: string;
    name: string;
    status: ConfirmingServiceStatusView;
}
export namespace BuyerDivision {
}



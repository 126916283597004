/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { BuyerListItemResponse } from '../model/buyerListItemResponse';
// @ts-ignore
import { BuyerSuppliersDetailListUsersResponse } from '../model/buyerSuppliersDetailListUsersResponse';
// @ts-ignore
import { BuyersSortableFields } from '../model/buyersSortableFields';
// @ts-ignore
import { CompanyView } from '../model/companyView';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { FactorDiscountRateTypesRequest } from '../model/factorDiscountRateTypesRequest';
// @ts-ignore
import { FactorDiscountRateTypesResponse } from '../model/factorDiscountRateTypesResponse';
// @ts-ignore
import { FactorServiceListResponse } from '../model/factorServiceListResponse';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { OverviewResponse } from '../model/overviewResponse';
// @ts-ignore
import { OverviewServiceTypeView } from '../model/overviewServiceTypeView';
// @ts-ignore
import { PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFields } from '../model/paginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFields';
// @ts-ignore
import { PaginatedResponseSupplierFiltersSupplierListItemSortableFields } from '../model/paginatedResponseSupplierFiltersSupplierListItemSortableFields';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { ServiceListResponse } from '../model/serviceListResponse';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { SupplierBuyerServiceTypeView } from '../model/supplierBuyerServiceTypeView';
// @ts-ignore
import { SupplierListPostRequest } from '../model/supplierListPostRequest';
// @ts-ignore
import { SupplierListReportPostRequest } from '../model/supplierListReportPostRequest';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class RegistryService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param accountId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param serviceType 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2AccountsAccountidBuyers(accountId: number, paginationStart: number, paginationMax: number, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFields>;
    public getRegistryApiV2AccountsAccountidBuyers(accountId: number, paginationStart: number, paginationMax: number, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFields>>;
    public getRegistryApiV2AccountsAccountidBuyers(accountId: number, paginationStart: number, paginationMax: number, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFields>>;
    public getRegistryApiV2AccountsAccountidBuyers(accountId: number, paginationStart: number, paginationMax: number, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getRegistryApiV2AccountsAccountidBuyers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getRegistryApiV2AccountsAccountidBuyers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getRegistryApiV2AccountsAccountidBuyers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (serviceType !== undefined && serviceType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>serviceType, 'serviceType');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<PaginatedResponseBuyerListFiltersResponseBuyerListItemResponseBuyersSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2AccountsAccountidBuyersBuyercompanyid(accountId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BuyerListItemResponse>;
    public getRegistryApiV2AccountsAccountidBuyersBuyercompanyid(accountId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BuyerListItemResponse>>;
    public getRegistryApiV2AccountsAccountidBuyersBuyercompanyid(accountId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BuyerListItemResponse>>;
    public getRegistryApiV2AccountsAccountidBuyersBuyercompanyid(accountId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getRegistryApiV2AccountsAccountidBuyersBuyercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getRegistryApiV2AccountsAccountidBuyersBuyercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<BuyerListItemResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param serviceType 
     * @param search 
     * @param paginationStart 
     * @param paginationMax 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2AccountsAccountidBuyersReport(accountId: number, format: ReportFormat, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, paginationStart?: number, paginationMax?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getRegistryApiV2AccountsAccountidBuyersReport(accountId: number, format: ReportFormat, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, paginationStart?: number, paginationMax?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getRegistryApiV2AccountsAccountidBuyersReport(accountId: number, format: ReportFormat, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, paginationStart?: number, paginationMax?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getRegistryApiV2AccountsAccountidBuyersReport(accountId: number, format: ReportFormat, sortField?: BuyersSortableFields, sortOrder?: SortOrder, serviceType?: SupplierBuyerServiceTypeView, search?: string, paginationStart?: number, paginationMax?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getRegistryApiV2AccountsAccountidBuyersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getRegistryApiV2AccountsAccountidBuyersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (serviceType !== undefined && serviceType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>serviceType, 'serviceType');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2AccountsAccountidFactorServices(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<FactorServiceListResponse>>;
    public getRegistryApiV2AccountsAccountidFactorServices(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<FactorServiceListResponse>>>;
    public getRegistryApiV2AccountsAccountidFactorServices(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<FactorServiceListResponse>>>;
    public getRegistryApiV2AccountsAccountidFactorServices(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getRegistryApiV2AccountsAccountidFactorServices.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/factor-services`;
        return this.httpClient.request<Array<FactorServiceListResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2AccountsAccountidServices(accountId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<ServiceListResponse>>;
    public getRegistryApiV2AccountsAccountidServices(accountId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<ServiceListResponse>>>;
    public getRegistryApiV2AccountsAccountidServices(accountId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<ServiceListResponse>>>;
    public getRegistryApiV2AccountsAccountidServices(accountId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getRegistryApiV2AccountsAccountidServices.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/services`;
        return this.httpClient.request<Array<ServiceListResponse>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2AccountsAccountidSuppliersCompaniesSuppliercompanyid(accountId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<CompanyView>;
    public getRegistryApiV2AccountsAccountidSuppliersCompaniesSuppliercompanyid(accountId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<CompanyView>>;
    public getRegistryApiV2AccountsAccountidSuppliersCompaniesSuppliercompanyid(accountId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<CompanyView>>;
    public getRegistryApiV2AccountsAccountidSuppliersCompaniesSuppliercompanyid(accountId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getRegistryApiV2AccountsAccountidSuppliersCompaniesSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getRegistryApiV2AccountsAccountidSuppliersCompaniesSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-companies/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<CompanyView>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2AccountsAccountidSuppliersDetailSupplieridUsers(accountId: number, supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BuyerSuppliersDetailListUsersResponse>;
    public getRegistryApiV2AccountsAccountidSuppliersDetailSupplieridUsers(accountId: number, supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BuyerSuppliersDetailListUsersResponse>>;
    public getRegistryApiV2AccountsAccountidSuppliersDetailSupplieridUsers(accountId: number, supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BuyerSuppliersDetailListUsersResponse>>;
    public getRegistryApiV2AccountsAccountidSuppliersDetailSupplieridUsers(accountId: number, supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling getRegistryApiV2AccountsAccountidSuppliersDetailSupplieridUsers.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getRegistryApiV2AccountsAccountidSuppliersDetailSupplieridUsers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/detail/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/users`;
        return this.httpClient.request<BuyerSuppliersDetailListUsersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param service 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2BuyersBuyeridSuppliersOverview(buyerId: number, service?: OverviewServiceTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<OverviewResponse>;
    public getRegistryApiV2BuyersBuyeridSuppliersOverview(buyerId: number, service?: OverviewServiceTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<OverviewResponse>>;
    public getRegistryApiV2BuyersBuyeridSuppliersOverview(buyerId: number, service?: OverviewServiceTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<OverviewResponse>>;
    public getRegistryApiV2BuyersBuyeridSuppliersOverview(buyerId: number, service?: OverviewServiceTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getRegistryApiV2BuyersBuyeridSuppliersOverview.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (service !== undefined && service !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>service, 'service');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-overview`;
        return this.httpClient.request<OverviewResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2FactorsFactoridDiscountRates(factorId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<FactorDiscountRateTypesResponse>;
    public getRegistryApiV2FactorsFactoridDiscountRates(factorId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<FactorDiscountRateTypesResponse>>;
    public getRegistryApiV2FactorsFactoridDiscountRates(factorId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<FactorDiscountRateTypesResponse>>;
    public getRegistryApiV2FactorsFactoridDiscountRates(factorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getRegistryApiV2FactorsFactoridDiscountRates.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-rates`;
        return this.httpClient.request<FactorDiscountRateTypesResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param fiscalCode 
     * @param countryCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistryApiV2LegalEntityFiscalCode(fiscalCode: string, countryCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<boolean>;
    public getRegistryApiV2LegalEntityFiscalCode(fiscalCode: string, countryCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<boolean>>;
    public getRegistryApiV2LegalEntityFiscalCode(fiscalCode: string, countryCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<boolean>>;
    public getRegistryApiV2LegalEntityFiscalCode(fiscalCode: string, countryCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (fiscalCode === null || fiscalCode === undefined) {
            throw new Error('Required parameter fiscalCode was null or undefined when calling getRegistryApiV2LegalEntityFiscalCode.');
        }
        if (countryCode === null || countryCode === undefined) {
            throw new Error('Required parameter countryCode was null or undefined when calling getRegistryApiV2LegalEntityFiscalCode.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (fiscalCode !== undefined && fiscalCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fiscalCode, 'fiscalCode');
        }
        if (countryCode !== undefined && countryCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>countryCode, 'countryCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/legal-entity/fiscal-code`;
        return this.httpClient.request<boolean>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param supplierListPostRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postRegistryApiV2AccountsAccountidSuppliers(accountId: number, supplierListPostRequest: SupplierListPostRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseSupplierFiltersSupplierListItemSortableFields>;
    public postRegistryApiV2AccountsAccountidSuppliers(accountId: number, supplierListPostRequest: SupplierListPostRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseSupplierFiltersSupplierListItemSortableFields>>;
    public postRegistryApiV2AccountsAccountidSuppliers(accountId: number, supplierListPostRequest: SupplierListPostRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseSupplierFiltersSupplierListItemSortableFields>>;
    public postRegistryApiV2AccountsAccountidSuppliers(accountId: number, supplierListPostRequest: SupplierListPostRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling postRegistryApiV2AccountsAccountidSuppliers.');
        }
        if (supplierListPostRequest === null || supplierListPostRequest === undefined) {
            throw new Error('Required parameter supplierListPostRequest was null or undefined when calling postRegistryApiV2AccountsAccountidSuppliers.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers`;
        return this.httpClient.request<PaginatedResponseSupplierFiltersSupplierListItemSortableFields>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierListPostRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param accountId 
     * @param supplierListReportPostRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postRegistryApiV2AccountsAccountidSuppliersReport(accountId: number, supplierListReportPostRequest: SupplierListReportPostRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postRegistryApiV2AccountsAccountidSuppliersReport(accountId: number, supplierListReportPostRequest: SupplierListReportPostRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postRegistryApiV2AccountsAccountidSuppliersReport(accountId: number, supplierListReportPostRequest: SupplierListReportPostRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postRegistryApiV2AccountsAccountidSuppliersReport(accountId: number, supplierListReportPostRequest: SupplierListReportPostRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (accountId === null || accountId === undefined) {
            throw new Error('Required parameter accountId was null or undefined when calling postRegistryApiV2AccountsAccountidSuppliersReport.');
        }
        if (supplierListReportPostRequest === null || supplierListReportPostRequest === undefined) {
            throw new Error('Required parameter supplierListReportPostRequest was null or undefined when calling postRegistryApiV2AccountsAccountidSuppliersReport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/accounts/${this.configuration.encodeParam({name: "accountId", value: accountId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/report`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: supplierListReportPostRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param factorDiscountRateTypesRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putRegistryApiV2FactorsFactoridDiscountRates(factorId: number, factorDiscountRateTypesRequest: FactorDiscountRateTypesRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putRegistryApiV2FactorsFactoridDiscountRates(factorId: number, factorDiscountRateTypesRequest: FactorDiscountRateTypesRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putRegistryApiV2FactorsFactoridDiscountRates(factorId: number, factorDiscountRateTypesRequest: FactorDiscountRateTypesRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putRegistryApiV2FactorsFactoridDiscountRates(factorId: number, factorDiscountRateTypesRequest: FactorDiscountRateTypesRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putRegistryApiV2FactorsFactoridDiscountRates.');
        }
        if (factorDiscountRateTypesRequest === null || factorDiscountRateTypesRequest === undefined) {
            throw new Error('Required parameter factorDiscountRateTypesRequest was null or undefined when calling putRegistryApiV2FactorsFactoridDiscountRates.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/registry/api/v2/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/discount-rates`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: factorDiscountRateTypesRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

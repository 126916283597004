/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveDiscountResponse1 } from './activeDiscountResponse1';
import { ActiveDiscountRequestFiltersResponse } from './activeDiscountRequestFiltersResponse';


export interface InvoiceContentResponseActiveDiscountResponseActiveDiscountRequestFiltersResponse { 
    data?: Array<ActiveDiscountResponse1>;
    count: number;
    startResult: number;
    maxResult: number;
    lastId?: number;
    filters: ActiveDiscountRequestFiltersResponse;
}


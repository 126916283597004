/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InfocertDiscountSignatureStatusView } from './infocertDiscountSignatureStatusView';


export interface InfocertDiscountSignatureCallbackRequest { 
    businessId: string;
    status: InfocertDiscountSignatureStatusView;
    creationDate: string;
    lastActivityDate: string;
}
export namespace InfocertDiscountSignatureCallbackRequest {
}



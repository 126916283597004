/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Answer } from './answer';


export interface Question { 
    position: number;
    question: string;
    answerMultiplicity: number;
    blocking: boolean;
    answers?: Array<Answer>;
}


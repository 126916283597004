/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EsgOpenEsDistributionApi } from './esgOpenEsDistributionApi';
import { EsgOpenEsValueApi } from './esgOpenEsValueApi';
import { EsgOpenEsSectorApi } from './esgOpenEsSectorApi';


export interface EsgOpenEsScoreDataApi { 
    values?: Array<EsgOpenEsValueApi>;
    sectors?: Array<EsgOpenEsSectorApi>;
    distributions?: Array<EsgOpenEsDistributionApi>;
}


import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';

import { AuthService } from '../services/auth-service/auth.service';
import { IonicStorageService } from '../storages/ionic-storage.service';
import { IonicStorageModelKeys } from '../storages/models/ionic-storage-model';

@Injectable({ providedIn: 'root' })
export class IsLoginGuard {
    constructor(
        private authService: AuthService,
        private router: Router,
        private storageService: IonicStorageService,
    ) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.storageService.get(IonicStorageModelKeys.user).pipe(
            map((user) => {
                if (!user) {
                    this.authService.logout();
                    return this.router.parseUrl('public');
                } else {
                    return true;
                }
            }),
        );
    }
}

import { Injectable } from '@angular/core';
import {
    AuthenticationIdentity,
    IdentityOrganization,
    IdentityUser,
} from '@fd/clients/thoth/client';
import { Observable, combineLatest, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IonicStorageService } from 'src/shared/storages/ionic-storage.service';
import { IonicStorageModelKeys } from 'src/shared/storages/models/ionic-storage-model';

@Injectable()
export class AuthService {
    private static ORGANIZATION_LOCAL_STORAGE_ID: IonicStorageModelKeys =
        'organization';
    private static USER_LOCAL_STORAGE_ID: IonicStorageModelKeys = 'user';

    constructor(private storageService: IonicStorageService) {}

    getExternalId(): Observable<number> {
        return this.getOrganizationFromStorage().pipe(
            map((organization) => organization?.externalId),
        );
    }
    getLegalEntity(): Observable<boolean> {
        return this.getUserFromStorage().pipe(map((user) => user?.legalEntity));
    }

    login(auth: AuthenticationIdentity): Observable<boolean> {
        return combineLatest([
            this.setUser(auth.user),
            this.setOrganization(auth.organization),
        ]).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return of(false);
            }),
        );
    }

    logout(): Observable<boolean> {
        return combineLatest([
            this.setUser(null),
            this.setOrganization(null),
        ]).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return of(false);
            }),
        );
    }

    otp(oneTimeToken: string, username: string): Observable<boolean> {
        return combineLatest([
            this.setOneTimeToken(oneTimeToken),
            this.setUsername(username),
        ]).pipe(
            map(() => {
                return true;
            }),
            catchError(() => {
                return of(false);
            }),
        );
    }

    setOneTimeToken(oneTimeToken: string) {
        return from(
            this.storageService.set(
                IonicStorageModelKeys.oneTimeTokenLocalStorageKey,
                oneTimeToken,
            ),
        );
    }

    updateUserData(user: IdentityUser): Observable<IdentityUser> {
        return combineLatest([this.setUser(user)]).pipe(map(() => user));
    }

    private getOrganizationFromStorage(): Observable<IdentityOrganization> {
        return this.storageService
            .get(AuthService.ORGANIZATION_LOCAL_STORAGE_ID)
            .pipe(map((response) => response));
    }

    private getUserFromStorage(): Observable<IdentityUser> {
        return this.storageService
            .get(AuthService.USER_LOCAL_STORAGE_ID)
            .pipe(map((response) => response));
    }

    private setOrganization(
        organization: IdentityOrganization | null,
    ): Observable<unknown> {
        if (organization === null) {
            return from(
                this.storageService.remove(
                    AuthService.ORGANIZATION_LOCAL_STORAGE_ID,
                ),
            );
        } else {
            return from(
                this.storageService.set(
                    AuthService.ORGANIZATION_LOCAL_STORAGE_ID,
                    organization,
                ),
            );
        }
    }

    private setUser(user: IdentityUser | null): Observable<unknown> {
        if (user === null) {
            return from(
                this.storageService.remove(AuthService.USER_LOCAL_STORAGE_ID),
            );
        } else {
            return from(
                this.storageService.set(
                    AuthService.USER_LOCAL_STORAGE_ID,
                    user,
                ),
            );
        }
    }

    private setUsername(username: string): Observable<unknown> {
        return from(
            this.storageService.set(
                IonicStorageModelKeys.loginUserName,
                username,
            ),
        );
    }
}

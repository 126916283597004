/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentTypeView } from './documentTypeView';
import { DocumentStatusView } from './documentStatusView';
import { OnboardingLegalEntityView } from './onboardingLegalEntityView';


export interface OnboardingDocumentResponse1 { 
    id: number;
    name: DocumentTypeView;
    legalEntity?: OnboardingLegalEntityView;
    status: DocumentStatusView;
    canPreload: boolean;
    preloadedDocumentName?: string;
    preloadedDocumentUrl?: string;
    uploadedDocumentName?: string;
    uploadedDocumentUrl?: string;
    rejectedReason?: string;
    info?: string;
}
export namespace OnboardingDocumentResponse1 {
}



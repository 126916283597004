/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AllocatedService1 = 'Confirming' | 'DynamicDiscounting' | 'Reverse';

export const AllocatedService1 = {
    Confirming: 'Confirming' as AllocatedService1,
    DynamicDiscounting: 'DynamicDiscounting' as AllocatedService1,
    Reverse: 'Reverse' as AllocatedService1
};


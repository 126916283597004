/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplierBuyerResponseFilters } from './supplierBuyerResponseFilters';
import { PaginationResponse } from './paginationResponse';
import { PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFieldsSort } from './paginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFieldsSort';
import { SupplierBuyerListItemResponse } from './supplierBuyerListItemResponse';


export interface PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFields { 
    filters: SupplierBuyerResponseFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseSupplierBuyerResponseFiltersSupplierBuyerListItemResponseSortableFieldsSort;
    page?: Array<SupplierBuyerListItemResponse>;
}


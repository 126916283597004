/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponseBuyerFiltersBuyerResponseSortableFieldsSort { 
    field: PaginatedResponseBuyerFiltersBuyerResponseSortableFieldsSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponseBuyerFiltersBuyerResponseSortableFieldsSort {
    export type FieldEnum = 'buyerCompanyName' | 'buyerCompanyVat' | 'factorBuyerCode' | 'activatedSuppliers';
    export const FieldEnum = {
        BuyerCompanyName: 'buyerCompanyName' as FieldEnum,
        BuyerCompanyVat: 'buyerCompanyVat' as FieldEnum,
        FactorBuyerCode: 'factorBuyerCode' as FieldEnum,
        ActivatedSuppliers: 'activatedSuppliers' as FieldEnum
    };
}



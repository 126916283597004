/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface FactorFilter { 
    integrationType: FactorIntegrationTypeView;
    label: string;
    selected: boolean;
    value: string;
    vat: VatView;
}
export namespace FactorFilter {
}



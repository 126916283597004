/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFieldsSort } from './paginatedResponseSupplierFiltersSupplierListItemResponseSortableFieldsSort';
import { SupplierListItemResponse } from './supplierListItemResponse';
import { PaginationResponse } from './paginationResponse';
import { SupplierFilters } from './supplierFilters';


export interface PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFields { 
    filters: SupplierFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseSupplierFiltersSupplierListItemResponseSortableFieldsSort;
    page?: Array<SupplierListItemResponse>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfirmingDiscountRequestLineErrorResponse } from './confirmingDiscountRequestLineErrorResponse';


export interface ConfirmingDiscountRequestLineResponse { 
    buyerName: string;
    discount: number;
    division: string;
    documentNumber: string;
    invoiceAmount: number;
    invoiceId: number;
    issueDate: string;
    dueDate: string;
    newDueDate: string;
    notesAmount: number;
    prizeAmount: number;
    errors?: Array<ConfirmingDiscountRequestLineErrorResponse>;
}


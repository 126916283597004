/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditCardPaymentStatus } from './creditCardPaymentStatus';
import { CreditCardProvider } from './creditCardProvider';
import { CreditCardPaymentSettings } from './creditCardPaymentSettings';


export interface CreditCardPayment { 
    provider: CreditCardProvider;
    status: CreditCardPaymentStatus;
    settings?: CreditCardPaymentSettings;
}
export namespace CreditCardPayment {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FactorService = 'ActiveFinancing' | 'Confirming' | 'ConfirmingJointPlafond' | 'ConfirmingSupplierFee' | 'CreditCard' | 'DynamicDiscounting' | 'EsgKPI' | 'EsgScore' | 'InterestRates' | 'Onboarding' | 'Reverse';

export const FactorService = {
    ActiveFinancing: 'ActiveFinancing' as FactorService,
    Confirming: 'Confirming' as FactorService,
    ConfirmingJointPlafond: 'ConfirmingJointPlafond' as FactorService,
    ConfirmingSupplierFee: 'ConfirmingSupplierFee' as FactorService,
    CreditCard: 'CreditCard' as FactorService,
    DynamicDiscounting: 'DynamicDiscounting' as FactorService,
    EsgKpi: 'EsgKPI' as FactorService,
    EsgScore: 'EsgScore' as FactorService,
    InterestRates: 'InterestRates' as FactorService,
    Onboarding: 'Onboarding' as FactorService,
    Reverse: 'Reverse' as FactorService
};


/**
 * Authentication API
 * Thoth authentication API
 *
 * The version of the OpenAPI document: 2.4.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Saml2Info } from './saml2Info';
import { GroupResponse } from './groupResponse';


export interface IdentityUser { 
    id: number;
    uuid: string;
    login: boolean;
    email: string;
    firstName: string;
    fiscalCode?: string;
    lastName: string;
    legalEntity: boolean;
    timeZone: string;
    locale: string;
    groups: Array<GroupResponse>;
    phone?: string;
    status: string;
    saml2Info?: Saml2Info;
    isHolding: boolean;
    divisions: Array<string>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiGridIntUpdate } from './kpiGridIntUpdate';
import { KpiGridBooleanUpdate } from './kpiGridBooleanUpdate';
import { KpiTargetUpdateInt } from './kpiTargetUpdateInt';
import { KpiGridDoubleUpdate } from './kpiGridDoubleUpdate';
import { KpiView } from './kpiView';


/**
 * @type KpiGridUpdate
 * @export
 */
export type KpiGridUpdate = KpiGridBooleanUpdate | KpiGridDoubleUpdate | KpiGridIntUpdate;


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditCardPayment } from './creditCardPayment';
import { AllocatedService1 } from './allocatedService1';
import { CreditCardProvider } from './creditCardProvider';
import { VatView } from './vatView';


export interface SupplierActivationDivision1 { 
    name: string;
    buyerDivisionCode: string;
    companyVat: VatView;
    supplierCompanyVat: VatView;
    buyerCompanyVat: VatView;
    enabled: boolean;
    allocatedService?: AllocatedService1;
    creditCardProviders?: Array<CreditCardProvider>;
    creditCardPayment?: CreditCardPayment;
}
export namespace SupplierActivationDivision1 {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Requester } from './requester';
import { VatView } from './vatView';
import { ActiveDiscountStatusApi } from './activeDiscountStatusApi';


export interface ActiveDiscountDetailResponse1 { 
    accelerationDays: number;
    buyerName: string;
    buyerCode: string;
    buyerVat: VatView;
    currency: string;
    discountId: number;
    discountRequestDate: string;
    dueDate: string;
    factorName: string;
    invoiceKeyCode: string;
    invoiceTotal: number;
    issueDate: string;
    newDueDate: string;
    requester?: Requester;
    status: ActiveDiscountStatusApi;
    cigCode?: string;
    cupCode?: string;
    discountOrderNumber?: number;
}
export namespace ActiveDiscountDetailResponse1 {
}



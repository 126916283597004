/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseBuyerSupplierListItemResponse } from './reverseBuyerSupplierListItemResponse';
import { PaginationResponse } from './paginationResponse';
import { ReverseBuyerSupplierResponseFilters } from './reverseBuyerSupplierResponseFilters';
import { PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1Sort } from './paginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1Sort';


export interface PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1 { 
    filters: ReverseBuyerSupplierResponseFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1Sort;
    page?: Array<ReverseBuyerSupplierListItemResponse>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AutocompleteResponseApiStringAutocompleteType } from '../model/autocompleteResponseApiStringAutocompleteType';
// @ts-ignore
import { BadRequest } from '../model/badRequest';
// @ts-ignore
import { Conflict } from '../model/conflict';
// @ts-ignore
import { DateAggregationApi } from '../model/dateAggregationApi';
// @ts-ignore
import { FactorReverseInvoiceStatusUpdateInput } from '../model/factorReverseInvoiceStatusUpdateInput';
// @ts-ignore
import { FactorReverseInvoicesSummaryResponse } from '../model/factorReverseInvoicesSummaryResponse';
// @ts-ignore
import { Forbidden } from '../model/forbidden';
// @ts-ignore
import { InvoiceContentResponseReverseInvoiceResponseReverseFactorInvoiceFiltersResponse } from '../model/invoiceContentResponseReverseInvoiceResponseReverseFactorInvoiceFiltersResponse';
// @ts-ignore
import { InvoiceContentResponseReverseInvoiceResponseReverseInvoiceFiltersResponse } from '../model/invoiceContentResponseReverseInvoiceResponseReverseInvoiceFiltersResponse';
// @ts-ignore
import { NotFound } from '../model/notFound';
// @ts-ignore
import { PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFields } from '../model/paginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFields';
// @ts-ignore
import { PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields } from '../model/paginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields';
// @ts-ignore
import { PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1 } from '../model/paginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1';
// @ts-ignore
import { PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFields } from '../model/paginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFields';
// @ts-ignore
import { RecognizeCheckoutResponse } from '../model/recognizeCheckoutResponse';
// @ts-ignore
import { ReportFormat } from '../model/reportFormat';
// @ts-ignore
import { ReverseActivationRequest } from '../model/reverseActivationRequest';
// @ts-ignore
import { ReverseBuyerActivationRequest } from '../model/reverseBuyerActivationRequest';
// @ts-ignore
import { ReverseBuyerFactorRelationsResponse } from '../model/reverseBuyerFactorRelationsResponse';
// @ts-ignore
import { ReverseBuyerSortableFieldsView } from '../model/reverseBuyerSortableFieldsView';
// @ts-ignore
import { ReverseDashboardResponse } from '../model/reverseDashboardResponse';
// @ts-ignore
import { ReverseEnableSupplierRequestBody } from '../model/reverseEnableSupplierRequestBody';
// @ts-ignore
import { ReverseFactorBuyerStatusView } from '../model/reverseFactorBuyerStatusView';
// @ts-ignore
import { ReverseFactorBuyerSupplierListItemResponse } from '../model/reverseFactorBuyerSupplierListItemResponse';
// @ts-ignore
import { ReverseFactorBuyersListItemResponse } from '../model/reverseFactorBuyersListItemResponse';
// @ts-ignore
import { ReverseFactorInvoiceStatusView } from '../model/reverseFactorInvoiceStatusView';
// @ts-ignore
import { ReverseFactorRelationStatusView } from '../model/reverseFactorRelationStatusView';
// @ts-ignore
import { ReverseFactorSortableFieldsView } from '../model/reverseFactorSortableFieldsView';
// @ts-ignore
import { ReverseFactorSupplierListItemResponse } from '../model/reverseFactorSupplierListItemResponse';
// @ts-ignore
import { ReverseFactorsResponse } from '../model/reverseFactorsResponse';
// @ts-ignore
import { ReverseInvoiceStatusView } from '../model/reverseInvoiceStatusView';
// @ts-ignore
import { ReverseInvoiceTypeView } from '../model/reverseInvoiceTypeView';
// @ts-ignore
import { ReverseInvoicesSummaryResponse } from '../model/reverseInvoicesSummaryResponse';
// @ts-ignore
import { ReverseRequestSelectionResponse } from '../model/reverseRequestSelectionResponse';
// @ts-ignore
import { SortOrder } from '../model/sortOrder';
// @ts-ignore
import { SortableFields10 } from '../model/sortableFields10';
// @ts-ignore
import { SortableFields11 } from '../model/sortableFields11';
// @ts-ignore
import { SortableFields12 } from '../model/sortableFields12';
// @ts-ignore
import { SortableFields9 } from '../model/sortableFields9';
// @ts-ignore
import { SupplierReverseStatusResponse } from '../model/supplierReverseStatusResponse';
// @ts-ignore
import { TechnicalError } from '../model/technicalError';
// @ts-ignore
import { Unauthorized } from '../model/unauthorized';
// @ts-ignore
import { Unknown } from '../model/unknown';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ReverseFactoringService {

    protected basePath = 'http://localhost:9009';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param buyerId 
     * @param factorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReverseApiV1BuyersBuyeridReverseSelectionFactorsFactorid(buyerId: number, factorId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteReverseApiV1BuyersBuyeridReverseSelectionFactorsFactorid(buyerId: number, factorId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteReverseApiV1BuyersBuyeridReverseSelectionFactorsFactorid(buyerId: number, factorId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteReverseApiV1BuyersBuyeridReverseSelectionFactorsFactorid(buyerId: number, factorId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteReverseApiV1BuyersBuyeridReverseSelectionFactorsFactorid.');
        }
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling deleteReverseApiV1BuyersBuyeridReverseSelectionFactorsFactorid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reverse-selection/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReverseApiV1BuyersBuyeridReverseSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteReverseApiV1BuyersBuyeridReverseSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteReverseApiV1BuyersBuyeridReverseSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteReverseApiV1BuyersBuyeridReverseSelectionInvoicesInvoiceid(buyerId: number, invoiceId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteReverseApiV1BuyersBuyeridReverseSelectionInvoicesInvoiceid.');
        }
        if (invoiceId === null || invoiceId === undefined) {
            throw new Error('Required parameter invoiceId was null or undefined when calling deleteReverseApiV1BuyersBuyeridReverseSelectionInvoicesInvoiceid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reverse-selection/invoices/${this.configuration.encodeParam({name: "invoiceId", value: invoiceId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling deleteReverseApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling deleteReverseApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling deleteReverseApiV1FactorsFactoridBuyersBuyercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling deleteReverseApiV1FactorsFactoridBuyersBuyercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling deleteReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling deleteReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling deleteReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param currency 
     * @param endDueDate 
     * @param startDueDate 
     * @param accumulate 
     * @param dateAggregation 
     * @param divisionId 
     * @param factorId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridDashboard(buyerId: number, currency: string, endDueDate: string, startDueDate: string, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseDashboardResponse>;
    public getReverseApiV1BuyersBuyeridDashboard(buyerId: number, currency: string, endDueDate: string, startDueDate: string, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseDashboardResponse>>;
    public getReverseApiV1BuyersBuyeridDashboard(buyerId: number, currency: string, endDueDate: string, startDueDate: string, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseDashboardResponse>>;
    public getReverseApiV1BuyersBuyeridDashboard(buyerId: number, currency: string, endDueDate: string, startDueDate: string, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridDashboard.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1BuyersBuyeridDashboard.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridDashboard.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridDashboard.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accumulate !== undefined && accumulate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accumulate, 'accumulate');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (dateAggregation !== undefined && dateAggregation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateAggregation, 'dateAggregation');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (divisionId !== undefined && divisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>divisionId, 'divisionId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dashboard`;
        return this.httpClient.request<ReverseDashboardResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param currency 
     * @param endDueDate 
     * @param startDueDate 
     * @param format 
     * @param accumulate 
     * @param dateAggregation 
     * @param divisionId 
     * @param factorId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridDashboardReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getReverseApiV1BuyersBuyeridDashboardReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getReverseApiV1BuyersBuyeridDashboardReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getReverseApiV1BuyersBuyeridDashboardReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, accumulate?: boolean, dateAggregation?: DateAggregationApi, divisionId?: number, factorId?: number, supplierCompanyId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridDashboardReport.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1BuyersBuyeridDashboardReport.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridDashboardReport.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridDashboardReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getReverseApiV1BuyersBuyeridDashboardReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (accumulate !== undefined && accumulate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>accumulate, 'accumulate');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (dateAggregation !== undefined && dateAggregation !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateAggregation, 'dateAggregation');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (divisionId !== undefined && divisionId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>divisionId, 'divisionId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/dashboard-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridFactors(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseFactorsResponse>;
    public getReverseApiV1BuyersBuyeridFactors(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseFactorsResponse>>;
    public getReverseApiV1BuyersBuyeridFactors(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseFactorsResponse>>;
    public getReverseApiV1BuyersBuyeridFactors(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/factors`;
        return this.httpClient.request<ReverseFactorsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param currency 
     * @param endDueDate 
     * @param startDueDate 
     * @param buyerDivision 
     * @param supplierCompanyId 
     * @param factorId 
     * @param invoiceId 
     * @param sort 
     * @param sortOrder 
     * @param status 
     * @param invoiceType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridInvoiceIds(buyerId: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getReverseApiV1BuyersBuyeridInvoiceIds(buyerId: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getReverseApiV1BuyersBuyeridInvoiceIds(buyerId: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getReverseApiV1BuyersBuyeridInvoiceIds(buyerId: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridInvoiceIds.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1BuyersBuyeridInvoiceIds.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridInvoiceIds.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridInvoiceIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (invoiceId !== undefined && invoiceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceId, 'invoiceId');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (invoiceType !== undefined && invoiceType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceType, 'invoiceType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoice-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param paginationStart 
     * @param paginationMax 
     * @param currency 
     * @param endDueDate 
     * @param startDueDate 
     * @param buyerDivision 
     * @param supplierCompanyId 
     * @param factorId 
     * @param invoiceId 
     * @param sort 
     * @param sortOrder 
     * @param status 
     * @param invoiceType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridInvoices(buyerId: number, paginationStart: number, paginationMax: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseReverseInvoiceResponseReverseInvoiceFiltersResponse>;
    public getReverseApiV1BuyersBuyeridInvoices(buyerId: number, paginationStart: number, paginationMax: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseReverseInvoiceResponseReverseInvoiceFiltersResponse>>;
    public getReverseApiV1BuyersBuyeridInvoices(buyerId: number, paginationStart: number, paginationMax: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseReverseInvoiceResponseReverseInvoiceFiltersResponse>>;
    public getReverseApiV1BuyersBuyeridInvoices(buyerId: number, paginationStart: number, paginationMax: number, currency: string, endDueDate: string, startDueDate: string, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridInvoices.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getReverseApiV1BuyersBuyeridInvoices.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getReverseApiV1BuyersBuyeridInvoices.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1BuyersBuyeridInvoices.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridInvoices.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridInvoices.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (invoiceId !== undefined && invoiceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceId, 'invoiceId');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (invoiceType !== undefined && invoiceType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceType, 'invoiceType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices`;
        return this.httpClient.request<InvoiceContentResponseReverseInvoiceResponseReverseInvoiceFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridInvoicesAutocomplete(buyerId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getReverseApiV1BuyersBuyeridInvoicesAutocomplete(buyerId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getReverseApiV1BuyersBuyeridInvoicesAutocomplete(buyerId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getReverseApiV1BuyersBuyeridInvoicesAutocomplete(buyerId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param currency 
     * @param endDueDate 
     * @param startDueDate 
     * @param format 
     * @param buyerDivision 
     * @param supplierCompanyId 
     * @param factorId 
     * @param invoiceId 
     * @param sort 
     * @param sortOrder 
     * @param status 
     * @param invoiceType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridInvoicesReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getReverseApiV1BuyersBuyeridInvoicesReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getReverseApiV1BuyersBuyeridInvoicesReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getReverseApiV1BuyersBuyeridInvoicesReport(buyerId: number, currency: string, endDueDate: string, startDueDate: string, format: ReportFormat, buyerDivision?: string, supplierCompanyId?: number, factorId?: number, invoiceId?: number, sort?: ReverseBuyerSortableFieldsView, sortOrder?: SortOrder, status?: ReverseInvoiceStatusView, invoiceType?: ReverseInvoiceTypeView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesReport.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesReport.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesReport.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (buyerDivision !== undefined && buyerDivision !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerDivision, 'buyerDivision');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (invoiceId !== undefined && invoiceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceId, 'invoiceId');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (invoiceType !== undefined && invoiceType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceType, 'invoiceType');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param invoiceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceIds: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseInvoicesSummaryResponse>;
    public getReverseApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceIds: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseInvoicesSummaryResponse>>;
    public getReverseApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceIds: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseInvoicesSummaryResponse>>;
    public getReverseApiV1BuyersBuyeridInvoicesSummary(buyerId: number, invoiceIds: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesSummary.');
        }
        if (invoiceIds === null || invoiceIds === undefined) {
            throw new Error('Required parameter invoiceIds was null or undefined when calling getReverseApiV1BuyersBuyeridInvoicesSummary.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/summary`;
        return this.httpClient.request<ReverseInvoicesSummaryResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridReverseFactorRelations(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseBuyerFactorRelationsResponse>;
    public getReverseApiV1BuyersBuyeridReverseFactorRelations(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseBuyerFactorRelationsResponse>>;
    public getReverseApiV1BuyersBuyeridReverseFactorRelations(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseBuyerFactorRelationsResponse>>;
    public getReverseApiV1BuyersBuyeridReverseFactorRelations(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridReverseFactorRelations.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reverse-factor-relations`;
        return this.httpClient.request<ReverseBuyerFactorRelationsResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseRequestSelectionResponse>;
    public getReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseRequestSelectionResponse>>;
    public getReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseRequestSelectionResponse>>;
    public getReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridReverseSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reverse-selection`;
        return this.httpClient.request<ReverseRequestSelectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<SupplierReverseStatusResponse>;
    public getReverseApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<SupplierReverseStatusResponse>>;
    public getReverseApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<SupplierReverseStatusResponse>>;
    public getReverseApiV1BuyersBuyeridSuppliersSupplieridStatus(buyerId: number, supplierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling getReverseApiV1BuyersBuyeridSuppliersSupplieridStatus.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling getReverseApiV1BuyersBuyeridSuppliersSupplieridStatus.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/status`;
        return this.httpClient.request<SupplierReverseStatusResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param buyerCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFields>;
    public getReverseApiV1FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFields>>;
    public getReverseApiV1FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFields>>;
    public getReverseApiV1FactorsFactoridBuyers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridBuyers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getReverseApiV1FactorsFactoridBuyers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getReverseApiV1FactorsFactoridBuyers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<PaginatedResponseBuyerFiltersReverseFactorBuyersListItemResponseSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseFactorBuyersListItemResponse>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseFactorBuyersListItemResponse>>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseFactorBuyersListItemResponse>>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyid(factorId: number, buyerCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ReverseFactorBuyersListItemResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param relationStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields12, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields12, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1>>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields12, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1>>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers(factorId: number, buyerCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields12, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (relationStatus !== undefined && relationStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relationStatus, 'relationStatus');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers`;
        return this.httpClient.request<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields1>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseFactorBuyerSupplierListItemResponse>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseFactorBuyerSupplierListItemResponse>>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseFactorBuyerSupplierListItemResponse>>;
    public getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid(factorId: number, buyerCompanyId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ReverseFactorBuyerSupplierListItemResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param buyerCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getReverseApiV1FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getReverseApiV1FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getReverseApiV1FactorsFactoridBuyersReport(factorId: number, format: ReportFormat, sortField?: SortableFields9, sortOrder?: SortOrder, search?: string, buyerCompanyId?: number, status?: ReverseFactorBuyerStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridBuyersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getReverseApiV1FactorsFactoridBuyersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param startDueDate 
     * @param endDueDate 
     * @param status 
     * @param currency 
     * @param sort 
     * @param sortOrder 
     * @param buyerCompanyId 
     * @param buyerId 
     * @param supplierCompanyId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridInvoiceIds(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<number>>;
    public getReverseApiV1FactorsFactoridInvoiceIds(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<number>>>;
    public getReverseApiV1FactorsFactoridInvoiceIds(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<number>>>;
    public getReverseApiV1FactorsFactoridInvoiceIds(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridInvoiceIds.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1FactorsFactoridInvoiceIds.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1FactorsFactoridInvoiceIds.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getReverseApiV1FactorsFactoridInvoiceIds.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1FactorsFactoridInvoiceIds.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (buyerId !== undefined && buyerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerId, 'buyerId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (invoiceId !== undefined && invoiceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceId, 'invoiceId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoice-ids`;
        return this.httpClient.request<Array<number>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param paginationStart 
     * @param paginationMax 
     * @param startDueDate 
     * @param endDueDate 
     * @param status 
     * @param currency 
     * @param sort 
     * @param sortOrder 
     * @param buyerCompanyId 
     * @param buyerId 
     * @param supplierCompanyId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridInvoices(factorId: number, paginationStart: number, paginationMax: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<InvoiceContentResponseReverseInvoiceResponseReverseFactorInvoiceFiltersResponse>;
    public getReverseApiV1FactorsFactoridInvoices(factorId: number, paginationStart: number, paginationMax: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<InvoiceContentResponseReverseInvoiceResponseReverseFactorInvoiceFiltersResponse>>;
    public getReverseApiV1FactorsFactoridInvoices(factorId: number, paginationStart: number, paginationMax: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<InvoiceContentResponseReverseInvoiceResponseReverseFactorInvoiceFiltersResponse>>;
    public getReverseApiV1FactorsFactoridInvoices(factorId: number, paginationStart: number, paginationMax: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridInvoices.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getReverseApiV1FactorsFactoridInvoices.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getReverseApiV1FactorsFactoridInvoices.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1FactorsFactoridInvoices.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1FactorsFactoridInvoices.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getReverseApiV1FactorsFactoridInvoices.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1FactorsFactoridInvoices.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (buyerId !== undefined && buyerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerId, 'buyerId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (invoiceId !== undefined && invoiceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceId, 'invoiceId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices`;
        return this.httpClient.request<InvoiceContentResponseReverseInvoiceResponseReverseFactorInvoiceFiltersResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param query 
     * @param max 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<AutocompleteResponseApiStringAutocompleteType>;
    public getReverseApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<AutocompleteResponseApiStringAutocompleteType>>;
    public getReverseApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<AutocompleteResponseApiStringAutocompleteType>>;
    public getReverseApiV1FactorsFactoridInvoicesAutocomplete(factorId: number, query: string, max?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesAutocomplete.');
        }
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesAutocomplete.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (query !== undefined && query !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>query, 'query');
        }
        if (max !== undefined && max !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>max, 'max');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/autocomplete`;
        return this.httpClient.request<AutocompleteResponseApiStringAutocompleteType>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param startDueDate 
     * @param endDueDate 
     * @param status 
     * @param currency 
     * @param format 
     * @param sort 
     * @param sortOrder 
     * @param buyerCompanyId 
     * @param buyerId 
     * @param supplierCompanyId 
     * @param invoiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridInvoicesReports(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, format: ReportFormat, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getReverseApiV1FactorsFactoridInvoicesReports(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, format: ReportFormat, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getReverseApiV1FactorsFactoridInvoicesReports(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, format: ReportFormat, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getReverseApiV1FactorsFactoridInvoicesReports(factorId: number, startDueDate: string, endDueDate: string, status: ReverseFactorInvoiceStatusView, currency: string, format: ReportFormat, sort?: ReverseFactorSortableFieldsView, sortOrder?: SortOrder, buyerCompanyId?: number, buyerId?: number, supplierCompanyId?: number, invoiceId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesReports.');
        }
        if (startDueDate === null || startDueDate === undefined) {
            throw new Error('Required parameter startDueDate was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesReports.');
        }
        if (endDueDate === null || endDueDate === undefined) {
            throw new Error('Required parameter endDueDate was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesReports.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesReports.');
        }
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesReports.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesReports.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (startDueDate !== undefined && startDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDueDate, 'startDueDate');
        }
        if (endDueDate !== undefined && endDueDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDueDate, 'endDueDate');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (sort !== undefined && sort !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sort, 'sort');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (currency !== undefined && currency !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>currency, 'currency');
        }
        if (buyerCompanyId !== undefined && buyerCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerCompanyId, 'buyerCompanyId');
        }
        if (buyerId !== undefined && buyerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>buyerId, 'buyerId');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (invoiceId !== undefined && invoiceId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>invoiceId, 'invoiceId');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/reports`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param invoiceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridInvoicesSummary(factorId: number, invoiceIds: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<FactorReverseInvoicesSummaryResponse>;
    public getReverseApiV1FactorsFactoridInvoicesSummary(factorId: number, invoiceIds: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<FactorReverseInvoicesSummaryResponse>>;
    public getReverseApiV1FactorsFactoridInvoicesSummary(factorId: number, invoiceIds: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<FactorReverseInvoicesSummaryResponse>>;
    public getReverseApiV1FactorsFactoridInvoicesSummary(factorId: number, invoiceIds: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesSummary.');
        }
        if (invoiceIds === null || invoiceIds === undefined) {
            throw new Error('Required parameter invoiceIds was null or undefined when calling getReverseApiV1FactorsFactoridInvoicesSummary.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (invoiceIds) {
            invoiceIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'invoiceIds');
            })
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoices/summary`;
        return this.httpClient.request<FactorReverseInvoicesSummaryResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param supplierCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFields>;
    public getReverseApiV1FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFields>>;
    public getReverseApiV1FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFields>>;
    public getReverseApiV1FactorsFactoridSuppliers(factorId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridSuppliers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getReverseApiV1FactorsFactoridSuppliers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getReverseApiV1FactorsFactoridSuppliers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers`;
        return this.httpClient.request<PaginatedResponseSupplierFiltersReverseFactorSupplierListItemResponseSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param format 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param supplierCompanyId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public getReverseApiV1FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public getReverseApiV1FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public getReverseApiV1FactorsFactoridSuppliersReport(factorId: number, format: ReportFormat, sortField?: SortableFields11, sortOrder?: SortOrder, search?: string, supplierCompanyId?: number, status?: ReverseFactorRelationStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersReport.');
        }
        if (format === null || format === undefined) {
            throw new Error('Required parameter format was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersReport.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (supplierCompanyId !== undefined && supplierCompanyId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>supplierCompanyId, 'supplierCompanyId');
        }
        if (status !== undefined && status !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>status, 'status');
        }
        if (format !== undefined && format !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>format, 'format');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers-report`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param supplierCompanyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<ReverseFactorSupplierListItemResponse>;
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<ReverseFactorSupplierListItemResponse>>;
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<ReverseFactorSupplierListItemResponse>>;
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyid(factorId: number, supplierCompanyId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersSuppliercompanyid.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersSuppliercompanyid.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}`;
        return this.httpClient.request<ReverseFactorSupplierListItemResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param supplierCompanyId 
     * @param paginationStart 
     * @param paginationMax 
     * @param sortField 
     * @param sortOrder 
     * @param search 
     * @param relationStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields10, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields>;
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields10, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields>>;
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields10, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields>>;
    public getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers(factorId: number, supplierCompanyId: number, paginationStart: number, paginationMax: number, sortField?: SortableFields10, sortOrder?: SortOrder, search?: string, relationStatus?: ReverseFactorRelationStatusView, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }
        if (paginationStart === null || paginationStart === undefined) {
            throw new Error('Required parameter paginationStart was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }
        if (paginationMax === null || paginationMax === undefined) {
            throw new Error('Required parameter paginationMax was null or undefined when calling getReverseApiV1FactorsFactoridSuppliersSuppliercompanyidBuyers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (paginationStart !== undefined && paginationStart !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationStart, 'paginationStart');
        }
        if (paginationMax !== undefined && paginationMax !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>paginationMax, 'paginationMax');
        }
        if (sortField !== undefined && sortField !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortField, 'sortField');
        }
        if (sortOrder !== undefined && sortOrder !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sortOrder, 'sortOrder');
        }
        if (search !== undefined && search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>search, 'search');
        }
        if (relationStatus !== undefined && relationStatus !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>relationStatus, 'relationStatus');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers`;
        return this.httpClient.request<PaginatedResponseReverseBuyerSupplierResponseFiltersReverseBuyerSupplierListItemResponseSortableFields>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param divisionId 
     * @param factorId 
     * @param factorBuyerCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation(buyerId: number, divisionId: number, factorId: number, factorBuyerCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation(buyerId: number, divisionId: number, factorId: number, factorBuyerCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation(buyerId: number, divisionId: number, factorId: number, factorBuyerCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation(buyerId: number, divisionId: number, factorId: number, factorBuyerCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation.');
        }
        if (divisionId === null || divisionId === undefined) {
            throw new Error('Required parameter divisionId was null or undefined when calling postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation.');
        }
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation.');
        }
        if (factorBuyerCode === null || factorBuyerCode === undefined) {
            throw new Error('Required parameter factorBuyerCode was null or undefined when calling postReverseApiV1BuyersBuyeridDivisionsDivisionidActivation.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (factorId !== undefined && factorId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorId, 'factorId');
        }
        if (factorBuyerCode !== undefined && factorBuyerCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>factorBuyerCode, 'factorBuyerCode');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/divisions/${this.configuration.encodeParam({name: "divisionId", value: divisionId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postReverseApiV1BuyersBuyeridReverseCheckout(buyerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RecognizeCheckoutResponse>;
    public postReverseApiV1BuyersBuyeridReverseCheckout(buyerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RecognizeCheckoutResponse>>;
    public postReverseApiV1BuyersBuyeridReverseCheckout(buyerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RecognizeCheckoutResponse>>;
    public postReverseApiV1BuyersBuyeridReverseCheckout(buyerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postReverseApiV1BuyersBuyeridReverseCheckout.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-ONE-TIME-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth3) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth3');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-FD-OTP', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reverse-checkout`;
        return this.httpClient.request<RecognizeCheckoutResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, requestBody: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, requestBody: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, requestBody: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postReverseApiV1BuyersBuyeridReverseSelection(buyerId: number, requestBody: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling postReverseApiV1BuyersBuyeridReverseSelection.');
        }
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling postReverseApiV1BuyersBuyeridReverseSelection.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/reverse-selection`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param reverseBuyerActivationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, reverseBuyerActivationRequest: ReverseBuyerActivationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, reverseBuyerActivationRequest: ReverseBuyerActivationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, reverseBuyerActivationRequest: ReverseBuyerActivationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidActivation(factorId: number, buyerCompanyId: number, reverseBuyerActivationRequest: ReverseBuyerActivationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postReverseApiV1FactorsFactoridBuyersBuyercompanyidActivation.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postReverseApiV1FactorsFactoridBuyersBuyercompanyidActivation.');
        }
        if (reverseBuyerActivationRequest === null || reverseBuyerActivationRequest === undefined) {
            throw new Error('Required parameter reverseBuyerActivationRequest was null or undefined when calling postReverseApiV1FactorsFactoridBuyersBuyercompanyidActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: reverseBuyerActivationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param buyerCompanyId 
     * @param supplierCompanyId 
     * @param reverseEnableSupplierRequestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation(factorId: number, buyerCompanyId: number, supplierCompanyId: number, reverseEnableSupplierRequestBody: ReverseEnableSupplierRequestBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation(factorId: number, buyerCompanyId: number, supplierCompanyId: number, reverseEnableSupplierRequestBody: ReverseEnableSupplierRequestBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation(factorId: number, buyerCompanyId: number, supplierCompanyId: number, reverseEnableSupplierRequestBody: ReverseEnableSupplierRequestBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation(factorId: number, buyerCompanyId: number, supplierCompanyId: number, reverseEnableSupplierRequestBody: ReverseEnableSupplierRequestBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation.');
        }
        if (buyerCompanyId === null || buyerCompanyId === undefined) {
            throw new Error('Required parameter buyerCompanyId was null or undefined when calling postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation.');
        }
        if (supplierCompanyId === null || supplierCompanyId === undefined) {
            throw new Error('Required parameter supplierCompanyId was null or undefined when calling postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation.');
        }
        if (reverseEnableSupplierRequestBody === null || reverseEnableSupplierRequestBody === undefined) {
            throw new Error('Required parameter reverseEnableSupplierRequestBody was null or undefined when calling postReverseApiV1FactorsFactoridBuyersBuyercompanyidSuppliersSuppliercompanyidActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/buyers/${this.configuration.encodeParam({name: "buyerCompanyId", value: buyerCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierCompanyId", value: supplierCompanyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: reverseEnableSupplierRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param buyerId 
     * @param supplierId 
     * @param reverseActivationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, reverseActivationRequest: ReverseActivationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, reverseActivationRequest: ReverseActivationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, reverseActivationRequest: ReverseActivationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putReverseApiV1BuyersBuyeridSuppliersSupplieridActivation(buyerId: number, supplierId: number, reverseActivationRequest: ReverseActivationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (buyerId === null || buyerId === undefined) {
            throw new Error('Required parameter buyerId was null or undefined when calling putReverseApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }
        if (supplierId === null || supplierId === undefined) {
            throw new Error('Required parameter supplierId was null or undefined when calling putReverseApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }
        if (reverseActivationRequest === null || reverseActivationRequest === undefined) {
            throw new Error('Required parameter reverseActivationRequest was null or undefined when calling putReverseApiV1BuyersBuyeridSuppliersSupplieridActivation.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/buyers/${this.configuration.encodeParam({name: "buyerId", value: buyerId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/suppliers/${this.configuration.encodeParam({name: "supplierId", value: supplierId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/activation`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: reverseActivationRequest,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param factorId 
     * @param factorReverseInvoiceStatusUpdateInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public putReverseApiV1FactorsFactoridInvoiceStatuses(factorId: number, factorReverseInvoiceStatusUpdateInput: FactorReverseInvoiceStatusUpdateInput, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public putReverseApiV1FactorsFactoridInvoiceStatuses(factorId: number, factorReverseInvoiceStatusUpdateInput: FactorReverseInvoiceStatusUpdateInput, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public putReverseApiV1FactorsFactoridInvoiceStatuses(factorId: number, factorReverseInvoiceStatusUpdateInput: FactorReverseInvoiceStatusUpdateInput, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public putReverseApiV1FactorsFactoridInvoiceStatuses(factorId: number, factorReverseInvoiceStatusUpdateInput: FactorReverseInvoiceStatusUpdateInput, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (factorId === null || factorId === undefined) {
            throw new Error('Required parameter factorId was null or undefined when calling putReverseApiV1FactorsFactoridInvoiceStatuses.');
        }
        if (factorReverseInvoiceStatusUpdateInput === null || factorReverseInvoiceStatusUpdateInput === undefined) {
            throw new Error('Required parameter factorReverseInvoiceStatusUpdateInput was null or undefined when calling putReverseApiV1FactorsFactoridInvoiceStatuses.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (apiKeyAuth2) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('X-XSRF-TOKEN', localVarCredential);
        }

        // authentication (apiKeyAuth1) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth1');
        if (localVarCredential) {
        }

        // authentication (apiKeyAuth4) required
        localVarCredential = this.configuration.lookupCredential('apiKeyAuth4');
        if (localVarCredential) {
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/reverse/api/v1/factors/${this.configuration.encodeParam({name: "factorId", value: factorId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int64"})}/invoice-statuses`;
        return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: factorReverseInvoiceStatusUpdateInput,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EsgProviderApi } from './esgProviderApi';
import { PaymentMethodApi } from './paymentMethodApi';
import { EsgManagerApi } from './esgManagerApi';
import { CompanyEsgStatusApi } from './companyEsgStatusApi';
import { EsgRequesterApi } from './esgRequesterApi';
import { EsgRequesterScoreApi } from './esgRequesterScoreApi';


export interface EsgRequesterRequestApi { 
    requestId: number;
    companyName: string;
    score?: EsgRequesterScoreApi;
    status: CompanyEsgStatusApi;
    providerId: number;
    provider: EsgProviderApi;
    manager: EsgManagerApi;
    paymentMethod?: PaymentMethodApi;
    requester?: EsgRequesterApi;
}
export namespace EsgRequesterRequestApi {
}



<ion-content [fullscreen]="true" *transloco="let t" scroll-y="false">
    <div class="container column">
        <div class="label-container column">
            <div class="icon">
                <ion-img
                    src="../../assets/img/outdated-illustration.svg"
                ></ion-img>
            </div>
            <div class="title">{{ t("outdated.version") }}</div>
            <div class="label">{{ t("outdated.version.label") }}</div>
        </div>
        <fd2-button
            [label]="t('outdated.version.update.app')"
            buttonStyle="secondary"
            icon="../../assets/img/reload.svg"
            (onClick)="onClick()"
        ></fd2-button>
    </div>
</ion-content>

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFieldsSort { 
    field: PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFieldsSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponseBuyerSupplierResponseFiltersBuyerSupplierListItemResponseSortableFieldsSort {
    export type FieldEnum = 'supplierCompanyName' | 'factorSupplierCode' | 'company';
    export const FieldEnum = {
        SupplierCompanyName: 'supplierCompanyName' as FieldEnum,
        FactorSupplierCode: 'factorSupplierCode' as FieldEnum,
        Company: 'company' as FieldEnum
    };
}



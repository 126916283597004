<ion-app class="main kutxa fd">
    <ng-container *fd2Let="supportedVersion$ | async as supportedVersion">
        <ion-router-outlet
            *ngIf="(supportedVersion && isOnline) || !isApp"
        ></ion-router-outlet>
        <fd2-offline *ngIf="isApp && !isOnline"></fd2-offline>
        <fd2-outdated-version
            [deviceOs]="isIos ? 'ios' : 'android'"
            *ngIf="isApp && !supportedVersion"
        ></fd2-outdated-version>
    </ng-container>
</ion-app>

/**
 * Identity API
 * Identity API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Saml2Attribute } from './saml2Attribute';
import { CAUserInfo } from './cAUserInfo';


export interface Saml2Info { 
    attribute: Saml2Attribute;
    info: CAUserInfo;
}


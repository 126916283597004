/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetStatus } from './budgetStatus';
import { BudgetBuyer } from './budgetBuyer';
import { BudgetCheckError1 } from './budgetCheckError1';
import { DefaultBadRequest1 } from './defaultBadRequest1';


/**
 * @type BadRequestErrorBudgetCheck1
 * @export
 */
export type BadRequestErrorBudgetCheck1 = BudgetCheckError1 | DefaultBadRequest1;


import { Injectable } from '@angular/core';
import { EMPTY, Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Destroyable } from '../../base/destroyable';

@Injectable({
    providedIn: 'root',
})
export abstract class AbstractPollingService<T> extends Destroyable {
    public readonly value$: Observable<T | null>;

    private _value$ = new ReplaySubject<T | null>(1);

    private initialized = false;

    private pollingTimer: Subscription;

    protected constructor() {
        super();
        this.value$ = this._value$.asObservable();
    }

    init(): void {
        if (!this.initialized) {
            this.initialized = true;
            this.pollingTimer = this.pollUnreadCount();
        }
    }

    pollUnreadCount(): Subscription {
        return timer(0, this.pollingInterval)
            .pipe(
                switchMap(() => {
                    return this.getValue().pipe(
                        catchError(() => {
                            return EMPTY;
                        }),
                    );
                }),
                this.untilDestroyed(),
            )
            .subscribe((value: T) => this._value$.next(value ?? null));
    }

    resetValue(): void {
        this._value$.next(null);
    }

    abstract get pollingInterval(): number;

    abstract getValue(): Observable<T>;
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiscountRateResponse } from './discountRateResponse';
import { JointPlafondView } from './jointPlafondView';
import { ConfirmingRelationStatusView } from './confirmingRelationStatusView';


export interface FactorSupplierDetailResponse { 
    buyerDivisionCode: string;
    /**
     * use buyerCompanyId
     */
    buyerId: number;
    buyerCompanyId: number;
    buyerName: string;
    factorSupplierCode?: string;
    supplierCompanyId: number;
    supplierId: number;
    supplierName: string;
    supplierStatus: ConfirmingRelationStatusView;
    discountRate?: DiscountRateResponse;
    feePercentage?: number;
    jointPlafond?: JointPlafondView;
}
export namespace FactorSupplierDetailResponse {
}



/**
 * Authentication API
 * Thoth authentication API
 *
 * The version of the OpenAPI document: 2.4.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MfaIncompleteDataResponse { 
    email: string;
    oneTimeToken: string;
    userName: string;
    userSurname: string;
    tag: object;
    telephone?: string;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DeferralRequestLineErrorResponse = 'DeferralInvoiceNotEligible' | 'DeferralInvoiceNotFound' | 'DeferralRequestNotValid' | 'DeferralRequestSimulation' | 'DeferralNoBudget';

export const DeferralRequestLineErrorResponse = {
    DeferralInvoiceNotEligible: 'DeferralInvoiceNotEligible' as DeferralRequestLineErrorResponse,
    DeferralInvoiceNotFound: 'DeferralInvoiceNotFound' as DeferralRequestLineErrorResponse,
    DeferralRequestNotValid: 'DeferralRequestNotValid' as DeferralRequestLineErrorResponse,
    DeferralRequestSimulation: 'DeferralRequestSimulation' as DeferralRequestLineErrorResponse,
    DeferralNoBudget: 'DeferralNoBudget' as DeferralRequestLineErrorResponse
};


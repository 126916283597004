/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsViewSort { 
    field: PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsViewSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponseLoanCompaniesListFiltersCompanyResponseSortableLoansFieldsViewSort {
    export type FieldEnum = 'FactorBuyerCode' | 'Name' | 'Vat';
    export const FieldEnum = {
        FactorBuyerCode: 'FactorBuyerCode' as FieldEnum,
        Name: 'Name' as FieldEnum,
        Vat: 'Vat' as FieldEnum
    };
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApiSort { 
    field: PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApiSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponseCompanyListFiltersCompanyResponseSortableFieldsApiSort {
    export type FieldEnum = 'executionDate' | 'name' | 'vat';
    export const FieldEnum = {
        ExecutionDate: 'executionDate' as FieldEnum,
        Name: 'name' as FieldEnum,
        Vat: 'vat' as FieldEnum
    };
}



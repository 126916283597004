/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SupplierDiscountRequestResponseApi } from './supplierDiscountRequestResponseApi';
import { SupplierDiscountRequestFiltersResponse } from './supplierDiscountRequestFiltersResponse';


export interface InvoiceContentResponseSupplierDiscountRequestResponseApiSupplierDiscountRequestFiltersResponse { 
    data?: Array<SupplierDiscountRequestResponseApi>;
    count: number;
    startResult: number;
    maxResult: number;
    lastId?: number;
    filters: SupplierDiscountRequestFiltersResponse;
}


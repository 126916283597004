/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EarlySalaryRequestsSortableFieldsView = 'Amount' | 'IssueDate' | 'LastName' | 'PaymentDate';

export const EarlySalaryRequestsSortableFieldsView = {
    Amount: 'Amount' as EarlySalaryRequestsSortableFieldsView,
    IssueDate: 'IssueDate' as EarlySalaryRequestsSortableFieldsView,
    LastName: 'LastName' as EarlySalaryRequestsSortableFieldsView,
    PaymentDate: 'PaymentDate' as EarlySalaryRequestsSortableFieldsView
};


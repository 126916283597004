/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PSD2UserInfoView } from './pSD2UserInfoView';
import { PSD2OnboardingStatusView } from './pSD2OnboardingStatusView';


export interface PSD2UserStatusResponse { 
    onboardingStatus: PSD2OnboardingStatusView;
    accountsSynchronized: boolean;
    userInfo?: PSD2UserInfoView;
    providerOnboardingUrl?: string;
    providerPlatformUrl?: string;
}
export namespace PSD2UserStatusResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BudgetStatus = 'AboveThreshold' | 'BelowThreshold';

export const BudgetStatus = {
    AboveThreshold: 'AboveThreshold' as BudgetStatus,
    BelowThreshold: 'BelowThreshold' as BudgetStatus
};


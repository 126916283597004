/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DpoView = 'Cluster30' | 'Cluster60' | 'Cluster90' | 'Cluster120' | 'Cluster150' | 'Cluster151';

export const DpoView = {
    Cluster30: 'Cluster30' as DpoView,
    Cluster60: 'Cluster60' as DpoView,
    Cluster90: 'Cluster90' as DpoView,
    Cluster120: 'Cluster120' as DpoView,
    Cluster150: 'Cluster150' as DpoView,
    Cluster151: 'Cluster151' as DpoView
};


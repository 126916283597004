/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CCPlafondResponse } from './cCPlafondResponse';
import { CreditCardCycleResponse } from './creditCardCycleResponse';
import { CCPlafondModelResponse } from './cCPlafondModelResponse';


export interface CreditCardSettingsResponse { 
    shopCode: string;
    discountRatePercentage: number;
    keyResultApi: string;
    cycles: Array<CreditCardCycleResponse>;
    plafondModel: CCPlafondModelResponse;
    lockdownPlafonds?: Array<CCPlafondResponse>;
}
export namespace CreditCardSettingsResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseInvoiceTypeView } from './reverseInvoiceTypeView';


export interface ReverseRequestLineResponse { 
    id: number;
    documentNumber: string;
    supplierCompanyId: number;
    supplierName: string;
    amount: number;
    currency: string;
    dueDate: string;
    invoiceType: ReverseInvoiceTypeView;
}
export namespace ReverseRequestLineResponse {
}



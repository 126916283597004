/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentStatusView = 'Accepted' | 'Rejected' | 'Uploaded' | 'ToUpload';

export const DocumentStatusView = {
    Accepted: 'Accepted' as DocumentStatusView,
    Rejected: 'Rejected' as DocumentStatusView,
    Uploaded: 'Uploaded' as DocumentStatusView,
    ToUpload: 'ToUpload' as DocumentStatusView
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiscountStatusApi } from './discountStatusApi';
import { AmountVatResponse } from './amountVatResponse';
import { VatView } from './vatView';
import { ConfirmingTypeApi } from './confirmingTypeApi';


export interface DiscountRequestResponseApi { 
    id: number;
    invoiceId: number;
    buyerId: number;
    buyerVat: VatView;
    supplierId: number;
    supplierVat: VatView;
    factorId: number;
    division: string;
    invoiceNumber: string;
    documentNumber: string;
    amount: number;
    newAmount: number;
    notesAmount: number;
    prizeAmount: number;
    accelerationDays: number;
    annualRatePercentage: number;
    discountAmount: number;
    discountableAmount: number;
    feeAmount?: AmountVatResponse;
    currency: string;
    exchange: number;
    date: string;
    dueDate: string;
    newDueDate: string;
    supplierCode: string;
    supplierName: string;
    buyerName: string;
    status: DiscountStatusApi;
    statusReason?: string;
    confirmingType: ConfirmingTypeApi;
    cigCode?: string;
    cupCode?: string;
    factorPaymentMethod?: string;
    supplierIban?: string;
    transferExpenses?: number;
    tae?: number;
}
export namespace DiscountRequestResponseApi {
}



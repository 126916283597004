<ion-content [fullscreen]="true" *transloco="let t" scroll-y="false">
    <div class="container column">
        <div class="label-container column">
            <div class="icon">
                <ion-img
                    src="../../assets/img/offline-illustration.svg"
                ></ion-img>
            </div>
            <div class="title">{{ t("offline.no.connection") }}</div>
            <div class="label">{{ t("offline.check.connection") }}</div>
        </div>
        <fd2-button
            [label]="t('common.try.again')"
            buttonStyle="secondary"
            icon="../../assets/img/reload.svg"
        ></fd2-button>
    </div>
</ion-content>

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';
import { DiscountRequestLineResponse } from './discountRequestLineResponse';
import { AutoDiscountStatusView } from './autoDiscountStatusView';


export interface DiscountRequestBuyerGroup { 
    buyerCompanyName: string;
    buyerCompanyId: number;
    buyerCompanyVat: VatView;
    supplierIban?: string;
    autoDiscountStatus: AutoDiscountStatusView;
    lines?: Array<DiscountRequestLineResponse>;
}
export namespace DiscountRequestBuyerGroup {
}



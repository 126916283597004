/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnboardingSectionView } from './onboardingSectionView';
import { OnboardingDocumentResponse } from './onboardingDocumentResponse';


export interface OnboardingSectionResponse { 
    section: OnboardingSectionView;
    position: number;
    documents?: Array<OnboardingDocumentResponse>;
}
export namespace OnboardingSectionResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferenceRateTypeView } from './referenceRateTypeView';
import { VariableRateResponse } from './variableRateResponse';
import { FactorDiscountFixedRateTypeResponse } from './factorDiscountFixedRateTypeResponse';
import { FactorDiscountSpreadRateTypeResponse } from './factorDiscountSpreadRateTypeResponse';
import { RateTypeView } from './rateTypeView';


/**
 * @type FactorDiscountRateTypesResponse
 * @export
 */
export type FactorDiscountRateTypesResponse = FactorDiscountFixedRateTypeResponse | FactorDiscountSpreadRateTypeResponse;


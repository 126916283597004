<ion-button
    mode="ios"
    [type]="buttonType"
    [fill]="onSetStyle(buttonStyle)"
    [class.primary-button]="buttonStyle === 'primary'"
    [class.secondary-button]="buttonStyle === 'secondary'"
    [class.transparent-button]="
        buttonStyle === 'transparent' || buttonStyle === 'link'
    "
    [class.link-button]="buttonStyle === 'link'"
    [class.right-align]="alignment === 'right'"
    [class.left-align]="alignment === 'left'"
    expand="block"
    [disabled]="disabled"
    (click)="!disabled ? onClickHandler() : null"
>
    <div class="row">
        {{ label }}
        <span>
            <ion-icon
                *ngIf="!!icon"
                class="icon"
                [slot]="iconSlot"
                [src]="icon"
            ></ion-icon
        ></span>
    </div>
</ion-button>

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';
import { ActiveDiscountStatusApi } from './activeDiscountStatusApi';


export interface ActiveDiscountResponse { 
    accelerationDays: number;
    buyerName: string;
    buyerVat: VatView;
    currency: string;
    discountId: number;
    documentNumber: string;
    dueDate: string;
    factorName: string;
    invoiceTotal: number;
    issueDate: string;
    newDueDate: string;
    status: ActiveDiscountStatusApi;
    supplierName: string;
    supplierVat: string;
    cigCode?: string;
    cupCode?: string;
}
export namespace ActiveDiscountResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AutocompleteType = 'BUYER' | 'INVOICE' | 'SUPPLIER' | 'DISCOUNT_REQUEST';

export const AutocompleteType = {
    Buyer: 'BUYER' as AutocompleteType,
    Invoice: 'INVOICE' as AutocompleteType,
    Supplier: 'SUPPLIER' as AutocompleteType,
    DiscountRequest: 'DISCOUNT_REQUEST' as AutocompleteType
};


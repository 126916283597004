/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerSupplierServiceStatusView } from './buyerSupplierServiceStatusView';
import { BuyerSupplierServiceTypeView } from './buyerSupplierServiceTypeView';
import { PaginatedResponseSupplierFiltersSupplierListItemSortableFieldsSort } from './paginatedResponseSupplierFiltersSupplierListItemSortableFieldsSort';
import { FinancialInfoFilters } from './financialInfoFilters';
import { PaginationRequest } from './paginationRequest';


export interface SupplierListPostRequest { 
    serviceType?: BuyerSupplierServiceTypeView;
    serviceStatus?: BuyerSupplierServiceStatusView;
    financialInfo: FinancialInfoFilters;
    search?: string;
    sort?: PaginatedResponseSupplierFiltersSupplierListItemSortableFieldsSort;
    pagination: PaginationRequest;
    enableUpload?: boolean;
}
export namespace SupplierListPostRequest {
}



import { Injectable, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class REQUIRED_SUPER {}

@Injectable({ providedIn: 'root' })
export abstract class Destroyable implements OnDestroy {
    protected unsubscribe$ = new Subject<void>();

    ngOnDestroy(): REQUIRED_SUPER {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        return new REQUIRED_SUPER();
    }

    protected untilDestroyed<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this.unsubscribe$);
    }
}

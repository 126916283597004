/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EarlySalaryRequestFiltersResponse } from './earlySalaryRequestFiltersResponse';
import { PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort } from './paginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort';
import { EarlySalaryRequestResponse } from './earlySalaryRequestResponse';
import { PaginationResponse } from './paginationResponse';


export interface PaginatedResponseEarlySalaryRequestFiltersResponseEarlySalaryRequestResponseEarlySalaryRequestsSortableFieldsView { 
    filters: EarlySalaryRequestFiltersResponse;
    pagination: PaginationResponse;
    sort?: PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort;
    page?: Array<EarlySalaryRequestResponse>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EarlySalaryEmployeeSortableFieldsView = 'ExternalId' | 'Name';

export const EarlySalaryEmployeeSortableFieldsView = {
    ExternalId: 'ExternalId' as EarlySalaryEmployeeSortableFieldsView,
    Name: 'Name' as EarlySalaryEmployeeSortableFieldsView
};


import { Injectable } from '@angular/core';
import {
    ChangeIbanRequest,
    OnboardingService,
} from '@fd/clients/findynamic/client';
import { Platform } from '@ionic/angular';

import { NativeApiService } from '../native-api-service/nativeApi.service';

@Injectable()
export class OnboardingApiService {
    constructor(
        private platform: Platform,
        private nativeApiService: NativeApiService,
        private findynamicOnboardingService: OnboardingService,
    ) {}

    isApp = this.platform.is('capacitor');

    getOnboardingApiV1CustomersCustomeridIbanActivations(customerId: number) {
        if (this.isApp) {
            return this.nativeApiService.getOnboardingApiV1CustomersCustomeridIbanActivations(
                customerId,
            );
        } else {
            return this.findynamicOnboardingService.getOnboardingApiV1CustomersCustomeridIbanActivations(
                customerId,
            );
        }
    }

    postOnboardingApiV1CustomersCustomeridFactorsFactoridIbanActivations(
        customerId: number,
        factorId: number,
        changeIbanRequest: ChangeIbanRequest,
    ) {
        if (this.isApp) {
            return this.nativeApiService.postOnboardingApiV1CustomersCustomeridFactorsFactoridIbanActivations(
                customerId,
                factorId,
                changeIbanRequest,
            );
        } else {
            return this.findynamicOnboardingService.postOnboardingApiV1CustomersCustomeridFactorsFactoridIbanActivations(
                customerId,
                factorId,
                changeIbanRequest,
            );
        }
    }
}

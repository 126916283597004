/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Quiz1 } from './quiz1';
import { Audio1 } from './audio1';
import { Video1 } from './video1';


/**
 * @type LearningProgressView
 * @export
 */
export type LearningProgressView = Audio1 | Quiz1 | Video1;


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type KpiView = 'E001' | 'E002' | 'E003' | 'E004' | 'S001' | 'S002' | 'S003' | 'S004' | 'S005' | 'G001' | 'G002';

export const KpiView = {
    E001: 'E001' as KpiView,
    E002: 'E002' as KpiView,
    E003: 'E003' as KpiView,
    E004: 'E004' as KpiView,
    S001: 'S001' as KpiView,
    S002: 'S002' as KpiView,
    S003: 'S003' as KpiView,
    S004: 'S004' as KpiView,
    S005: 'S005' as KpiView,
    G001: 'G001' as KpiView,
    G002: 'G002' as KpiView
};


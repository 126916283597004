/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type OnboardingServiceView = 'BankingBrokerage' | 'Confirming' | 'ReceivableFinancing' | 'ReverseFactoring';

export const OnboardingServiceView = {
    BankingBrokerage: 'BankingBrokerage' as OnboardingServiceView,
    Confirming: 'Confirming' as OnboardingServiceView,
    ReceivableFinancing: 'ReceivableFinancing' as OnboardingServiceView,
    ReverseFactoring: 'ReverseFactoring' as OnboardingServiceView
};


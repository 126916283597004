/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActiveDivisionBudgetResponse } from './activeDivisionBudgetResponse';
import { ActiveBudgetResponse } from './activeBudgetResponse';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface ActiveFactorResponse { 
    supplierId: number;
    factorId: number;
    factorName: string;
    factorLogoUrl?: string;
    factorVat: string;
    email?: string;
    address?: string;
    enabled: boolean;
    withRecourseBudget: ActiveBudgetResponse;
    supplierDivisionBudgets?: Array<ActiveDivisionBudgetResponse>;
    onboardingId?: number;
    integrationType: FactorIntegrationTypeView;
}
export namespace ActiveFactorResponse {
}



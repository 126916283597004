import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    combineLatest,
    of,
} from 'rxjs';
import {
    catchError,
    exhaustMap,
    filter,
    map,
    shareReplay,
} from 'rxjs/operators';

import { AuthService } from '../../auth-service/auth.service';
import { SignatureApiService } from '../../signature-api-service/signatureApi.service';
import { AbstractPollingService } from '../abstract-polling.service';

@Injectable({
    providedIn: 'root',
})
export class DocumentsToSignNotificationService extends AbstractPollingService<number> {
    private supplierId$ = new ReplaySubject<number>();
    private legalEntity$ = new ReplaySubject<boolean>();

    reload$ = new BehaviorSubject<boolean>(false);

    constructor(
        private authService: AuthService,
        private signatureApiService: SignatureApiService,
    ) {
        super();
        this.init();

        this.authService
            .getExternalId()
            .pipe(filter((id) => !!id))
            .subscribe((id) => this.supplierId$.next(id));
        this.authService
            .getLegalEntity()
            .pipe(filter((legalEntity) => !!legalEntity))
            .subscribe((legalEntity) => this.legalEntity$.next(legalEntity));
    }

    get pollingInterval(): number {
        return 3600000;
    }

    reload() {
        this.reload$.next(true);
    }

    getValue(): Observable<number> {
        return combineLatest([
            this.legalEntity$,
            this.supplierId$,
            this.reload$,
        ]).pipe(
            exhaustMap(([legalEntity, supplierId]) => {
                if (legalEntity) {
                    return this.signatureApiService
                        .getSignatureApiV1AccountsAccountidSignableDocumentsCount(
                            supplierId,
                        )
                        .pipe(
                            map((res) => {
                                return res.documentsToSign ?? 0;
                            }),
                            catchError(() => {
                                return of(0);
                            }),
                        );
                } else {
                    return of(0);
                }
            }),
            shareReplay(),
        );
    }
}

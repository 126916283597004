/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NamedFilterOptionType } from './namedFilterOptionType';
import { CountFilterOptionPSD2MovementDirectionView } from './countFilterOptionPSD2MovementDirectionView';
import { NamedFilterOptionPSD2MovementCategoryView } from './namedFilterOptionPSD2MovementCategoryView';
import { DateInterval } from './dateInterval';
import { NamedFilterOptionPSD2MovementOperationKindView } from './namedFilterOptionPSD2MovementOperationKindView';


export interface PSD2MovementListFilters { 
    bankAccountFilter?: Array<NamedFilterOptionType>;
    categoryFilter?: Array<NamedFilterOptionPSD2MovementCategoryView>;
    currencyFilter?: Array<NamedFilterOptionType>;
    minAmountFilter?: number;
    maxAmountFilter?: number;
    bookingDateIntervalFilter: DateInterval;
    valueDateIntervalFilter: DateInterval;
    operationKindFilter?: Array<NamedFilterOptionPSD2MovementOperationKindView>;
    directionFilter?: Array<CountFilterOptionPSD2MovementDirectionView>;
    search?: string;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTargetBoolean } from './kpiTargetBoolean';
import { KpiTarget0Boolean } from './kpiTarget0Boolean';
import { KpiView } from './kpiView';


export interface KpiGridBoolean { 
    kpi: KpiView;
    target0: KpiTarget0Boolean;
    targets?: Array<KpiTargetBoolean>;
    kind: string;
}
export namespace KpiGridBoolean {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DashboardAggregatedReport } from './dashboardAggregatedReport';


export interface BuyerDashboardResponse { 
    averageDeferredDays?: number;
    averageDeferredRate?: number;
    deferralInfoExists: boolean;
    totalConfirmedInvoices: number;
    totalDeferredInvoices: number;
    totalInvoicesSoldToFactor: number;
    report?: Array<DashboardAggregatedReport>;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfirmingServiceStatusView } from './confirmingServiceStatusView';
import { SupplierFactorConfigurationResponse } from './supplierFactorConfigurationResponse';


export interface SupplierConfirmingStatusResponse { 
    factorName?: string;
    divisions?: Array<string>;
    status: ConfirmingServiceStatusView;
    configurations?: Array<SupplierFactorConfigurationResponse>;
}
export namespace SupplierConfirmingStatusResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EarlySalaryAvailabilityError } from './earlySalaryAvailabilityError';
import { EarlySalaryParameters } from './earlySalaryParameters';
import { EarlySalaryServiceStatus } from './earlySalaryServiceStatus';


export interface EarlySalaryRequestsSummaryResponse { 
    serviceStatus: EarlySalaryServiceStatus;
    suspendedFrom?: string;
    suspendedTo?: string;
    availabilityError?: Array<EarlySalaryAvailabilityError>;
    currency: string;
    availableAmount: number;
    salaryAmount: number;
    requestedAmount: number;
    nextPaymentDate?: string;
    availableRequestsNumber: number;
    parameters: EarlySalaryParameters;
}
export namespace EarlySalaryRequestsSummaryResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VatView } from './vatView';
import { FactorSignatureServiceView } from './factorSignatureServiceView';
import { SignGroupResponse } from './signGroupResponse';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface FactorGroupResponse { 
    factorIntegrationType: FactorIntegrationTypeView;
    factorName: string;
    factorVat: VatView;
    service: FactorSignatureServiceView;
    groups?: Array<SignGroupResponse>;
}
export namespace FactorGroupResponse {
}



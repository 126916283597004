import { Injectable } from '@angular/core';
import {
    TCountryCode,
    getCountryData,
    getCountryDataList,
} from 'countries-list';

@Injectable()
export class PhonePrefixService {
    constructor() {}

    getFiltredPrefixPhone(phone: string, lang: string) {
        if (phone) {
            let availablePhonePrefixes = getCountryDataList().map((item) => ({
                code: item.iso2,
                prefix: `+${item.phone[0]}`,
            }));

            availablePhonePrefixes = availablePhonePrefixes.sort(
                (a, b) => a.prefix.length - b.prefix.length,
            );

            const selected = availablePhonePrefixes.find((item) =>
                phone.startsWith(item.prefix),
            );

            if (selected) {
                return {
                    phone: this.getFiltredPhone(phone, selected.code),
                    localeCode: selected.code,
                };
            }
        }

        const localeCode = this.getLocale(lang);
        return {
            phone: this.getFiltredPhone(phone, localeCode),
            localeCode: localeCode,
        };
    }

    private getFiltredPhone(phone: string, localeCode: TCountryCode): string {
        return phone?.replace(`+${getCountryData(localeCode).phone[0]}`, '');
    }

    private getLocale(lang: string): TCountryCode {
        switch (lang) {
            case 'es':
            case 'eu':
                return 'ES';
            default:
                return 'GB';
        }
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BuyerDivision } from './buyerDivision';
import { ConfirmingServiceStatusView } from './confirmingServiceStatusView';
import { ConfirmingTypeApi } from './confirmingTypeApi';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface SupplierFactorConfigurationResponse { 
    confirmingType: ConfirmingTypeApi;
    divisions?: Array<BuyerDivision>;
    factorId: number;
    factorName: string;
    factorVat: string;
    prizePercentage: number;
    status: ConfirmingServiceStatusView;
    integrationType: FactorIntegrationTypeView;
}
export namespace SupplierFactorConfigurationResponse {
}



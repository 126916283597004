/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EsgOpenEsSectionApi = 'GovernancePrinciples' | 'OverAll' | 'People' | 'Planet' | 'Prosperity';

export const EsgOpenEsSectionApi = {
    GovernancePrinciples: 'GovernancePrinciples' as EsgOpenEsSectionApi,
    OverAll: 'OverAll' as EsgOpenEsSectionApi,
    People: 'People' as EsgOpenEsSectionApi,
    Planet: 'Planet' as EsgOpenEsSectionApi,
    Prosperity: 'Prosperity' as EsgOpenEsSectionApi
};


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseInvoiceTypeView } from './reverseInvoiceTypeView';
import { ReverseFactorInvoiceStatusView } from './reverseFactorInvoiceStatusView';


export interface ReverseInvoiceResponse1 { 
    invoiceId: number;
    documentNumber: string;
    buyerName: string;
    buyerVat: string;
    supplierName: string;
    supplierVat: string;
    issueDate: string;
    dueDate: string;
    invoiceAmount: number;
    currency: string;
    status: ReverseFactorInvoiceStatusView;
    invoiceType: ReverseInvoiceTypeView;
}
export namespace ReverseInvoiceResponse1 {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LearningLessonTypeView } from './learningLessonTypeView';
import { EarlySalaryEducationStatusView } from './earlySalaryEducationStatusView';
import { LearningLessonContentResponse } from './learningLessonContentResponse';
import { LearningDifficultyView } from './learningDifficultyView';


export interface LearningLessonResponse { 
    position: number;
    status: EarlySalaryEducationStatusView;
    durationInMinutes: number;
    difficulty?: LearningDifficultyView;
    contentType: LearningLessonTypeView;
    content: LearningLessonContentResponse;
}
export namespace LearningLessonResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PSD2MovementListFilters } from './pSD2MovementListFilters';
import { PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsViewSort } from './paginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsViewSort';
import { PSD2MovementListItem } from './pSD2MovementListItem';
import { PaginationResponse } from './paginationResponse';


export interface PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsView { 
    filters: PSD2MovementListFilters;
    pagination: PaginationResponse;
    sort?: PaginatedResponsePSD2MovementListFiltersPSD2MovementListItemPSD2MovementListSortableFieldsViewSort;
    page?: Array<PSD2MovementListItem>;
}


import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { EMPTY, NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth-service/auth.service';
import { ThothApiService } from '../services/thoth-api-service/thothApi.service';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private tokenService: HttpXsrfTokenExtractor,
        private thothService: ThothApiService,
        private navController: NavController,
        private router: Router,
    ) {}

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        const headerName = 'X-XSRF-TOKEN';
        const lcUrl = req.url.toLowerCase();
        // Skip absolute URLs.
        // absolute URLs require special handling
        // anyway as the cookie set
        // on our origin is not the same as the token expected by another origin.
        if (
            !lcUrl.startsWith(environment.apiBasePath) &&
            (lcUrl.startsWith('http://') || lcUrl.startsWith('https://'))
        ) {
            return next.handle(req);
        }
        const token = this.tokenService.getToken();
        req = lcUrl.startsWith(environment.apiBasePath)
            ? req
            : req.clone({ url: environment.apiBasePath + lcUrl });

        // Be careful not to overwrite an existing header of the same name.
        if (token !== null && !req.headers.has(headerName)) {
            req = req.clone({ headers: req.headers.set(headerName, token) });
        }

        return next.handle(req).pipe(
            catchError((error) => {
                if (
                    error.status === 401 &&
                    this.router.url.includes('private')
                ) {
                    console.error('Too many authentication fails, logging out');
                    this.authService.logout();
                    this.thothService.logout().pipe(
                        catchError(() => {
                            return EMPTY;
                        }),
                    );

                    this.navController.navigateRoot('public');

                    return NEVER;
                } else {
                    return throwError(() => error);
                }
            }),
        );
    }
}

/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConfirmingInvoicesSelectionRequest } from './confirmingInvoicesSelectionRequest';
import { SupplierAutoDiscountsRequest } from './supplierAutoDiscountsRequest';


export interface ConfirmingInvoicesSelectionsRequest { 
    invoices?: Array<ConfirmingInvoicesSelectionRequest>;
    autoDiscountsRequest?: SupplierAutoDiscountsRequest;
}


/**
 * Authentication API
 * Thoth authentication API
 *
 * The version of the OpenAPI document: 2.4.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Saml2Attribute } from './saml2Attribute';
import { CAUserInfo } from './cAUserInfo';


export interface Saml2Info { 
    attribute: Saml2Attribute;
    info: CAUserInfo;
}


/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LearningLessonResponse } from './learningLessonResponse';
import { EarlySalaryEducationStatusView } from './earlySalaryEducationStatusView';
import { LearningDifficultyView } from './learningDifficultyView';


export interface LearningResponse { 
    moduleExternalId: string;
    position: number;
    difficulty?: LearningDifficultyView;
    status: EarlySalaryEducationStatusView;
    title: string;
    _abstract?: string;
    image: string;
    startDate?: string;
    endDate?: string;
    countLesson: number;
    completePercentage: number;
    lessons?: Array<LearningLessonResponse>;
}
export namespace LearningResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateAggregationApi } from './dateAggregationApi';


export interface DashboardQuery { 
    accumulate?: boolean;
    currency?: string;
    dateAggregation?: DateAggregationApi;
    endDate?: string;
    startDate?: string;
    supplierId?: number;
    divisionId?: number;
    factorId?: number;
}
export namespace DashboardQuery {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTargetDouble } from './kpiTargetDouble';
import { KpiView } from './kpiView';
import { KpiTarget0Double } from './kpiTarget0Double';


export interface KpiGridDouble { 
    kpi: KpiView;
    target0: KpiTarget0Double;
    targets?: Array<KpiTargetDouble>;
    kind: string;
}
export namespace KpiGridDouble {
}



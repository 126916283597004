/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnboardingPromoterView } from './onboardingPromoterView';
import { OnboardingServiceView } from './onboardingServiceView';
import { OnboardingSectionResponse } from './onboardingSectionResponse';


export interface CustomerOnboardingBySectionResponse { 
    onboarderName: string;
    service: OnboardingServiceView;
    missingDocuments: number;
    uploadedDocuments: number;
    totalDocuments: number;
    promoter?: OnboardingPromoterView;
    sections?: Array<OnboardingSectionResponse>;
}
export namespace CustomerOnboardingBySectionResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReverseRequestDivisionGroupResponse } from './reverseRequestDivisionGroupResponse';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface ReverseRequestFactorGroupResponse { 
    factorId: number;
    factorName: string;
    factorVatCountry: string;
    factorVatCode: string;
    groups?: Array<ReverseRequestDivisionGroupResponse>;
    integrationType: FactorIntegrationTypeView;
}
export namespace ReverseRequestFactorGroupResponse {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NamedFilter } from './namedFilter';
import { FactorFilter } from './factorFilter';


export interface BuyerDashboardFiltersResponse { 
    currencyFilter?: Array<NamedFilter>;
    divisionFilter?: Array<NamedFilter>;
    factorFilter?: Array<FactorFilter>;
    supplierFilter?: Array<NamedFilter>;
    startDate?: string;
    endDate?: string;
}


import { Injectable } from '@angular/core';
import { CapacitorCookies } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { IonicStorageModelKeys } from 'src/shared/storages/models/ionic-storage-model';

@Injectable({ providedIn: 'root' })
export class IonicStorageService {
    oneTimeTokenLocalStorageKey: string | null;

    otpLocalStorageKey: string | null;

    signOnCheckout: boolean | null;

    transactionCompletedInvoice: boolean | null;

    transactionCompletedRequest: boolean | null;

    constructor(
        private platform: Platform,
        private storage: Storage,
    ) {}

    clear() {
        this.storage.clear();
    }

    // eslint-disable-next-line
    get(key: IonicStorageModelKeys): Observable<any> {
        return from(this.storage.get(key)).pipe(
            map((value) => JSON.parse(value)),
        );
    }

    getOneTimeTokenLocalStorageKey(): string | undefined {
        if (this.oneTimeTokenLocalStorageKey) {
            const ott = this.oneTimeTokenLocalStorageKey;
            this.oneTimeTokenLocalStorageKey = null;
            return ott;
        } else return undefined;
    }

    getOtpLocalStorageKey(): string | undefined {
        if (this.otpLocalStorageKey) {
            const otp = String(this.otpLocalStorageKey);
            this.otpLocalStorageKey = null;
            return otp;
        } else return undefined;
    }

    getXSRFTokenLocalStorageKey(): Observable<string> {
        return from(
            CapacitorCookies.getCookies().then((cookieMap) => {
                if (this.platform.is('android')) {
                    return decodeURIComponent(cookieMap['XSRF-TOKEN']);
                } else {
                    return cookieMap['XSRF-TOKEN'];
                }
            }),
        );
    }

    init() {
        return this.storage.create();
    }

    remove(key: IonicStorageModelKeys): Observable<unknown> {
        return from(this.storage.remove(key));
    }

    // eslint-disable-next-line
    set(key: IonicStorageModelKeys, value: any): Observable<unknown> {
        return from(this.storage.set(key, JSON.stringify(value)));
    }

    setCheckoutSign(value: boolean) {
        this.signOnCheckout = value;
    }

    setOTPLocalStorageKey(otp: string) {
        this.otpLocalStorageKey = otp;
    }

    setOneTimeTokenLocalStorageKey(oneTimeToken: string) {
        this.oneTimeTokenLocalStorageKey = oneTimeToken;
    }
    setReloadedDataInvoice() {
        this.transactionCompletedInvoice = false;
    }

    setReloadedDataRequest() {
        this.transactionCompletedRequest = false;
    }

    setTransactionCompleted() {
        this.transactionCompletedRequest = true;
        this.transactionCompletedInvoice = true;
    }
}

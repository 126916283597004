/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortOrder } from './sortOrder';


export interface PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort { 
    field: PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort.FieldEnum;
    order?: SortOrder;
}
export namespace PaginatedResponseEarlySalaryHRRequestFiltersResponseEarlySalaryHRRequestResponseEarlySalaryRequestsSortableFieldsViewSort {
    export type FieldEnum = 'Amount' | 'IssueDate' | 'LastName' | 'PaymentDate';
    export const FieldEnum = {
        Amount: 'Amount' as FieldEnum,
        IssueDate: 'IssueDate' as FieldEnum,
        LastName: 'LastName' as FieldEnum,
        PaymentDate: 'PaymentDate' as FieldEnum
    };
}



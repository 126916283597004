/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodApi } from './paymentMethodApi';
import { EsgManagerApi } from './esgManagerApi';
import { CompanyEsgStatusApi } from './companyEsgStatusApi';


export interface CompanyResponse { 
    companyId: number;
    name: string;
    vat: string;
    status: CompanyEsgStatusApi;
    executionDate?: string;
    invitationDate?: string;
    manager?: EsgManagerApi;
    /**
     * use invitationExpirationDate
     */
    expirationDate?: string;
    invitationExpirationDate?: string;
    scoreExpirationDate?: string;
    paymentMethod?: PaymentMethodApi;
    scoreShared: boolean;
}
export namespace CompanyResponse {
}



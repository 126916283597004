/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { KpiTargetInt } from './kpiTargetInt';
import { KpiView } from './kpiView';
import { KpiTarget0Int } from './kpiTarget0Int';


export interface KpiGridInt { 
    kpi: KpiView;
    target0: KpiTarget0Int;
    targets?: Array<KpiTargetInt>;
    kind: string;
}
export namespace KpiGridInt {
}



/**
 * FinDynamic
 * FinDynamic API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev@findynamic.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorBudgetResponse } from './factorBudgetResponse';
import { DeferralSettings } from './deferralSettings';
import { FactorIntegrationTypeView } from './factorIntegrationTypeView';


export interface FactorResponse { 
    id: number;
    factorId: number;
    factorName: string;
    factorLogoUrl?: string;
    name: string;
    vat: string;
    email: string;
    address: string;
    fiscalCode: string;
    activatedSuppliers: number;
    enabled: boolean;
    confirmBudget?: FactorBudgetResponse;
    withoutRecourseBudget?: FactorBudgetResponse;
    deferralSettings?: DeferralSettings;
    onboardingId?: number;
    integrationType: FactorIntegrationTypeView;
}
export namespace FactorResponse {
}


